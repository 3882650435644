import { put } from "redux-saga/effects";
import * as actions from "store/reducers/glucose/Glucose.actions";
import GlucoseService from "../../../services/Glucose";

/**
 * FETCH USER
 */
export function* fetchGlucoseSaga(action) {
  yield put(actions.fetchGlucoseStart());

  try {
    const service = yield new GlucoseService();
    const glucose = yield service.find(action.params);

    yield put(actions.fetchGlucoseSuccess(glucose));
  } catch (error) {
    yield put(actions.fetchGlucoseFail(error));
  }
}
