import * as privacyPolicyTypes from './privacy-policy.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
    privacyPolicys: {},
    privacyPolicy: {},
    successDeteled: '',    
    error: null,
    loading: false
};

const fetchPrivacyPolicyStart = ( state, action ) => {
  return updateObject( state, { loading: true } );
};

const fetchPrivacyPolicySuccess = ( state, action ) => {
  return updateObject( state, {
      privacyPolicys: action.privacyPolicys,
      privacyPolicy: {},
      loading: false,
      error: null
  } );
};

const fetchPrivacyPolicyFail = ( state, action ) => {
  return updateObject( state, { loading: false, error: action.error });
};

const addPrivacyPolicyStart = ( state, action ) => {
  return updateObject( state, { loading: true } );
};

const addPrivacyPolicySuccess = ( state, action ) => {
  return updateObject( state, {
      privacyPolicy: action.privacyPolicy,
      loading: false,
      error: null
  } );
};

const addPrivacyPolicyFail = ( state, action ) => {
  return updateObject( state, { loading: false, error: action.error } );
};

const deletePrivacyPolicyStart = ( state, action ) => {
  return updateObject( state, { loading: true } );
};

const deletePrivacyPolicySuccess = ( state, action ) => {
  return updateObject( state, {
      successDeteled: new Date().getTime(),
      error: null
  } );
};

const deletePrivacyPolicyFail = ( state, action ) => {
  return updateObject( state, { loading: false, error: action.error } );
};

const getPrivacyPolicyStart = ( state, action ) => {
  return updateObject( state, { loading: true } );
};

const getPrivacyPolicySuccess = ( state, action ) => {
  return updateObject( state, {
      privacyPolicy: action.privacyPolicy,
      loading: false,
      error: null
  } );
};

const getPrivacyPolicyFail = ( state, action ) => {
  return updateObject( state, { loading: false, error: action.error } );
};

const updatePrivacyPolicyStart = ( state, action ) => {
  return updateObject( state, { loading: true } );
};

const updatePrivacyPolicySuccess = ( state, action ) => {
  return updateObject( state, {
      privacyPolicy: action.privacyPolicy,
      loading: false,
      error: null
  } );
};

const updatePrivacyPolicyFail = ( state, action ) => {
  return updateObject( state, { loading: false, error: action.error } );
};

const privacyPolicyDismissError = (state) => {
  return updateObject( state, {
      error: null
  });
};

const privacyPolicyReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {
      case privacyPolicyTypes.FETCH_PRIVACY_POLICY_START: return fetchPrivacyPolicyStart( state, action );
      case privacyPolicyTypes.FETCH_PRIVACY_POLICY_SUCCESS: return fetchPrivacyPolicySuccess( state, action );
      case privacyPolicyTypes.FETCH_PRIVACY_POLICY_FAIL: return fetchPrivacyPolicyFail( state, action );
      case privacyPolicyTypes.ADD_PRIVACY_POLICY_START: return addPrivacyPolicyStart( state, action );
      case privacyPolicyTypes.ADD_PRIVACY_POLICY_SUCCESS: return addPrivacyPolicySuccess( state, action );
      case privacyPolicyTypes.ADD_PRIVACY_POLICY_FAIL: return addPrivacyPolicyFail( state, action );
      case privacyPolicyTypes.DELETE_PRIVACY_POLICY_START: return deletePrivacyPolicyStart( state, action );
      case privacyPolicyTypes.DELETE_PRIVACY_POLICY_SUCCESS: return deletePrivacyPolicySuccess( state, action );
      case privacyPolicyTypes.DELETE_PRIVACY_POLICY_FAIL: return deletePrivacyPolicyFail( state, action );
      case privacyPolicyTypes.GET_PRIVACY_POLICY_START: return getPrivacyPolicyStart( state, action );
      case privacyPolicyTypes.GET_PRIVACY_POLICY_SUCCESS: return getPrivacyPolicySuccess( state, action );
      case privacyPolicyTypes.GET_PRIVACY_POLICY_FAIL: return getPrivacyPolicyFail( state, action );
      case privacyPolicyTypes.UPDATE_PRIVACY_POLICY_START: return updatePrivacyPolicyStart( state, action );
      case privacyPolicyTypes.UPDATE_PRIVACY_POLICY_SUCCESS: return updatePrivacyPolicySuccess( state, action );
      case privacyPolicyTypes.UPDATE_PRIVACY_POLICY_FAIL: return updatePrivacyPolicyFail( state, action );
      case privacyPolicyTypes.PRIVACY_POLICY_DISMISS_ERROR: return privacyPolicyDismissError( state);

        default: return state;

    }

};

export default privacyPolicyReducer;
