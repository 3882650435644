import { put } from "redux-saga/effects";
import * as actions from "store/reducers/article-category/article-category.actions";
import articleCategoryService from "services/ArticleCategory";
export function* fetchArticleCategorySaga(action) {
    yield put(actions.fetchArticleCategoryStart());
    const service = yield new articleCategoryService();
    const articleCategory = yield service.find(action.params);
    if(!articleCategory.error){
      yield put(actions.fetchArticleCategorySuccess(articleCategory));
    } else {
      yield put(actions.fetchArticleCategoryFail(articleCategory.error));  
    }
}

export function* addArticleCategorySaga(action) {
  yield put(actions.addArticleCategoryStart());
  const service = yield new articleCategoryService();
  const articleCategory = yield service.create(action.data);
  if(!articleCategory.error){
    yield action.history.replace('/admin/article-category');
  } else {
    yield put(actions.addArticleCategoryFail(articleCategory.error));
  }
}

export function* deleteArticleCategorySaga(action) {
  yield put(actions.deleteArticleCategoryStart());
  const service = yield new articleCategoryService();
  const articleCategory = yield service.delete(action.id);
  if(!articleCategory.error){
    yield put(actions.deleteArticleCategorySuccess());
    if(action.history){
      action.history.replace('/admin/article-category')
    }
  } else {
    yield put(actions.deleteArticleCategoryFail(articleCategory.error));
  }
}

export function* getArticleCategorySaga(action) {
  yield put(actions.getArticleCategoryStart());
  const service = yield new articleCategoryService();
  const articleCategory = yield service.get(action.id);
  if(!articleCategory.error){
    yield put(actions.getArticleCategorySuccess(articleCategory));
  } else {
    yield put(actions.getArticleCategoryFail(articleCategory.error));
  }
}

export function* updateArticleCategorySaga(action) {
  yield put(actions.updateArticleCategoryStart());
  const service = yield new articleCategoryService();
  const articleCategory = yield service.patch(action.data);
  if(!articleCategory.error){
    yield action.history.replace('/admin/article-category');
  } else {
    yield put(actions.updateArticleCategoryFail(articleCategory.error));
  }
}