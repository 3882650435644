import { combineReducers } from 'redux';
import Activity from './activity/Activity.reducer';
import Glucose from './glucose/Glucose.reducer';
import HBA1C from './HBA1C/HBA1C.reducer';
import OtherLabResult from './otherLabResult/OtherLabResult.reducer';
import adminAuthManagement from './adminAuthManagement/adminAuthManagement.reducer';
import adminLog from './adminLog/AdminLog.reducer';
import admin from './admins/admins.reducer';
import articleCategory from './article-category/article-category.reducer';
import article from './article/article.reducer';
import auth from './auth/auth.reducer';
import banner from './banner/banner.reducer';
import contentCategory from './content-category/content-category.reducer';
import content from './content/content.reducer';
import contentView from './content-views/content-views.reducer';
import faq from './faq/faq.reducer';
import foodCategory from './food-category/food-category.reducer';
import foodRecipe from './food-recipe/food-recipe.reducer';
import food from './food/food.reducer';
import foodUnit from './food-unit/food-unit.reducer';
import greeting from './greeting/greeting.reducer';
import medicine from './medicine/medicine.reducer';
import onboarding from './onboarding/onboarding.reducer';
import overcalorieDiabetics from './overcalorie-diabetics/overcalorieDiabetics.reducer';
import pageInformation from './page-information/page-information.reducer';
import physicalActivity from './physical-activity/physical-activity.reducer';
import pinnedContent from './pinned-contents/pinned-content.reducer';
import privacyPolicy from './privacy-policy/privacy-policy.reducer';
import pushNotification from './push-notification/push-notification.reducer';
import role from './roles/roles.reducer';
import shared from './shared/shared.reducer';
import topFood from './top-food/topFood.reducer';
import topMedicine from './top-medicine/topMedicine.reducer';
import user from './user/user.reducer';
import waterConsumption from './waterConsumption/waterConsumption.reducer';
import versioning from './versioning/versioning.reducer';

const root = combineReducers({
  HBA1C,
  activity: Activity,
  admin,
  adminAuthManagement,
  adminLog,
  article,
  articleCategory,
  auth,
  banner,
  content,
  contentCategory,
  contentView,
  faq,
  food,
  foodCategory,
  foodRecipe,
  foodUnit,
  glucose: Glucose,
  greeting,
  medicine,
  onboarding,
  otherLabResult: OtherLabResult,
  overcalorieDiabetics,
  pageInformation,
  physicalActivity,
  pinnedContent,
  privacyPolicy,
  pushNotification,
  role,
  shared,
  topFood,
  topMedicine,
  user,
  waterConsumption,
  versioning
});

export default root;
