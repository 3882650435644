import * as pushNotificationTypes from "./push-notification.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  pushNotifications: {},
  pushNotification: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  },
};

const fetchPushNotificationStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchPushNotificationSuccess = (state, action) => {
  return updateObject(state, {
    pushNotifications: action.pushNotifications,
    pushNotification: {},
    loading: false,
    error: null
  });
};

const fetchPushNotificationFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addPushNotificationStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addPushNotificationSuccess = (state, action) => {
  return updateObject(state, {
    pushNotification: action.pushNotification,
    loading: false,
    error: null
  });
};

const addPushNotificationFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deletePushNotificationStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deletePushNotificationSuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deletePushNotificationFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getPushNotificationStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getPushNotificationSuccess = (state, action) => {
  return updateObject(state, {
    pushNotification: action.pushNotification,
    loading: false,
    error: null
  });
};

const getPushNotificationFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updatePushNotificationStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updatePushNotificationSuccess = (state, action) => {
  return updateObject(state, {
    pushNotification: action.pushNotification,
    loading: false,
    error: null
  });
};

const updatePushNotificationFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const pushNotificationDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const pushNotificationAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj
  });
};

const pushNotificationClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {}
  });
};

const pushNotificationAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination
  });
};

const pushNotificationClearPagination = (state) => {
  return updateObject(state, {
    pagination: {}
  });
};

const pushNotificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pushNotificationTypes.FETCH_PUSH_NOTIFICATION_START:
      return fetchPushNotificationStart(state, action);
    case pushNotificationTypes.FETCH_PUSH_NOTIFICATION_SUCCESS:
      return fetchPushNotificationSuccess(state, action);
    case pushNotificationTypes.FETCH_PUSH_NOTIFICATION_FAIL:
      return fetchPushNotificationFail(state, action);
    case pushNotificationTypes.ADD_PUSH_NOTIFICATION_START:
      return addPushNotificationStart(state, action);
    case pushNotificationTypes.ADD_PUSH_NOTIFICATION_SUCCESS:
      return addPushNotificationSuccess(state, action);
    case pushNotificationTypes.ADD_PUSH_NOTIFICATION_FAIL:
      return addPushNotificationFail(state, action);
    case pushNotificationTypes.DELETE_PUSH_NOTIFICATION_START:
      return deletePushNotificationStart(state, action);
    case pushNotificationTypes.DELETE_PUSH_NOTIFICATION_SUCCESS:
      return deletePushNotificationSuccess(state, action);
    case pushNotificationTypes.DELETE_PUSH_NOTIFICATION_FAIL:
      return deletePushNotificationFail(state, action);
    case pushNotificationTypes.GET_PUSH_NOTIFICATION_START:
      return getPushNotificationStart(state, action);
    case pushNotificationTypes.GET_PUSH_NOTIFICATION_SUCCESS:
      return getPushNotificationSuccess(state, action);
    case pushNotificationTypes.GET_PUSH_NOTIFICATION_FAIL:
      return getPushNotificationFail(state, action);
    case pushNotificationTypes.UPDATE_PUSH_NOTIFICATION_START:
      return updatePushNotificationStart(state, action);
    case pushNotificationTypes.UPDATE_PUSH_NOTIFICATION_SUCCESS:
      return updatePushNotificationSuccess(state, action);
    case pushNotificationTypes.UPDATE_PUSH_NOTIFICATION_FAIL:
      return updatePushNotificationFail(state, action);
    case pushNotificationTypes.PUSH_NOTIFICATION_DISMISS_ERROR:
      return pushNotificationDismissError(state);
    case pushNotificationTypes.PUSH_NOTIFICATION_ADD_FILTER:
      return pushNotificationAddFilter(state, action);
    case pushNotificationTypes.PUSH_NOTIFICATION_CLEAR_FILTER:
      return pushNotificationClearFilter(state);
    case pushNotificationTypes.PUSH_NOTIFICATION_ADD_PAGINATION:
      return pushNotificationAddPagination(state, action);
    case pushNotificationTypes.PUSH_NOTIFICATION_CLEAR_PAGINATION:
      return pushNotificationClearPagination(state);
    default:
      return state;
  }
};

export default pushNotificationReducer;
