import { takeEvery } from "redux-saga/effects";
import * as waterConsumptionTypes from "store/reducers/waterConsumption/waterConsumption.types";
import {
  fetchWaterConsumptionsSaga,
  getWaterConsumptionSaga,
  addWaterConsumptionSaga,
  updateWaterConsumptionSaga,
  deleteWaterConsumptionSaga
} from "./waterConsumption";

export const waterConsumptionSagas = {
  watchFetchWaterConsumptions: takeEvery(
    waterConsumptionTypes.FETCH_WATER_CONSUMPTIONS,
    fetchWaterConsumptionsSaga
  ),
  watchGetWaterConsumption: takeEvery(
    waterConsumptionTypes.GET_WATER_CONSUMPTION,
    getWaterConsumptionSaga
  )
  // watchAddWaterConsumption: takeEvery(
  //   waterConsumptionTypes.ADD_WATER_CONSUMPTION,
  //   addWaterConsumptionSaga
  // ),
  // watchUpdateWaterConsumption: takeEvery(
  //   waterConsumptionTypes.UPDATE_WATER_CONSUMPTION,
  //   updateWaterConsumptionSaga
  // ),
  // watchDeleteWaterConsumption: takeEvery(
  //   waterConsumptionTypes.DELETE_WATER_CONSUMPTION,
  //   deleteWaterConsumptionSaga
  // )
};
