export const ADD_VERSIONING = 'ADD_VERSIONING';
export const ADD_VERSIONING_START = 'ADD_VERSIONING_START';
export const ADD_VERSIONING_SUCCESS = 'ADD_VERSIONING_SUCCESS';
export const ADD_VERSIONING_FAIL = 'ADD_VERSIONING_FAIL';

export const DELETE_VERSIONING = 'DELETE_VERSIONING';
export const DELETE_VERSIONING_START = 'DELETE_VERSIONING_START';
export const DELETE_VERSIONING_SUCCESS = 'DELETE_VERSIONING_SUCCESS';
export const DELETE_VERSIONING_FAIL = 'DELETE_VERSIONING_FAIL';

export const UPDATE_VERSIONING = 'UPDATE_VERSIONING';
export const UPDATE_VERSIONING_START = 'UPDATE_VERSIONING_START';
export const UPDATE_VERSIONING_SUCCESS = 'UPDATE_VERSIONING_SUCCESS';
export const UPDATE_VERSIONING_FAIL = 'UPDATE_VERSIONING_FAIL';

export const GET_VERSIONING = 'GET_VERSIONING';
export const GET_VERSIONING_START = 'GET_VERSIONING_START';
export const GET_VERSIONING_SUCCESS = 'GET_VERSIONING_SUCCESS';
export const GET_VERSIONING_FAIL = 'GET_VERSIONING_FAIL';

export const FETCH_VERSIONING = 'FETCH_VERSIONING';
export const FETCH_VERSIONING_START = 'FETCH_VERSIONING_START';
export const FETCH_VERSIONING_SUCCESS = 'FETCH_VERSIONING_SUCCESS';
export const FETCH_VERSIONING_FAIL = 'FETCH_VERSIONING_FAIL';

// ====== UTILITY  ======= //
export const VERSIONING_DISMISS_ERROR = 'VERSIONING_DISMISS_ERROR';
export const VERSIONING_ADD_FILTER = 'VERSIONING_ADD_FILTER';
export const VERSIONING_CLEAR_FILTER = 'VERSIONING_CLEAR_FILTER';
export const VERSIONING_ADD_PAGINATION = 'VERSIONING_ADD_PAGINATION';
export const VERSIONING_CLEAR_PAGINATION = 'VERSIONING_CLEAR_PAGINATION';
