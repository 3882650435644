export const ADD_ARTICLE = "ADD_ARTICLE";
export const ADD_ARTICLE_START = "ADD_ARTICLE_START";
export const ADD_ARTICLE_SUCCESS = "ADD_ARTICLE_SUCCESS";
export const ADD_ARTICLE_FAIL = "ADD_ARTICLE_FAIL";

export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const DELETE_ARTICLE_START = "DELETE_ARTICLE_START";
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS";
export const DELETE_ARTICLE_FAIL = "DELETE_ARTICLE_FAIL";

export const UPDATE_ARTICLE = "UPDATE_ARTICLE";
export const UPDATE_ARTICLE_START = "UPDATE_ARTICLE_START";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAIL = "UPDATE_ARTICLE_FAIL";

export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLE_START = "GET_ARTICLE_START";
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_FAIL = "GET_ARTICLE_FAIL";

export const FETCH_ARTICLES = "FETCH_ARTICLES";
export const FETCH_ARTICLES_START = "FETCH_ARTICLES_START";
export const FETCH_ARTICLES_SUCCESS = "FETCH_ARTICLES_SUCCESS";
export const FETCH_ARTICLES_FAIL = "FETCH_ARTICLES_FAIL";

// ====== UTILITY  ======= //
export const ARTICLE_DISMISS_ERROR = 'ARTICLE_DISMISS_ERROR';
export const ARTICLE_ADD_FILTER = 'ARTICLE_ADD_FILTER';
export const ARTICLE_CLEAR_FILTER = 'ARTICLE_CLEAR_FILTER';
export const ARTICLE_ADD_PAGINATION = 'ARTICLE_ADD_PAGINATION';
export const ARTICLE_CLEAR_PAGINATION = 'ARTICLE_CLEAR_PAGINATION';
