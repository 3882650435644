/**
 * TOP MEDICINE
 */
export const FETCH_TOP_MEDICINE = "FETCH_TOP_MEDICINE";
export const FETCH_TOP_MEDICINE_START = "FETCH_TOP_MEDICINE_START";
export const FETCH_TOP_MEDICINE_SUCCESS = "FETCH_TOP_MEDICINE_SUCCESS";
export const FETCH_TOP_MEDICINE_FAIL = "FETCH_TOP_MEDICINE_FAIL";

/**
 * TOP MANUFACTURER
 */
export const FETCH_TOP_MANUFACTURER = "FETCH_TOP_MANUFACTURER";
export const FETCH_TOP_MANUFACTURER_START = "FETCH_TOP_MANUFACTURER_START";
export const FETCH_TOP_MANUFACTURER_SUCCESS = "FETCH_TOP_MANUFACTURER_SUCCESS";
export const FETCH_TOP_MANUFACTURER_FAIL = "FETCH_TOP_MANUFACTURER_FAIL";
