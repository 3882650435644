import * as articleTypes from "store/reducers/article/article.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchArticlesSaga,
  addArticleSaga,
  deleteArticleSaga,
  getArticleSaga,
  updateArticleSaga
} from "./article";

export const articleSagas = {
  watchFetchArticles: takeEvery(articleTypes.FETCH_ARTICLES, fetchArticlesSaga),
  watchAddArticle: takeEvery(articleTypes.ADD_ARTICLE, addArticleSaga),
  watchDeleteArticle: takeEvery(articleTypes.DELETE_ARTICLE, deleteArticleSaga),
  watchGetArticle: takeEvery(articleTypes.GET_ARTICLE, getArticleSaga),
  watchUpdateArticle: takeEvery(articleTypes.UPDATE_ARTICLE, updateArticleSaga)
};
