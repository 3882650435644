import * as OvercalorieDiabeticsTypes from "./overcalorieDiabetics.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  overcalorieDiabetics: null,
  loading: false
};

/**
 * FETCH OVERCALORIE DIABETICS
 */
const fetchOvercalorieDiabeticsStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchOvercalorieDiabeticsSuccess = (state, action) =>
  updateObject(state, {
    overcalorieDiabetics: action.overcalorieDiabetics,
    loading: false
  });

const fetchOvercalorieDiabeticsFail = (state, action) =>
  updateObject(state, { loading: false });

const OvercalorieDiabeticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OvercalorieDiabeticsTypes.FETCH_OVERCALORIE_DIABETICS_START:
      return fetchOvercalorieDiabeticsStart(state, action);
    case OvercalorieDiabeticsTypes.FETCH_OVERCALORIE_DIABETICS_SUCCESS:
      return fetchOvercalorieDiabeticsSuccess(state, action);
    case OvercalorieDiabeticsTypes.FETCH_OVERCALORIE_DIABETICS_FAIL:
      return fetchOvercalorieDiabeticsFail(state, action);

    default:
      return state;
  }
};

export default OvercalorieDiabeticsReducer;
