export const ADD_WATER_CONSUMPTION = "ADD_WATER_CONSUMPTION";
export const ADD_WATER_CONSUMPTION_START = "ADD_WATER_CONSUMPTION_START";
export const ADD_WATER_CONSUMPTION_SUCCESS = "ADD_WATER_CONSUMPTION_SUCCESS";
export const ADD_WATER_CONSUMPTION_FAIL = "ADD_WATER_CONSUMPTION_FAIL";

export const UPDATE_WATER_CONSUMPTION = "UPDATE_WATER_CONSUMPTION";
export const UPDATE_WATER_CONSUMPTION_START = "UPDATE_WATER_CONSUMPTION_START";
export const UPDATE_WATER_CONSUMPTION_SUCCESS = "UPDATE_WATER_CONSUMPTION_SUCCESS";
export const UPDATE_WATER_CONSUMPTION_FAIL = "UPDATE_WATER_CONSUMPTION_FAIL";

export const DELETE_WATER_CONSUMPTION = "DELETE_WATER_CONSUMPTION";
export const DELETE_WATER_CONSUMPTION_START = "DELETE_WATER_CONSUMPTION_START";
export const DELETE_WATER_CONSUMPTION_SUCCESS = "DELETE_WATER_CONSUMPTION_SUCCESS";
export const DELETE_WATER_CONSUMPTION_FAIL = "DELETE_WATER_CONSUMPTION_FAIL";

export const GET_WATER_CONSUMPTION = "GET_WATER_CONSUMPTIONS";
export const GET_WATER_CONSUMPTION_START = "GET_WATER_CONSUMPTION_START";
export const GET_WATER_CONSUMPTION_SUCCESS = "GET_WATER_CONSUMPTION_SUCCESS";
export const GET_WATER_CONSUMPTION_FAIL = "GET_WATER_CONSUMPTION_FAIL";

export const FETCH_WATER_CONSUMPTIONS = "FETCH_WATER_CONSUMPTIONS";
export const FETCH_WATER_CONSUMPTIONS_START = "FETCH_WATER_CONSUMPTIONS_START";
export const FETCH_WATER_CONSUMPTIONS_SUCCESS = "FETCH_WATER_CONSUMPTIONS_SUCCESS";
export const FETCH_WATER_CONSUMPTIONS_FAIL = "FETCH_WATER_CONSUMPTIONS_FAIL";

export const UPLOAD_WATER_CONSUMPTION_PICTURE = "UPLOAD_WATER_CONSUMPTION_PICTURE";
export const UPLOAD_WATER_CONSUMPTION_PICTURE_START = "UPLOAD_WATER_CONSUMPTION_PICTURE_START";
export const UPLOAD_WATER_CONSUMPTION_PICTURE_SUCCESS = "UPLOAD_WATER_CONSUMPTION_PICTURE_SUCCESS";
export const UPLOAD_WATER_CONSUMPTION_PICTURE_FAIL = "UPLOAD_WATER_CONSUMPTION_PICTURE_FAIL";
