import { put } from "redux-saga/effects";
import * as actions from "store/reducers/activity/Activity.actions";
import ActivityService from "../../../services/Activity";

/**
 * FETCH USER
 */
export function* fetchActivitySaga(action) {
  yield put(actions.fetchActivityStart());

  try {
    const service = yield new ActivityService();
    const activity = yield service.find(action.params);

    yield put(actions.fetchActivitySuccess(activity));
  } catch (error) {
    yield put(actions.fetchActivityFail(error));
  }
}
