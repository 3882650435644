import * as articleCategoryTypes from './article-category.types';

export const addArticleCategory = (data, history) => {
    return {
        type: articleCategoryTypes.ADD_ARTICLE_CATEGORY,
        data: data,
        history: history
    };
};

export const addArticleCategorySuccess = articleCategory => {
    return {
        type: articleCategoryTypes.ADD_ARTICLE_CATEGORY_SUCCESS,
        articleCategory: articleCategory
    };
};

export const addArticleCategoryFail = error => {
    return {
        type: articleCategoryTypes.ADD_ARTICLE_CATEGORY_FAIL,
        error: error
    };
};

export const addArticleCategoryStart = () => {
    return {
        type: articleCategoryTypes.ADD_ARTICLE_CATEGORY_START
    };
};

export const updateArticleCategory = (data, history) => {
    return {
        type: articleCategoryTypes.UPDATE_ARTICLE_CATEGORY,
        data: data,
        history: history
    };
};

export const updateArticleCategorySuccess = articleCategory => {
    return {
        type: articleCategoryTypes.UPDATE_ARTICLE_CATEGORY_SUCCESS,
        articleCategory: articleCategory
    };
};

export const updateArticleCategoryFail = error => {
    return {
        type: articleCategoryTypes.UPDATE_ARTICLE_CATEGORY_FAIL,
        error: error
    };
};

export const updateArticleCategoryStart = () => {
    return {
        type: articleCategoryTypes.UPDATE_ARTICLE_CATEGORY_START
    };
};

export const deleteArticleCategory = (id, history) => {
    return {
        type: articleCategoryTypes.DELETE_ARTICLE_CATEGORY,
        id: id,
        history: history
    };
};

export const deleteArticleCategorySuccess = () => {
    return {
        type: articleCategoryTypes.DELETE_ARTICLE_CATEGORY_SUCCESS,
    };
};

export const deleteArticleCategoryFail = error => {
    return {
        type: articleCategoryTypes.DELETE_ARTICLE_CATEGORY_FAIL,
        error: error
    };
};

export const deleteArticleCategoryStart = () => {
    return {
        type: articleCategoryTypes.DELETE_ARTICLE_CATEGORY_START
    };
};

export const getArticleCategory = (id) => {
    return {
        type: articleCategoryTypes.GET_ARTICLE_CATEGORY,
        id: id
    };
};

export const getArticleCategorySuccess = (articleCategory) => {
    return {
        type: articleCategoryTypes.GET_ARTICLE_CATEGORY_SUCCESS,
        articleCategory: articleCategory
    };
};

export const getArticleCategoryFail = error => {
    return {
        type: articleCategoryTypes.GET_ARTICLE_CATEGORY_FAIL,
        error: error
    };
};

export const getArticleCategoryStart = () => {
    return {
        type: articleCategoryTypes.GET_ARTICLE_CATEGORY_START
    };
};

export const fetchArticleCategorySuccess = articleCategories => {
    return {
        type: articleCategoryTypes.FETCH_ARTICLE_CATEGORY_SUCCESS,
        articleCategories: articleCategories
    };
};

export const fetchArticleCategoryFail = error => {
    return {
        type: articleCategoryTypes.FETCH_ARTICLE_CATEGORY_FAIL,
        error: error
    };
};

export const fetchArticleCategoryStart = () => {
    return {
        type: articleCategoryTypes.FETCH_ARTICLE_CATEGORY_START
    };
};

export const fetchArticleCategory = (params) => {
    return {
        type: articleCategoryTypes.FETCH_ARTICLE_CATEGORY,
        params: params
    };
};

// ====== UTILITY  ======= //
export const articleCategoryDismissError = () => {
    return {
        type: articleCategoryTypes.ARTICLE_CATEGORY_DISMISS_ERROR
    };
};

export const articleCategoryAddFilter = (filter, filterObj) => {
    return {
        type: articleCategoryTypes.ARTICLE_CATEGORY_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const articleCategoryClearFilter = () => {
    return {
        type: articleCategoryTypes.ARTICLE_CATEGORY_CLEAR_FILTER
    };
};

export const articleCategoryAddPagination = (pagination) => {
    return {
        type: articleCategoryTypes.ARTICLE_CATEGORY_ADD_PAGINATION,
        pagination: pagination
    };
};

export const articleCategoryClearPagination = () => {
    return {
        type: articleCategoryTypes.ARTICLE_CATEGORY_CLEAR_PAGINATION
    };
};