import * as GlucoseTypes from "./Glucose.types";

/**
 * FETCH Glucose
 */
export const fetchGlucose = (params) => ({
  type: GlucoseTypes.FETCH_GLUCOSE,
  params
});
export const fetchGlucoseSuccess = (glucose) => ({
  type: GlucoseTypes.FETCH_GLUCOSE_SUCCESS,
  glucose
});
export const fetchGlucoseFail = (error) => ({
  type: GlucoseTypes.FETCH_GLUCOSE_FAIL,
  error
});
export const fetchGlucoseStart = () => ({
  type: GlucoseTypes.FETCH_GLUCOSE_START
});
