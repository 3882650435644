import { put } from "redux-saga/effects";
import * as actions from "store/reducers/user/user.actions";
import uploadService from "services/Upload";
import userService from "services/Users";

/**
 * FETCH USER
 */
export function* fetchUsersSaga(action) {
  yield put(actions.fetchUsersStart());
  try {
    const service = yield new userService();
    const users = yield service.find(action.params);
    yield put(actions.fetchUsersSuccess(users));
  } catch (error) {
    yield put(actions.fetchUsersFail(error));
  }
}

/**
 * FETCH TOTAL USERS
 */
export function* fetchTotalUsersSaga(action) {
  yield put(actions.fetchTotalUsersStart());
  try {
    const service = yield new userService();
    const users = yield service.find(action.params);

    const lastMonthParams = {
      "created[$lt]": new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        1
      )
        .toISOString()
        .split("T")[0]
    };
    const lastMonthUsers = yield service.find(lastMonthParams);
    const denominator = lastMonthUsers.total > 0 ? lastMonthUsers.total : 1;

    const growthPercentage =
      ((users.total - lastMonthUsers.total) / denominator) * 100;
    yield put(
      actions.fetchTotalUsersSuccess({
        total: users.total,
        growthPercentage
      })
    );
  } catch (error) {
    yield put(actions.fetchTotalUsersFail(error));
  }
}

/**
 * FETCH ACTIVE USERS
 * Fetches all users that logged in during the past 30 days
 */
export function* fetchActiveUsersSaga(action) {
  yield put(actions.fetchActiveUsersStart());
  try {
    const service = yield new userService();
    const users = yield service.find(action.params);

    const lastMonthParams = {
      "lastLogin[$gte]": new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 2,
        1
      )
        .toISOString()
        .split("T")[0]
    };
    const lastMonthUsers = yield service.find(lastMonthParams);
    const lastMonthUserTotal = lastMonthUsers.total - users.total;
    const denominator = lastMonthUsers.total > 0 ? lastMonthUsers.total : 1;

    const growthPercentage =
      ((users.total - lastMonthUserTotal) / denominator) * 100;
    yield put(
      actions.fetchActiveUsersSuccess({
        total: users.total,
        growthPercentage
      })
    );
  } catch (error) {
    yield put(actions.fetchActiveUsersFail(error));
  }
}

/**
 * GET USER
 */
export function* getUserSaga(action) {
  yield put(actions.getUserStart());

  const service = yield new userService();
  const user = yield service.get(action.id);
  if (user.error) {
    yield put(actions.getUserFail(user.error));
    return;
  }

  yield put(actions.getUserSuccess(user));
}

/**
 * ADD USER
 */
export function* addUserSaga(action) {
  yield put(actions.addUserStart());

  // Img Upload
  if (action.imgUpload) {
    const upService = yield new uploadService();
    const upload = yield upService.upload(action.imgUpload);
    if (upload.error) {
      yield put(actions.uploadFail(upload.error));
      return;
    }
    action.userData.image = upload.data.url;
  }

  // Add User
  const service = yield new userService();
  const user = yield service.create(action.userData);
  if (user.error) {
    yield put(actions.addUserFail(user.error));
    return;
  }

  yield put(actions.addUserSuccess(user));
  yield action.history.goBack();
}

/**
 * UPDATE USER
 */
export function* updateUserSaga(action) {
  yield put(actions.updateUserStart());

  // Img Upload
  if (action.imgUpload) {
    const upService = yield new uploadService();
    const upload = yield upService.upload(action.imgUpload);
    if (upload.error) {
      yield put(actions.uploadFail(upload.error));
      return;
    }
    action.userData.image = upload.data.url;
  }

  // Add User
  const service = yield new userService();
  const user = service.patch(action.userData);
  if (user.error) {
    yield put(actions.updateUserFail(user.error));
    return;
  }

  yield put(actions.updateUserSuccess(user));
  yield action.history.goBack();
}

/**
 * DELETE USER
 */
export function* deleteUserSaga(action) {
  yield put(actions.deleteUserStart());

  const service = yield new userService();
  const user = yield service.delete(action.id);
  if (user.error) {
    yield put(actions.deleteUserFail(user.error));
    return;
  }

  yield put(actions.deleteUserSuccess());
  if (action.history) {
    action.history.goBack();
  }
}
