import * as HBA1CTypes from "./HBA1C.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  HBA1C: null,
  url: "",
  loading: false
};

/**
 * FETCH HBA1C
 */
const fetchHBA1CStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchHBA1CSuccess = (state, action) => {
  return updateObject(state, {
    HBA1C: action.HBA1C,
    loading: false
  });
};
const fetchHBA1CFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const HBA1CReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HBA1CTypes.FETCH_HBA1C_START:
      return fetchHBA1CStart(state, action);
    case HBA1CTypes.FETCH_HBA1C_SUCCESS:
      return fetchHBA1CSuccess(state, action);
    case HBA1CTypes.FETCH_HBA1C_FAIL:
      return fetchHBA1CFail(state, action);

    default:
      return state;
  }
};

export default HBA1CReducer;
