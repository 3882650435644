import * as pushNotificationTypes from './push-notification.types';

export const addPushNotification = (data, imgUploadPayload, history) => {
    return {
        type: pushNotificationTypes.ADD_PUSH_NOTIFICATION,
        data: data,
        imgUploadPayload: imgUploadPayload,
        history: history
    };
};

export const addPushNotificationSuccess = pushNotification => {
    return {
        type: pushNotificationTypes.ADD_PUSH_NOTIFICATION_SUCCESS,
        pushNotification: pushNotification
    };
};

export const addPushNotificationFail = error => {
    return {
        type: pushNotificationTypes.ADD_PUSH_NOTIFICATION_FAIL,
        error: error
    };
};

export const addPushNotificationStart = () => {
    return {
        type: pushNotificationTypes.ADD_PUSH_NOTIFICATION_START
    };
};

export const updatePushNotification = (data, imgUploadPayload, history) => {
    return {
        type: pushNotificationTypes.UPDATE_PUSH_NOTIFICATION,
        data: data,
        imgUploadPayload: imgUploadPayload,
        history: history
    };
};

export const updatePushNotificationSuccess = pushNotification => {
    return {
        type: pushNotificationTypes.UPDATE_PUSH_NOTIFICATION_SUCCESS,
        pushNotification: pushNotification
    };
};

export const updatePushNotificationFail = error => {
    return {
        type: pushNotificationTypes.UPDATE_PUSH_NOTIFICATION_FAIL,
        error: error
    };
};

export const updatePushNotificationStart = () => {
    return {
        type: pushNotificationTypes.UPDATE_PUSH_NOTIFICATION_START
    };
};

export const deletePushNotification = (id, history) => {
    return {
        type: pushNotificationTypes.DELETE_PUSH_NOTIFICATION,
        id: id,
        history: history
    };
};

export const deletePushNotificationSuccess = () => {
    return {
        type: pushNotificationTypes.DELETE_PUSH_NOTIFICATION_SUCCESS,
    };
};

export const deletePushNotificationFail = error => {
    return {
        type: pushNotificationTypes.DELETE_PUSH_NOTIFICATION_FAIL,
        error: error
    };
};

export const deletePushNotificationStart = () => {
    return {
        type: pushNotificationTypes.DELETE_PUSH_NOTIFICATION_START
    };
};

export const getPushNotification = (id) => {
    return {
        type: pushNotificationTypes.GET_PUSH_NOTIFICATION,
        id: id
    };
};

export const getPushNotificationSuccess = (pushNotification) => {
    return {
        type: pushNotificationTypes.GET_PUSH_NOTIFICATION_SUCCESS,
        pushNotification: pushNotification
    };
};

export const getPushNotificationFail = error => {
    return {
        type: pushNotificationTypes.GET_PUSH_NOTIFICATION_FAIL,
        error: error
    };
};

export const getPushNotificationStart = () => {
    return {
        type: pushNotificationTypes.GET_PUSH_NOTIFICATION_START
    };
};

export const fetchPushNotificationSuccess = pushNotifications => {
    return {
        type: pushNotificationTypes.FETCH_PUSH_NOTIFICATION_SUCCESS,
        pushNotifications: pushNotifications
    };
};

export const fetchPushNotificationFail = error => {
    return {
        type: pushNotificationTypes.FETCH_PUSH_NOTIFICATION_FAIL,
        error: error
    };
};

export const fetchPushNotificationStart = () => {
    return {
        type: pushNotificationTypes.FETCH_PUSH_NOTIFICATION_START
    };
};

export const fetchPushNotification = (params) => {
    return {
        type: pushNotificationTypes.FETCH_PUSH_NOTIFICATION,
        params: params
    };
};

// ====== UTILITY  ======= //
export const pushNotificationDismissError = () => {
    return {
        type: pushNotificationTypes.PUSH_NOTIFICATION_DISMISS_ERROR
    };
};

export const pushNotificationAddFilter = (filter, filterObj) => {
    return {
        type: pushNotificationTypes.PUSH_NOTIFICATION_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const pushNotificationClearFilter = () => {
    return {
        type: pushNotificationTypes.PUSH_NOTIFICATION_CLEAR_FILTER
    };
};

export const pushNotificationAddPagination = (pagination) => {
    return {
        type: pushNotificationTypes.PUSH_NOTIFICATION_ADD_PAGINATION,
        pagination: pagination
    };
};

export const pushNotificationClearPagination = () => {
    return {
        type: pushNotificationTypes.PUSH_NOTIFICATION_CLEAR_PAGINATION
    };
};
