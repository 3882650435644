export const ADD_PUSH_NOTIFICATION = 'ADD_PUSH_NOTIFICATION';
export const ADD_PUSH_NOTIFICATION_START = 'ADD_PUSH_NOTIFICATION_START';
export const ADD_PUSH_NOTIFICATION_SUCCESS = 'ADD_PUSH_NOTIFICATION_SUCCESS';
export const ADD_PUSH_NOTIFICATION_FAIL = 'ADD_PUSH_NOTIFICATION_FAIL';

export const DELETE_PUSH_NOTIFICATION = 'DELETE_PUSH_NOTIFICATION';
export const DELETE_PUSH_NOTIFICATION_START = 'DELETE_PUSH_NOTIFICATION_START';
export const DELETE_PUSH_NOTIFICATION_SUCCESS = 'DELETE_PUSH_NOTIFICATION_SUCCESS';
export const DELETE_PUSH_NOTIFICATION_FAIL = 'DELETE_PUSH_NOTIFICATION_FAIL';


export const UPDATE_PUSH_NOTIFICATION = 'UPDATE_PUSH_NOTIFICATION';
export const UPDATE_PUSH_NOTIFICATION_START = 'UPDATE_PUSH_NOTIFICATION_START';
export const UPDATE_PUSH_NOTIFICATION_SUCCESS = 'UPDATE_PUSH_NOTIFICATION_SUCCESS';
export const UPDATE_PUSH_NOTIFICATION_FAIL = 'UPDATE_PUSH_NOTIFICATION_FAIL';

export const GET_PUSH_NOTIFICATION = 'GET_PUSH_NOTIFICATION';
export const GET_PUSH_NOTIFICATION_START = 'GET_PUSH_NOTIFICATION_START';
export const GET_PUSH_NOTIFICATION_SUCCESS = 'GET_PUSH_NOTIFICATION_SUCCESS';
export const GET_PUSH_NOTIFICATION_FAIL = 'GET_PUSH_NOTIFICATION_FAIL';

export const FETCH_PUSH_NOTIFICATION = 'FETCH_PUSH_NOTIFICATION';
export const FETCH_PUSH_NOTIFICATION_START = 'FETCH_PUSH_NOTIFICATION_START';
export const FETCH_PUSH_NOTIFICATION_SUCCESS = 'FETCH_PUSH_NOTIFICATION_SUCCESS';
export const FETCH_PUSH_NOTIFICATION_FAIL = 'FETCH_PUSH_NOTIFICATION_FAIL';


// ====== UTILITY  ======= //
export const PUSH_NOTIFICATION_DISMISS_ERROR = 'PUSH_NOTIFICATION_DISMISS_ERROR';
export const PUSH_NOTIFICATION_ADD_FILTER = 'PUSH_NOTIFICATION_ADD_FILTER';
export const PUSH_NOTIFICATION_CLEAR_FILTER = 'PUSH_NOTIFICATION_CLEAR_FILTER';
export const PUSH_NOTIFICATION_ADD_PAGINATION = 'PUSH_NOTIFICATION_ADD_PAGINATION';
export const PUSH_NOTIFICATION_CLEAR_PAGINATION = 'PUSH_NOTIFICATION_CLEAR_PAGINATION';