import { takeEvery } from "redux-saga/effects";
import * as OvercalorieDiabeticsTypes from "store/reducers/overcalorie-diabetics/overcalorieDiabetics.types";
import { fetchOvercalorieDiabeticsSaga } from "./OvercalorieDiabeticsSaga";

export const overcalorieDiabeticsSagas = {
  watchFetchOvercalorieDiabetics: takeEvery(
    OvercalorieDiabeticsTypes.FETCH_OVERCALORIE_DIABETICS,
    fetchOvercalorieDiabeticsSaga
  )
};
