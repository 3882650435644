import * as articleCategoryTypes from "./article-category.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  articleCategories: {},
  articleCategory: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  },
};

const fetchArticleCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchArticleCategorySuccess = (state, action) => {
  return updateObject(state, {
    articleCategories: action.articleCategories,
    articleCategory: {},
    loading: false,
    error: null
  });
};

const fetchArticleCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addArticleCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addArticleCategorySuccess = (state, action) => {
  return updateObject(state, {
    articleCategory: action.articleCategory,
    loading: false,
    error: null
  });
};

const addArticleCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteArticleCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteArticleCategorySuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deleteArticleCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getArticleCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getArticleCategorySuccess = (state, action) => {
  return updateObject(state, {
    articleCategory: action.articleCategory,
    loading: false,
    error: null
  });
};

const getArticleCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateArticleCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateArticleCategorySuccess = (state, action) => {
  return updateObject(state, {
    articleCategory: action.articleCategory,
    loading: false,
    error: null
  });
};

const updateArticleCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const articleCategoryDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const articleCategoryAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj
  });
};

const articleCategoryClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {}
  });
};

const articleCategoryAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination
  });
};

const articleCategoryClearPagination = (state) => {
  return updateObject(state, {
    pagination: {}
  });
};

const articleCategoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case articleCategoryTypes.FETCH_ARTICLE_CATEGORY_START:
      return fetchArticleCategoryStart(state, action);
    case articleCategoryTypes.FETCH_ARTICLE_CATEGORY_SUCCESS:
      return fetchArticleCategorySuccess(state, action);
    case articleCategoryTypes.FETCH_ARTICLE_CATEGORY_FAIL:
      return fetchArticleCategoryFail(state, action);
    case articleCategoryTypes.ADD_ARTICLE_CATEGORY_START:
      return addArticleCategoryStart(state, action);
    case articleCategoryTypes.ADD_ARTICLE_CATEGORY_SUCCESS:
      return addArticleCategorySuccess(state, action);
    case articleCategoryTypes.ADD_ARTICLE_CATEGORY_FAIL:
      return addArticleCategoryFail(state, action);
    case articleCategoryTypes.DELETE_ARTICLE_CATEGORY_START:
      return deleteArticleCategoryStart(state, action);
    case articleCategoryTypes.DELETE_ARTICLE_CATEGORY_SUCCESS:
      return deleteArticleCategorySuccess(state, action);
    case articleCategoryTypes.DELETE_ARTICLE_CATEGORY_FAIL:
      return deleteArticleCategoryFail(state, action);
    case articleCategoryTypes.GET_ARTICLE_CATEGORY_START:
      return getArticleCategoryStart(state, action);
    case articleCategoryTypes.GET_ARTICLE_CATEGORY_SUCCESS:
      return getArticleCategorySuccess(state, action);
    case articleCategoryTypes.GET_ARTICLE_CATEGORY_FAIL:
      return getArticleCategoryFail(state, action);
    case articleCategoryTypes.UPDATE_ARTICLE_CATEGORY_START:
      return updateArticleCategoryStart(state, action);
    case articleCategoryTypes.UPDATE_ARTICLE_CATEGORY_SUCCESS:
      return updateArticleCategorySuccess(state, action);
    case articleCategoryTypes.UPDATE_ARTICLE_CATEGORY_FAIL:
      return updateArticleCategoryFail(state, action);
    case articleCategoryTypes.ARTICLE_CATEGORY_DISMISS_ERROR:
      return articleCategoryDismissError(state);
    case articleCategoryTypes.ARTICLE_CATEGORY_ADD_FILTER:
      return articleCategoryAddFilter(state, action);
    case articleCategoryTypes.ARTICLE_CATEGORY_CLEAR_FILTER:
      return articleCategoryClearFilter(state);
    case articleCategoryTypes.ARTICLE_CATEGORY_ADD_PAGINATION:
      return articleCategoryAddPagination(state, action);
    case articleCategoryTypes.ARTICLE_CATEGORY_CLEAR_PAGINATION:
      return articleCategoryClearPagination(state);
    default:
      return state;
  }
};

export default articleCategoryReducer;
