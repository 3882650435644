import * as onboardingTypes from "store/reducers/onboarding/onboarding.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchOnboardingSaga,
  addOnboardingSaga,
  deleteOnboardingSaga,
  getOnboardingSaga,
  updateOnboardingSaga
} from "./onboarding";

export const onboardingSagas = {
  watchFetchOnboarding: takeEvery(onboardingTypes.FETCH_ONBOARDING, fetchOnboardingSaga),
  watchAddOnboarding: takeEvery(onboardingTypes.ADD_ONBOARDING, addOnboardingSaga),
  watchDeleteOnboarding: takeEvery(onboardingTypes.DELETE_ONBOARDING, deleteOnboardingSaga),
  watchGetOnboarding: takeEvery(onboardingTypes.GET_ONBOARDING, getOnboardingSaga),
  watchUpdateOnboarding: takeEvery(onboardingTypes.UPDATE_ONBOARDING, updateOnboardingSaga)
}