import { put } from "redux-saga/effects";
import * as actions from "../../reducers/medicine/medicine.actions";
import MedicineService from "../../../services/Medicine";
import uploadService from "../../../services/Upload";

/**
 * FETCH MEDICINES
 */
export function* fetchMedicinesSaga(action) {
  yield put(actions.fetchMedicinesStart());
  const service = yield new MedicineService();
  const medicines = yield service.find(action.params);
  if (medicines.error) {
    yield put(actions.fetchMedicinesFail(medicines.error));
    return;
  }
  yield put(actions.fetchMedicinesSuccess(medicines));
}

/**
 * CREATE MEDICINE
 */
export function* addMedicineSaga(action) {
  yield put(actions.addMedicineStart());
  const upService = yield new uploadService();
  let img = {};
  if (action.imgUploadPayload) {
    img = yield upService.upload(action.imgUploadPayload);
    if (img.error) {
      yield put(actions.addMedicineFail(img.error));
      return;
    }
    action.data.image = img.data.url;
  }

  const service = yield new MedicineService();
  const medicine = yield service.create(action.data);
  if (medicine.error) {
    yield put(actions.addMedicineFail(medicine.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * GET MEDICINE
 */
export function* getMedicineSaga(action) {
  yield put(actions.getMedicineStart());
  const service = yield new MedicineService();
  const medicine = yield service.get(action.id);
  if (medicine.error) {
    yield put(actions.getMedicineFail(medicine.error));
    return;
  }

  yield put(actions.getMedicineSuccess(medicine));
}

/**
 * UPDATE MEDICINE
 */
export function* updateMedicineSaga(action) {
  yield put(actions.updateMedicineStart());

  const upService = yield new uploadService();
  let img = {};
  if (action.imgUploadPayload) {
    img = yield upService.upload(action.imgUploadPayload);
    if (img.error) {
      yield put(actions.addMedicineFail(img.error));
      return;
    }
    action.data.image = img.data.url;
  }

  const service = yield new MedicineService();
  const medicine = yield service.patch(action.data);
  if (medicine.error) {
    yield put(actions.updateMedicineFail(medicine.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * DELETE MEDICINE
 */
export function* deleteMedicineSaga(action) {
  yield put(actions.deleteMedicineStart());
  const service = yield new MedicineService();
  const medicine = yield service.delete(action.id);
  if (medicine.error) {
    yield put(actions.deleteMedicineFail(medicine.error));
    return;
  }
  yield put(actions.deleteMedicineSuccess());
  if (action.history) {
    action.history.goBack();
  }
}
