import Service from "./Service";

export default class AdminAuthManagement extends Service {
  constructor() {
    super("authmanagement-admin", true);
  }

  async create(data) {
    const response = await super.create(data);
    return super.generateDefaultResponse(response);
  }
}
