import { takeEvery } from "redux-saga/effects";
import * as adminTypes from "store/reducers/admins/admins.types";
import {
  fetchAdminsSaga,
  addAdminSaga,
  deleteAdminSaga,
  getAdminSaga,
  updateAdminSaga
} from "./admins";

export const adminSagas = {
  watchFetchAdmins: takeEvery(adminTypes.FETCH_ADMINS, fetchAdminsSaga),
  watchAddAdmin: takeEvery(adminTypes.ADD_ADMIN, addAdminSaga),
  watchDeleteAdmin: takeEvery(adminTypes.DELETE_ADMIN, deleteAdminSaga),
  watchGetAdmin: takeEvery(adminTypes.GET_ADMIN, getAdminSaga),
  watchUpdateAdmin:  takeEvery(adminTypes.UPDATE_ADMIN, updateAdminSaga)
};