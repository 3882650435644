import * as foodRecipeTypes from "./food-recipe.types";

/**
 * FETCH FOOD_RECIPES
 */
export const fetchFoodRecipes = (params) => ({
  type: foodRecipeTypes.FETCH_FOOD_RECIPES,
  params
});
export const fetchFoodRecipesStart = () => ({
  type: foodRecipeTypes.FETCH_FOOD_RECIPES_START
});
export const fetchFoodRecipesSuccess = (foodRecipes) => ({
  type: foodRecipeTypes.FETCH_FOOD_RECIPES_SUCCESS,
  foodRecipes
});
export const fetchFoodRecipesFail = (error) => ({
  type: foodRecipeTypes.FETCH_FOOD_RECIPES_FAIL,
  error
});

/**
 * GET FOOD_RECIPE
 */
export const getFoodRecipe = (id) => ({
  type: foodRecipeTypes.GET_FOOD_RECIPE,
  id
});
export const getFoodRecipeStart = () => ({
  type: foodRecipeTypes.GET_FOOD_RECIPE_START
});
export const getFoodRecipeSuccess = (foodRecipe) => ({
  type: foodRecipeTypes.GET_FOOD_RECIPE_SUCCESS,
  foodRecipe
});
export const getFoodRecipeFail = (error) => ({
  type: foodRecipeTypes.GET_FOOD_RECIPE_FAIL,
  error
});

/**
 * CREATE FOOD_RECIPE
 */
export const addFoodRecipe = (history, data, imgUploadPayload) => ({
  type: foodRecipeTypes.ADD_FOOD_RECIPE,
  history,
  data,
  imgUploadPayload
});
export const addFoodRecipeStart = () => ({
  type: foodRecipeTypes.ADD_FOOD_RECIPE_START
});
export const addFoodRecipeSuccess = (foodRecipe) => ({
  type: foodRecipeTypes.ADD_FOOD_RECIPE_SUCCESS,
  foodRecipe
});
export const addFoodRecipeFail = (error) => ({
  type: foodRecipeTypes.ADD_FOOD_RECIPE_FAIL,
  error
});

/**
 * UPDATE FOOD_RECIPE
 */
export const updateFoodRecipe = (history, data, imgUploadPayload) => ({
  type: foodRecipeTypes.UPDATE_FOOD_RECIPE,
  history,
  data,
  imgUploadPayload
});
export const updateFoodRecipeStart = () => ({
  type: foodRecipeTypes.UPDATE_FOOD_RECIPE_START
});
export const updateFoodRecipeSuccess = (foodRecipe) => ({
  type: foodRecipeTypes.UPDATE_FOOD_RECIPE_SUCCESS,
  foodRecipe
});
export const updateFoodRecipeFail = (error) => ({
  type: foodRecipeTypes.UPDATE_FOOD_RECIPE_FAIL,
  error
});

/**
 * DELETE FOOD_RECIPE
 */
export const deleteFoodRecipe = (id, history) => ({
  type: foodRecipeTypes.DELETE_FOOD_RECIPE,
  id,
  history
});
export const deleteFoodRecipeStart = () => ({
  type: foodRecipeTypes.DELETE_FOOD_RECIPE_START
});
export const deleteFoodRecipeSuccess = () => ({
  type: foodRecipeTypes.DELETE_FOOD_RECIPE_SUCCESS
});

export const deleteFoodRecipeFail = (error) => ({
  type: foodRecipeTypes.DELETE_FOOD_RECIPE_FAIL,
  error
});

// ====== UTILITY  ======= //
export const foodRecipeDismissError = () => {
  return {
      type: foodRecipeTypes.FOOD_RECIPE_DISMISS_ERROR
  };
};

export const foodRecipeAddFilter = (filter, filterObj) => {
  return {
      type: foodRecipeTypes.FOOD_RECIPE_ADD_FILTER,
      filter: filter,
      filterObj: filterObj
  };
};

export const foodRecipeClearFilter = () => {
  return {
      type: foodRecipeTypes.FOOD_RECIPE_CLEAR_FILTER
  };
};

export const foodRecipeAddPagination = (pagination) => {
  return {
      type: foodRecipeTypes.FOOD_RECIPE_ADD_PAGINATION,
      pagination: pagination
  };
};

export const foodRecipeClearPagination = () => {
  return {
      type: foodRecipeTypes.FOOD_RECIPE_CLEAR_PAGINATION
  };
};
