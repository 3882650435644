import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import Spinner from 'components/atoms/Spinner/Spinner';
import { authCheckState } from 'store/reducers/auth/auth.actions';
import { useSelector, useDispatch } from 'react-redux';
const AdminLayout = lazy(() => import('./layouts/Admin'));
const AuthLayout = lazy(() => import('./layouts/Auth'));
const WebviewLayout = lazy(() => import('./layouts/Webview'));

function App() {
  const auth = useSelector(state => state.auth);
  const token = auth.token || localStorage.getItem('token');
  const dispatch = useDispatch();
  let routes = (
    <Switch>
      <Route path="/auth" component={AuthLayout} />
      <Route path="/webview" component={WebviewLayout} />
      <Redirect from="/" to="/auth/index" />
    </Switch>
  );

  if (token) {
    routes = (
      <Switch>
        <Route path="/admin" component={AdminLayout} />
        <Route path="/webview" component={WebviewLayout} />
        <Redirect to="/admin" />
      </Switch>
    );
  }

  useEffect(() => {
    dispatch(authCheckState());
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>{routes}</Suspense>
    </BrowserRouter>
  );
}

export default App;
