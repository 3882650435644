import * as AdminLogTypes from './AdminLog.types';

/**
 * FETCH ADMIN LOG
 */
export const fetchAdminLog = params => ({
  type: AdminLogTypes.FETCH_ADMIN_LOG,
  params
});
export const fetchAdminLogSuccess = logs => ({
  type: AdminLogTypes.FETCH_ADMIN_LOG_SUCCESS,
  logs
});
export const fetchAdminLogFail = error => ({
  type: AdminLogTypes.FETCH_ADMIN_LOG_FAIL,
  error
});
export const fetchAdminLogStart = () => ({
  type: AdminLogTypes.FETCH_ADMIN_LOG_START
});

/**
 * ADD ADMIN LOG
 */
export const addAdminLog = (data, history) => ({
  type: AdminLogTypes.ADD_ADMIN_LOG,
  data,
  history
});
export const addAdminLogSuccess = log => ({
  type: AdminLogTypes.ADD_ADMIN_LOG_SUCCESS,
  log
});
export const addAdminLogFail = error => ({
  type: AdminLogTypes.ADD_ADMIN_LOG_FAIL,
  error
});
export const addAdminLogStart = () => ({
  type: AdminLogTypes.ADD_ADMIN_LOG_START
});
