import * as greetingTypes from "./greeting.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  greetings: {},
  greeting: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1,
  },
};

const fetchGreetingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchGreetingSuccess = (state, action) => {
  return updateObject(state, {
    greetings: action.greetings,
    greeting: {},
    loading: false,
    error: null
  });
};

const fetchGreetingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addGreetingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addGreetingSuccess = (state, action) => {
  return updateObject(state, {
    greeting: action.greeting,
    loading: false,
    error: null
  });
};

const addGreetingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteGreetingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteGreetingSuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deleteGreetingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getGreetingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getGreetingSuccess = (state, action) => {
  return updateObject(state, {
    greeting: action.greeting,
    loading: false,
    error: null
  });
};

const getGreetingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateGreetingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateGreetingSuccess = (state, action) => {
  return updateObject(state, {
    greeting: action.greeting,
    loading: false,
    error: null
  });
};

const updateGreetingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const greetingDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const greetingAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj
  });
};

const greetingClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {}
  });
};

const greetingAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination
  });
};

const greetingClearPagination = (state) => {
  return updateObject(state, {
    pagination: {}
  });
};

const greetingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case greetingTypes.FETCH_GREETING_START:
      return fetchGreetingStart(state, action);
    case greetingTypes.FETCH_GREETING_SUCCESS:
      return fetchGreetingSuccess(state, action);
    case greetingTypes.FETCH_GREETING_FAIL:
      return fetchGreetingFail(state, action);
    case greetingTypes.ADD_GREETING_START:
      return addGreetingStart(state, action);
    case greetingTypes.ADD_GREETING_SUCCESS:
      return addGreetingSuccess(state, action);
    case greetingTypes.ADD_GREETING_FAIL:
      return addGreetingFail(state, action);
    case greetingTypes.DELETE_GREETING_START:
      return deleteGreetingStart(state, action);
    case greetingTypes.DELETE_GREETING_SUCCESS:
      return deleteGreetingSuccess(state, action);
    case greetingTypes.DELETE_GREETING_FAIL:
      return deleteGreetingFail(state, action);
    case greetingTypes.GET_GREETING_START:
      return getGreetingStart(state, action);
    case greetingTypes.GET_GREETING_SUCCESS:
      return getGreetingSuccess(state, action);
    case greetingTypes.GET_GREETING_FAIL:
      return getGreetingFail(state, action);
    case greetingTypes.UPDATE_GREETING_START:
      return updateGreetingStart(state, action);
    case greetingTypes.UPDATE_GREETING_SUCCESS:
      return updateGreetingSuccess(state, action);
    case greetingTypes.UPDATE_GREETING_FAIL:
      return updateGreetingFail(state, action);
    case greetingTypes.GREETING_DISMISS_ERROR:
      return greetingDismissError(state);
    case greetingTypes.GREETING_ADD_FILTER:
      return greetingAddFilter(state, action);
    case greetingTypes.GREETING_CLEAR_FILTER:
      return greetingClearFilter(state);
    case greetingTypes.GREETING_ADD_PAGINATION:
      return greetingAddPagination(state, action);
    case greetingTypes.GREETING_CLEAR_PAGINATION:
      return greetingClearPagination(state);
    default:
      return state;
  }
};

export default greetingReducer;
