import { put } from "redux-saga/effects";
import * as actions from "store/reducers/waterConsumption/waterConsumption.actions";
import waterConsumptionService from "services/WaterConsumption";

/**
 * FETCH USER
 */
export function* fetchWaterConsumptionsSaga(action) {
  yield put(actions.fetchWaterConsumptionsStart());
  try {
    const service = yield new waterConsumptionService();
    const waterConsumptions = yield service.find(action.params);
    yield put(actions.fetchWaterConsumptionsSuccess(waterConsumptions));
  } catch (error) {
    yield put(actions.fetchWaterConsumptionsFail(error));
  }
}

/**
 * GET USER
 */
export function* getWaterConsumptionSaga(action) {
  yield put(actions.getWaterConsumptionStart());

  const service = yield new waterConsumptionService();
  const waterConsumption = yield service.get(action.id);
  if (waterConsumption.error) {
    yield put(actions.getWaterConsumptionFail(waterConsumption.error));
    return;
  }

  yield put(actions.getWaterConsumptionSuccess(waterConsumption));
}
