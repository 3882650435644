import * as bannerTypes from "store/reducers/banner/banner.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchBannersSaga,
  addBannerSaga,
  deleteBannerSaga,
  getBannerSaga,
  updateBannerSaga
} from "./banner";

export const bannerSagas = {
  watchFetchBanners: takeEvery(bannerTypes.FETCH_BANNERS, fetchBannersSaga),
  watchAddBanner: takeEvery(bannerTypes.ADD_BANNER, addBannerSaga),
  watchDeleteBanner: takeEvery(bannerTypes.DELETE_BANNER, deleteBannerSaga),
  watchGetBanner: takeEvery(bannerTypes.GET_BANNER, getBannerSaga),
  watchUpdateBanner: takeEvery(bannerTypes.UPDATE_BANNER, updateBannerSaga)
};
