import { put } from 'redux-saga/effects';
import * as actions from 'store/reducers/adminLog/AdminLog.actions';
import AdminLogService from '../../../services/AdminLog';

/**
 * FETCH USER
 */
export function* fetchAdminLogSaga(action) {
  yield put(actions.fetchAdminLogStart());

  try {
    const service = yield new AdminLogService();
    const logs = yield service.find(action.params);

    yield put(actions.fetchAdminLogSuccess(logs));
  } catch (error) {
    yield put(actions.fetchAdminLogFail(error));
  }
}

/**
 * CREATE ADMIN LOG
 */
export function* addAdminLogSaga(action) {
  console.log(action);
  yield put(actions.addAdminLogStart());

  try {
    const service = yield new AdminLogService();
    const log = yield service.create(action.data);

    yield put(actions.addAdminLogSuccess(log));
    action.history.goBack();
  } catch (error) {
    yield put(actions.addAdminLogFail(error));
  }
}
