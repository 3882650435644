import * as contentViewTypes from "./content-views.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  contentView: {},
  error: null,
  loading: false
};

const fetchContentViewStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchContentViewSuccess = (state, action) => {
  return updateObject(state, {
    [action.typeParams]: action.contentViews,
    contentView: {},
    loading: false,
    error: null
  });
};

const fetchContentViewFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getContentViewStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getContentViewSuccess = (state, action) => {
  return updateObject(state, {
    contentView: action.contentView,
    loading: false,
    error: null
  });
};

const getContentViewFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const contentViewDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const contentViewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case contentViewTypes.FETCH_CONTENT_VIEWS_START:
      return fetchContentViewStart(state, action);
    case contentViewTypes.FETCH_CONTENT_VIEWS_SUCCESS:
      return fetchContentViewSuccess(state, action);
    case contentViewTypes.FETCH_CONTENT_VIEWS_FAIL:
      return fetchContentViewFail(state, action);
    case contentViewTypes.GET_CONTENT_VIEWS_START:
      return getContentViewStart(state, action);
    case contentViewTypes.GET_CONTENT_VIEWS_SUCCESS:
      return getContentViewSuccess(state, action);
    case contentViewTypes.GET_CONTENT_VIEWS_FAIL:
      return getContentViewFail(state, action);
    case contentViewTypes.CONTENT_VIEWS_DISMISS_ERROR:
      return contentViewDismissError(state);

    default:
      return state;
  }
};

export default contentViewReducer;
