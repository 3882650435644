import * as physicalActivityTypes from "./physical-activity.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  physicalActivities: {},
  physicalActivity: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1,
  },
};

const fetchPhysicalActivitiesStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchPhysicalActivitiesSuccess = (state, action) => {
  return updateObject(state, {
    physicalActivities: action.physicalActivities,
    physicalActivity: {},
    loading: false,
    error: null
  });
};

const fetchPhysicalActivitiesFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addPhysicalActivityStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addPhysicalActivitySuccess = (state, action) => {
  return updateObject(state, {
    physicalActivity: action.physicalActivity,
    loading: false,
    error: null
  });
};

const addPhysicalActivityFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deletePhysicalActivityStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deletePhysicalActivitySuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deletePhysicalActivityFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getPhysicalActivityStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getPhysicalActivitySuccess = (state, action) => {
  return updateObject(state, {
    physicalActivity: action.physicalActivity,
    loading: false,
    error: null
  });
};

const getPhysicalActivityFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updatePhysicalActivityStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updatePhysicalActivitySuccess = (state, action) => {
  return updateObject(state, {
    physicalActivity: action.physicalActivity,
    loading: false,
    error: null
  });
};

const updatePhysicalActivityFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const physicalActivityDismissError = (state) => {
  return updateObject(state, {
    error: null,
  });
};

const physicalActivityAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj,
  });
};

const physicalActivityClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {},
  });
};

const physicalActivityAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination,
  });
};

const physicalActivityClearPagination = (state) => {
  return updateObject(state, {
    pagination: {},
  });
};

const physicalActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case physicalActivityTypes.FETCH_PHYSICAL_ACTIVITIES_START:
      return fetchPhysicalActivitiesStart(state, action);
    case physicalActivityTypes.FETCH_PHYSICAL_ACTIVITIES_SUCCESS:
      return fetchPhysicalActivitiesSuccess(state, action);
    case physicalActivityTypes.FETCH_PHYSICAL_ACTIVITIES_FAIL:
      return fetchPhysicalActivitiesFail(state, action);
    case physicalActivityTypes.ADD_PHYSICAL_ACTIVITY_START:
      return addPhysicalActivityStart(state, action);
    case physicalActivityTypes.ADD_PHYSICAL_ACTIVITY_SUCCESS:
      return addPhysicalActivitySuccess(state, action);
    case physicalActivityTypes.ADD_PHYSICAL_ACTIVITY_FAIL:
      return addPhysicalActivityFail(state, action);
    case physicalActivityTypes.DELETE_PHYSICAL_ACTIVITY_START:
      return deletePhysicalActivityStart(state, action);
    case physicalActivityTypes.DELETE_PHYSICAL_ACTIVITY_SUCCESS:
      return deletePhysicalActivitySuccess(state, action);
    case physicalActivityTypes.DELETE_PHYSICAL_ACTIVITY_FAIL:
      return deletePhysicalActivityFail(state, action);
    case physicalActivityTypes.GET_PHYSICAL_ACTIVITY_START:
      return getPhysicalActivityStart(state, action);
    case physicalActivityTypes.GET_PHYSICAL_ACTIVITY_SUCCESS:
      return getPhysicalActivitySuccess(state, action);
    case physicalActivityTypes.GET_PHYSICAL_ACTIVITY_FAIL:
      return getPhysicalActivityFail(state, action);
    case physicalActivityTypes.UPDATE_PHYSICAL_ACTIVITY_START:
      return updatePhysicalActivityStart(state, action);
    case physicalActivityTypes.UPDATE_PHYSICAL_ACTIVITY_SUCCESS:
      return updatePhysicalActivitySuccess(state, action);
    case physicalActivityTypes.UPDATE_PHYSICAL_ACTIVITY_FAIL:
      return updatePhysicalActivityFail(state, action);
    case physicalActivityTypes.PHYSICAL_ACTIVITY_DISMISS_ERROR:
      return physicalActivityDismissError(state);
    case physicalActivityTypes.PHYSICAL_ACTIVITY_ADD_FILTER:
      return physicalActivityAddFilter(state, action);
    case physicalActivityTypes.PHYSICAL_ACTIVITY_CLEAR_FILTER:
      return physicalActivityClearFilter(state);
    case physicalActivityTypes.PHYSICAL_ACTIVITY_ADD_PAGINATION:
      return physicalActivityAddPagination(state, action);
    case physicalActivityTypes.PHYSICAL_ACTIVITY_CLEAR_PAGINATION:
      return physicalActivityClearPagination(state);

    default:
      return state;
  }
};

export default physicalActivityReducer;
