import * as contentTypes from './content.types';

export const addContent = (data, imgUploadPayload, history) => {
    return {
        type: contentTypes.ADD_CONTENT,
        imgUploadPayload: imgUploadPayload,
        data: data,
        history: history
    };
};

export const addContentSuccess = content => {
    return {
        type: contentTypes.ADD_CONTENT_SUCCESS,
        content: content
    };
};

export const addContentFail = error => {
    return {
        type: contentTypes.ADD_CONTENT_FAIL,
        error: error
    };
};

export const addContentStart = () => {
    return {
        type: contentTypes.ADD_CONTENT_START
    };
};

export const contentDismissError = () => {
    return {
        type: contentTypes.CONTENT_DISMISS_ERROR
    };
};

export const updateContent = (data, imgUploadPayload, history) => {
    return {
        type: contentTypes.UPDATE_CONTENT,
        imgUploadPayload: imgUploadPayload,
        data: data,
        history: history
    };
};

export const updateContentSuccess = content => {
    return {
        type: contentTypes.UPDATE_CONTENT_SUCCESS,
        content: content
    };
};

export const updateContentFail = error => {
    return {
        type: contentTypes.UPDATE_CONTENT_FAIL,
        error: error
    };
};

export const updateContentStart = () => {
    return {
        type: contentTypes.UPDATE_CONTENT_START
    };
};

export const deleteContent = (id, history) => {
    return {
        type: contentTypes.DELETE_CONTENT,
        id: id,
        history: history
    };
};

export const deleteContentSuccess = () => {
    return {
        type: contentTypes.DELETE_CONTENT_SUCCESS,
    };
};

export const deleteContentFail = error => {
    return {
        type: contentTypes.DELETE_CONTENT_FAIL,
        error: error
    };
};

export const deleteContentStart = () => {
    return {
        type: contentTypes.DELETE_CONTENT_START
    };
};

export const getContent = (id) => {
    return {
        type: contentTypes.GET_CONTENT,
        id: id
    };
};

export const getContentSuccess = (content) => {
    return {
        type: contentTypes.GET_CONTENT_SUCCESS,
        content: content
    };
};

export const getContentFail = error => {
    return {
        type: contentTypes.GET_CONTENT_FAIL,
        error: error
    };
};

export const getContentStart = () => {
    return {
        type: contentTypes.GET_CONTENT_START
    };
};

export const fetchContentsSuccess = contents => {
    return {
        type: contentTypes.FETCH_CONTENTS_SUCCESS,
        contents: contents
    };
};

export const fetchContentsFail = error => {
    return {
        type: contentTypes.FETCH_CONTENTS_FAIL,
        error: error
    };
};

export const fetchContentsStart = () => {
    return {
        type: contentTypes.FETCH_CONTENTS_START
    };
};

export const fetchContents = (params) => {
    return {
        type: contentTypes.FETCH_CONTENTS,
        params: params
    };
};
