import * as pushNotificationTypes from "store/reducers/push-notification/push-notification.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchPushNotificationSaga,
  addPushNotificationSaga,
  deletePushNotificationSaga,
  getPushNotificationSaga,
  updatePushNotificationSaga
} from "./push-notification";

export const pushNotificationSagas = {
  watchFetchPushNotification: takeEvery(pushNotificationTypes.FETCH_PUSH_NOTIFICATION, fetchPushNotificationSaga),
  watchAddPushNotification: takeEvery(pushNotificationTypes.ADD_PUSH_NOTIFICATION, addPushNotificationSaga),
  watchDeletePushNotification: takeEvery(pushNotificationTypes.DELETE_PUSH_NOTIFICATION, deletePushNotificationSaga),
  watchGetPushNotification: takeEvery(pushNotificationTypes.GET_PUSH_NOTIFICATION, getPushNotificationSaga),
  watchUpdatePushNotification: takeEvery(pushNotificationTypes.UPDATE_PUSH_NOTIFICATION, updatePushNotificationSaga)
}