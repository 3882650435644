import * as contentCategoryTypes from "./content-category.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  contentsCategories: {},
  contentCategory: {},
  successDeteled: "",
  error: null,
  loading: false
};

const fetchContentsCategoriesStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchContentsCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    contentsCategories: action.contentsCategories,
    contentCategory: {},
    loading: false,
    error: null
  });
};

const fetchContentsCategoriesFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addContentCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addContentCategorySuccess = (state, action) => {
  return updateObject(state, {
    contentCategory: action.contentCategory,
    loading: false,
    error: null
  });
};

const addContentCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteContentCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteContentCategorySuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deleteContentCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getContentCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getContentCategorySuccess = (state, action) => {
  return updateObject(state, {
    contentCategory: action.contentCategory,
    loading: false,
    error: null
  });
};

const getContentCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateContentCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateContentCategorySuccess = (state, action) => {
  return updateObject(state, {
    contentCategory: action.contentCategory,
    loading: false,
    error: null
  });
};

const updateContentCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const contentCategoryDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const contentCategoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case contentCategoryTypes.FETCH_CONTENTS_CATEGORIES_START:
      return fetchContentsCategoriesStart(state, action);
    case contentCategoryTypes.FETCH_CONTENTS_CATEGORIES_SUCCESS:
      return fetchContentsCategoriesSuccess(state, action);
    case contentCategoryTypes.FETCH_CONTENTS_CATEGORIES_FAIL:
      return fetchContentsCategoriesFail(state, action);
    case contentCategoryTypes.ADD_CONTENT_CATEGORY_START:
      return addContentCategoryStart(state, action);
    case contentCategoryTypes.ADD_CONTENT_CATEGORY_SUCCESS:
      return addContentCategorySuccess(state, action);
    case contentCategoryTypes.ADD_CONTENT_CATEGORY_FAIL:
      return addContentCategoryFail(state, action);
    case contentCategoryTypes.DELETE_CONTENT_CATEGORY_START:
      return deleteContentCategoryStart(state, action);
    case contentCategoryTypes.DELETE_CONTENT_CATEGORY_SUCCESS:
      return deleteContentCategorySuccess(state, action);
    case contentCategoryTypes.DELETE_CONTENT_CATEGORY_FAIL:
      return deleteContentCategoryFail(state, action);
    case contentCategoryTypes.GET_CONTENT_CATEGORY_START:
      return getContentCategoryStart(state, action);
    case contentCategoryTypes.GET_CONTENT_CATEGORY_SUCCESS:
      return getContentCategorySuccess(state, action);
    case contentCategoryTypes.GET_CONTENT_CATEGORY_FAIL:
      return getContentCategoryFail(state, action);
    case contentCategoryTypes.UPDATE_CONTENT_CATEGORY_START:
      return updateContentCategoryStart(state, action);
    case contentCategoryTypes.UPDATE_CONTENT_CATEGORY_SUCCESS:
      return updateContentCategorySuccess(state, action);
    case contentCategoryTypes.UPDATE_CONTENT_CATEGORY_FAIL:
      return updateContentCategoryFail(state, action);
    case contentCategoryTypes.CONTENT_CATEGORY_DISMISS_ERROR:
      return contentCategoryDismissError(state);

    default:
      return state;
  }
};

export default contentCategoryReducer;
