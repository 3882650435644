import * as pageInformationTypes from './page-information.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  pageInformations: {},
  pageInformation: {},

  successDeteled: '',
  error: null,
  loading: false,

  filter: '',
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  }
};

/**
 * FETCH PAGE_INFORMATIONS
 */
const fetchPageInformationsStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchPageInformationsSuccess = (state, action) =>
  updateObject(state, {
    pageInformations: action.pageInformations,
    loading: false,
    error: null
  });
const fetchPageInformationsFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * CREATE PAGE_INFORMATION
 */
const addPageInformationStart = (state, action) =>
  updateObject(state, { loading: true });
const addPageInformationSuccess = (state, action) =>
  updateObject(state, {
    pageInformation: action.pageInformation,
    loading: false,
    error: null
  });
const addPageInformationFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * GET PAGE_INFORMATION
 */
const getPageInformationStart = (state, action) =>
  updateObject(state, { loading: true });
const getPageInformationSuccess = (state, action) =>
  updateObject(state, {
    pageInformation: action.pageInformation,
    loading: false,
    error: null
  });
const getPageInformationFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPDATE PAGE_INFORMATION
 */
const updatePageInformationStart = (state, action) =>
  updateObject(state, { loading: true });
const updatePageInformationSuccess = (state, action) =>
  updateObject(state, {
    pageInformation: action.pageInformation,
    loading: false,
    error: null
  });
const updatePageInformationFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * DELETE PAGE_INFORMATION
 */
const deletePageInformationStart = (state, action) =>
  updateObject(state, { loading: true });
const deletePageInformationSuccess = (state, action) =>
  updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
const deletePageInformationFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

// ====== UTILITY  ======= //

const pageInformationDismissError = state =>
  updateObject(state, {
    error: null
  });

const pageInformationAddFilter = (state, action) =>
  updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj
  });

const pageInformationClearFilter = state =>
  updateObject(state, {
    filter: '',
    filterObj: {}
  });

const pageInformationAddPagination = (state, action) =>
  updateObject(state, {
    pagination: action.pagination
  });
const pageInformationClearPagination = state =>
  updateObject(state, {
    pagination: {}
  });

const pageInformationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pageInformationTypes.FETCH_PAGE_INFORMATIONS_START:
      return fetchPageInformationsStart(state, action);
    case pageInformationTypes.FETCH_PAGE_INFORMATIONS_SUCCESS:
      return fetchPageInformationsSuccess(state, action);
    case pageInformationTypes.FETCH_PAGE_INFORMATIONS_FAIL:
      return fetchPageInformationsFail(state, action);

    case pageInformationTypes.ADD_PAGE_INFORMATION_START:
      return addPageInformationStart(state, action);
    case pageInformationTypes.ADD_PAGE_INFORMATION_SUCCESS:
      return addPageInformationSuccess(state, action);
    case pageInformationTypes.ADD_PAGE_INFORMATION_FAIL:
      return addPageInformationFail(state, action);

    case pageInformationTypes.GET_PAGE_INFORMATION_START:
      return getPageInformationStart(state, action);
    case pageInformationTypes.GET_PAGE_INFORMATION_SUCCESS:
      return getPageInformationSuccess(state, action);
    case pageInformationTypes.GET_PAGE_INFORMATION_FAIL:
      return getPageInformationFail(state, action);

    case pageInformationTypes.UPDATE_PAGE_INFORMATION_START:
      return updatePageInformationStart(state, action);
    case pageInformationTypes.UPDATE_PAGE_INFORMATION_SUCCESS:
      return updatePageInformationSuccess(state, action);
    case pageInformationTypes.UPDATE_PAGE_INFORMATION_FAIL:
      return updatePageInformationFail(state, action);

    case pageInformationTypes.DELETE_PAGE_INFORMATION_START:
      return deletePageInformationStart(state, action);
    case pageInformationTypes.DELETE_PAGE_INFORMATION_SUCCESS:
      return deletePageInformationSuccess(state, action);
    case pageInformationTypes.DELETE_PAGE_INFORMATION_FAIL:
      return deletePageInformationFail(state, action);

    case pageInformationTypes.PAGE_INFORMATION_DISMISS_ERROR:
      return pageInformationDismissError(state);
    case pageInformationTypes.PAGE_INFORMATION_ADD_FILTER:
      return pageInformationAddFilter(state, action);
    case pageInformationTypes.PAGE_INFORMATION_CLEAR_FILTER:
      return pageInformationClearFilter(state);
    case pageInformationTypes.PAGE_INFORMATION_ADD_PAGINATION:
      return pageInformationAddPagination(state, action);
    case pageInformationTypes.PAGE_INFORMATION_CLEAR_PAGINATION:
      return pageInformationClearPagination(state);
    default:
      return state;
  }
};

export default pageInformationReducer;
