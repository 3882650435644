import * as privacyPolicyTypes from "store/reducers/privacy-policy/privacy-policy.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchPrivacyPolicySaga,
  addPrivacyPolicySaga,
  deletePrivacyPolicySaga,
  getPrivacyPolicySaga,
  updatePrivacyPolicySaga
} from "./privacy-policy";

export const privacyPolicySagas = {
  watchFetchPrivacyPolicy: takeEvery(privacyPolicyTypes.FETCH_PRIVACY_POLICY, fetchPrivacyPolicySaga),
  watchAddPrivacyPolicy: takeEvery(privacyPolicyTypes.ADD_PRIVACY_POLICY, addPrivacyPolicySaga),
  watchDeletePrivacyPolicy: takeEvery(privacyPolicyTypes.DELETE_PRIVACY_POLICY, deletePrivacyPolicySaga),
  watchGetPrivacyPolicy: takeEvery(privacyPolicyTypes.GET_PRIVACY_POLICY, getPrivacyPolicySaga),
  watchUpdatePrivacyPolicy: takeEvery(privacyPolicyTypes.UPDATE_PRIVACY_POLICY, updatePrivacyPolicySaga)
}