export const GET_CONTENT_VIEWS = 'GET_CONTENT_VIEWS';
export const GET_CONTENT_VIEWS_START = 'GET_CONTENT_VIEWS_START';
export const GET_CONTENT_VIEWS_SUCCESS = 'GET_CONTENT_VIEWS_SUCCESS';
export const GET_CONTENT_VIEWS_FAIL = 'GET_CONTENT_VIEWS_FAIL';

export const FETCH_CONTENT_VIEWS = 'FETCH_CONTENT_VIEWS';
export const FETCH_CONTENT_VIEWS_START = 'FETCH_CONTENT_VIEWS_START';
export const FETCH_CONTENT_VIEWS_SUCCESS = 'FETCH_CONTENT_VIEWS_SUCCESS';
export const FETCH_CONTENT_VIEWS_FAIL = 'FETCH_CONTENT_VIEWS_FAIL';


export const CONTENT_VIEWS_DISMISS_ERROR = 'CONTENT_VIEWS_DISMISS_ERROR';