import * as greetingTypes from "store/reducers/greeting/greeting.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchGreetingSaga,
  addGreetingSaga,
  deleteGreetingSaga,
  getGreetingSaga,
  updateGreetingSaga
} from "./greeting";

export const greetingSagas = {
  watchFetchGreeting: takeEvery(greetingTypes.FETCH_GREETING, fetchGreetingSaga),
  watchAddGreeting: takeEvery(greetingTypes.ADD_GREETING, addGreetingSaga),
  watchDeleteGreeting: takeEvery(greetingTypes.DELETE_GREETING, deleteGreetingSaga),
  watchGetGreeting: takeEvery(greetingTypes.GET_GREETING, getGreetingSaga),
  watchUpdateGreeting: takeEvery(greetingTypes.UPDATE_GREETING, updateGreetingSaga)
}