import * as adminAuthManagementTypes from "./adminAuthManagement.types";

/**
 * RESET PASSWORD
 */
export const resetPassword = (params, history) => ({
  type: adminAuthManagementTypes.RESET_PASSWORD,
  params,
  history
});
export const resetPasswordStart = () => ({
  type: adminAuthManagementTypes.RESET_PASSWORD_START
});

export const resetPasswordSuccess = (users) => ({
  type: adminAuthManagementTypes.RESET_PASSWORD_SUCCESS
});
export const resetPasswordFail = (error) => ({
  type: adminAuthManagementTypes.RESET_PASSWORD_FAIL,
  error
});

export const dismissError = () => ({
  type: adminAuthManagementTypes.DISMISS_ERROR
});
