import { put } from "redux-saga/effects";
import * as actions from "store/reducers/privacy-policy/privacy-policy.actions";
import privacyPolicyService from "services/PrivacyPolicy";
export function* fetchPrivacyPolicySaga(action) {
    yield put(actions.fetchPrivacyPolicyStart());
    const service = yield new privacyPolicyService();
    const privacyPolicy = yield service.find(action.params);
    if(!privacyPolicy.error){
      yield put(actions.fetchPrivacyPolicySuccess(privacyPolicy));
    } else {
      yield put(actions.fetchPrivacyPolicyFail(privacyPolicy.error));  
    }
}

export function* addPrivacyPolicySaga(action) {
  yield put(actions.addPrivacyPolicyStart());
  const service = yield new privacyPolicyService();
  const privacyPolicy = yield service.create(action.data);
  if(!privacyPolicy.error){
    yield action.history.replace('/admin/privacy-policy');
  } else {
    yield put(actions.addPrivacyPolicyFail(privacyPolicy.error));
  }
}

export function* deletePrivacyPolicySaga(action) {
  yield put(actions.deletePrivacyPolicyStart());
  const service = yield new privacyPolicyService();
  const privacyPolicy = yield service.delete(action.id);
  if(!privacyPolicy.error){
    yield put(actions.deletePrivacyPolicySuccess());
    if(action.history){
      action.history.replace('/admin/privacy-policy')
    }
  } else {
    yield put(actions.deletePrivacyPolicyFail(privacyPolicy.error));
  }
}

export function* getPrivacyPolicySaga(action) {
  yield put(actions.getPrivacyPolicyStart());
  const service = yield new privacyPolicyService();
  const privacyPolicy = yield service.get(action.id);
  if(!privacyPolicy.error){
    yield put(actions.getPrivacyPolicySuccess(privacyPolicy));
  } else {
    yield put(actions.getPrivacyPolicyFail(privacyPolicy.error));
  }
}

export function* updatePrivacyPolicySaga(action) {
  yield put(actions.updatePrivacyPolicyStart());
  const service = yield new privacyPolicyService();
  const privacyPolicy = yield service.patch(action.data);
  if(!privacyPolicy.error){
    yield action.history.replace('/admin/privacy-policy');
  } else {
    yield put(actions.updatePrivacyPolicyFail(privacyPolicy.error));
  }
}