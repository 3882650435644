import { takeEvery } from 'redux-saga/effects';
import * as AdminLogTypes from 'store/reducers/adminLog/AdminLog.types';
import { fetchAdminLogSaga, addAdminLogSaga } from './AdminLogSaga';

export const adminLogSagas = {
  watchFetchAdminLog: takeEvery(
    AdminLogTypes.FETCH_ADMIN_LOG,
    fetchAdminLogSaga
  ),
  watchAddAdminLog: takeEvery(AdminLogTypes.ADD_ADMIN_LOG, addAdminLogSaga)
};
