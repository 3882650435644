import * as articleTypes from "./article.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  articles: {},
  article: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  },
};

/**
 * FETCH ARTICLES
 */
const fetchArticlesStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchArticlesSuccess = (state, action) =>
  updateObject(state, {
    articles: action.articles,
    article: {},
    loading: false,
    error: null
  });
const fetchArticlesFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * CREATE ARTICLE
 */
const addArticleStart = (state, action) =>
  updateObject(state, { loading: true });
const addArticleSuccess = (state, action) =>
  updateObject(state, {
    article: action.article,
    loading: false,
    error: null
  });
const addArticleFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * GET ARTICLE
 */
const getArticleStart = (state, action) =>
  updateObject(state, { loading: true });
const getArticleSuccess = (state, action) =>
  updateObject(state, {
    article: action.article,
    loading: false,
    error: null
  });
const getArticleFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPDATE ARTICLE
 */
const updateArticleStart = (state, action) =>
  updateObject(state, { loading: true });
const updateArticleSuccess = (state, action) =>
  updateObject(state, {
    article: action.article,
    loading: false,
    error: null
  });
const updateArticleFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * DELETE ARTICLE
 */
const deleteArticleStart = (state, action) =>
  updateObject(state, { loading: true });
const deleteArticleSuccess = (state, action) =>
  updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
const deleteArticleFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

// ====== UTILITY  ======= //

const articleDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const articleAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj
  });
};

const articleClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {}
  });
};

const articleAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination
  });
};

const articleClearPagination = (state) => {
  return updateObject(state, {
    pagination: {}
  });
};

const articleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case articleTypes.FETCH_ARTICLES_START:
      return fetchArticlesStart(state, action);
    case articleTypes.FETCH_ARTICLES_SUCCESS:
      return fetchArticlesSuccess(state, action);
    case articleTypes.FETCH_ARTICLES_FAIL:
      return fetchArticlesFail(state, action);

    case articleTypes.ADD_ARTICLE_START:
      return addArticleStart(state, action);
    case articleTypes.ADD_ARTICLE_SUCCESS:
      return addArticleSuccess(state, action);
    case articleTypes.ADD_ARTICLE_FAIL:
      return addArticleFail(state, action);

    case articleTypes.GET_ARTICLE_START:
      return getArticleStart(state, action);
    case articleTypes.GET_ARTICLE_SUCCESS:
      return getArticleSuccess(state, action);
    case articleTypes.GET_ARTICLE_FAIL:
      return getArticleFail(state, action);

    case articleTypes.UPDATE_ARTICLE_START:
      return updateArticleStart(state, action);
    case articleTypes.UPDATE_ARTICLE_SUCCESS:
      return updateArticleSuccess(state, action);
    case articleTypes.UPDATE_ARTICLE_FAIL:
      return updateArticleFail(state, action);

    case articleTypes.DELETE_ARTICLE_START:
      return deleteArticleStart(state, action);
    case articleTypes.DELETE_ARTICLE_SUCCESS:
      return deleteArticleSuccess(state, action);
    case articleTypes.DELETE_ARTICLE_FAIL:
      return deleteArticleFail(state, action);

    case articleTypes.ARTICLE_DISMISS_ERROR:
      return articleDismissError(state);
    case articleTypes.ARTICLE_ADD_FILTER:
      return articleAddFilter(state, action);
    case articleTypes.ARTICLE_CLEAR_FILTER:
      return articleClearFilter(state);
    case articleTypes.ARTICLE_ADD_PAGINATION:
      return articleAddPagination(state, action);
    case articleTypes.ARTICLE_CLEAR_PAGINATION:
      return articleClearPagination(state);
    default:
      return state;
  }
};

export default articleReducer;
