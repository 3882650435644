/**
 * BLOOD PRESSURE
 */
export const FETCH_BLOOD_PRESSURE = "FETCH_BLOOD_PRESSURE";
export const FETCH_BLOOD_PRESSURE_START = "FETCH_BLOOD_PRESSURE_START";
export const FETCH_BLOOD_PRESSURE_SUCCESS = "FETCH_BLOOD_PRESSURE_SUCCESS";
export const FETCH_BLOOD_PRESSURE_FAIL = "FETCH_BLOOD_PRESSURE_FAIL";

/**
 * CHOLESTEROL
 */
export const FETCH_CHOLESTEROL = "FETCH_CHOLESTEROL";
export const FETCH_CHOLESTEROL_START = "FETCH_CHOLESTEROL_START";
export const FETCH_CHOLESTEROL_SUCCESS = "FETCH_CHOLESTEROL_SUCCESS";
export const FETCH_CHOLESTEROL_FAIL = "FETCH_CHOLESTEROL_FAIL";

/**
 * KIDNEY
 */
export const FETCH_KIDNEY = "FETCH_KIDNEY";
export const FETCH_KIDNEY_START = "FETCH_KIDNEY_START";
export const FETCH_KIDNEY_SUCCESS = "FETCH_KIDNEY_SUCCESS";
export const FETCH_KIDNEY_FAIL = "FETCH_CHOLESTEROL_FAIL";
