import * as userTypes from "./user.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  users: {},
  url: "",
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1,
  },
};

/**
 * FETCH USERS
 */
const fetchUsersStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchUsersSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users,
    user: {},
    loading: false,
    error: null,
  });
};
const fetchUsersFail = (state, action) => {
  return updateObject(state, { loading: false });
};

/**
 * FETCH TOTAL USERS
 */
const fetchTotalUsersStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchTotalUsersSuccess = (state, action) => {
  return updateObject(state, {
    totalUsers: action.userTotal,
    user: {},
    loading: false,
    error: null,
  });
};
const fetchTotalUsersFail = (state, action) => {
  return updateObject(state, { loading: false });
};

/**
 * FETCH ACTIVE USERS
 */
const fetchActiveUsersStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchActiveUsersSuccess = (state, action) => {
  return updateObject(state, {
    activeUsers: action.users,
    user: {},
    loading: false,
    error: null,
  });
};
const fetchActiveUsersFail = (state, action) => {
  return updateObject(state, { loading: false });
};

/**
 * GET USER
 */
const getUserStart = (state, action) => updateObject(state, { loading: true });
const getUserSuccess = (state, action) =>
  updateObject(state, { loading: false, user: action.user, error: null });
const getUserFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * ADD USER
 */
const addUserStart = (state, action) => updateObject(state, { loading: true });
const addUserSuccess = (state, action) =>
  updateObject(state, { user: action.user, loading: false, error: null });
const addUserFail = (state, action) =>
  updateObject(state, { error: action.error, loading: false });

/**
 * DELETE USER
 */
const deleteUserStart = (state, action) =>
  updateObject(state, { loading: true });
const deleteUserSuccess = (state, action) =>
  updateObject(state, { successDeleted: new Date().getTime(), error: null });
const deleteUserFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPLOAD USER IMAGE
 */
const uploadStart = (state, action) => updateObject(state, { loading: true });
const uploadSuccess = (state, action) =>
  updateObject(state, { url: action.url, loading: false, error: null });
const uploadFail = (state, action) =>
  updateObject(state, { error: action.error, loading: false });

// ====== UTILITY  ======= //

const userDismissError = (state) => {
  return updateObject(state, {
    error: null,
  });
};

const userAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj,
  });
};

const userClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {},
  });
};

const userAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination,
  });
};

const userClearPagination = (state) => {
  return updateObject(state, {
    pagination: {},
  });
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userTypes.FETCH_USERS_START:
      return fetchUsersStart(state, action);
    case userTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case userTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action);

    case userTypes.FETCH_TOTAL_USERS_START:
      return fetchTotalUsersStart(state, action);
    case userTypes.FETCH_TOTAL_USERS_SUCCESS:
      return fetchTotalUsersSuccess(state, action);
    case userTypes.FETCH_TOTAL_USERS_FAIL:
      return fetchTotalUsersFail(state, action);

    case userTypes.FETCH_ACTIVE_USERS_START:
      return fetchActiveUsersStart(state, action);
    case userTypes.FETCH_ACTIVE_USERS_SUCCESS:
      return fetchActiveUsersSuccess(state, action);
    case userTypes.FETCH_ACTIVE_USERS_FAIL:
      return fetchActiveUsersFail(state, action);

    case userTypes.GET_USER_START:
      return getUserStart(state, action);
    case userTypes.GET_USER_SUCCESS:
      return getUserSuccess(state, action);
    case userTypes.GET_USER_FAIL:
      return getUserFail(state, action);

    case userTypes.ADD_USER_START:
      return addUserStart(state, action);
    case userTypes.ADD_USER_SUCCESS:
      return addUserSuccess(state, action);
    case userTypes.ADD_USER_FAIL:
      return addUserFail(state, action);

    case userTypes.DELETE_USER_START:
      return deleteUserStart(state, action);
    case userTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action);
    case userTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);

    case userTypes.UPLOAD_USER_PICTURE_START:
      return uploadStart(state, action);
    case userTypes.UPLOAD_USER_PICTURE_SUCCESS:
      return uploadSuccess(state, action);
    case userTypes.UPLOAD_USER_PICTURE_FAIL:
      return uploadFail(state, action);

    case userTypes.USER_DISMISS_ERROR:
      return userDismissError(state);
    case userTypes.USER_ADD_FILTER:
      return userAddFilter(state, action);
    case userTypes.USER_CLEAR_FILTER:
      return userClearFilter(state);
    case userTypes.USER_ADD_PAGINATION:
      return userAddPagination(state, action);
    case userTypes.USER_CLEAR_PAGINATION:
      return userClearPagination(state);

    default:
      return state;
  }
};

export default userReducer;
