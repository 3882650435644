import { takeEvery } from "redux-saga/effects";
import * as physicalActivityTypes from "store/reducers/physical-activity/physical-activity.types";
import {
  fetchPhysicalActivitiesSaga,
  addPhysicalActivitySaga,
  deletePhysicalActivitySaga,
  getPhysicalActivitySaga,
  updatePhysicalActivitySaga
} from "./physical-activity";


export const physicalActivitySagas = {
  watchFetchPhysicalActivities: takeEvery(
    physicalActivityTypes.FETCH_PHYSICAL_ACTIVITIES,
    fetchPhysicalActivitiesSaga
  ),
  watchAddPhysicalActivity: takeEvery(
    physicalActivityTypes.ADD_PHYSICAL_ACTIVITY,
    addPhysicalActivitySaga
  ),
  watchDeletePhysicalActivity: takeEvery(
    physicalActivityTypes.DELETE_PHYSICAL_ACTIVITY,
    deletePhysicalActivitySaga
  ),
  watchGetPhysicalActivity: takeEvery(
    physicalActivityTypes.GET_PHYSICAL_ACTIVITY,
    getPhysicalActivitySaga
  ),
  watchUpdatePhysicalActivity: takeEvery(
    physicalActivityTypes.UPDATE_PHYSICAL_ACTIVITY,
    updatePhysicalActivitySaga
  )
}