import * as versioningTypes from './versioning.types';

/**
 * FETCH VERSIONING
 */
export const fetchVersioning = params => ({
  type: versioningTypes.FETCH_VERSIONING,
  params
});
export const fetchVersioningStart = () => ({
  type: versioningTypes.FETCH_VERSIONING_START
});
export const fetchVersioningSuccess = versioning => ({
  type: versioningTypes.FETCH_VERSIONING_SUCCESS,
  versioning
});
export const fetchVersioningFail = error => ({
  type: versioningTypes.FETCH_VERSIONING_FAIL,
  error
});

/**
 * GET VERSIONING
 */
export const getVersioning = id => ({
  type: versioningTypes.GET_VERSIONING,
  id
});
export const getVersioningStart = () => ({
  type: versioningTypes.GET_VERSIONING_START
});
export const getVersioningSuccess = versioning => ({
  type: versioningTypes.GET_VERSIONING_SUCCESS,
  versioning
});
export const getVersioningFail = error => ({
  type: versioningTypes.GET_VERSIONING_FAIL,
  error
});

/**
 * CREATE VERSIONING
 */
export const addVersioning = (history, data) => ({
  type: versioningTypes.ADD_VERSIONING,
  history,
  data
});
export const addVersioningStart = () => ({
  type: versioningTypes.ADD_VERSIONING_START
});
export const addVersioningSuccess = versioning => ({
  type: versioningTypes.ADD_VERSIONING_SUCCESS,
  versioning
});
export const addVersioningFail = error => ({
  type: versioningTypes.ADD_VERSIONING_FAIL,
  error
});

/**
 * UPDATE VERSIONING
 */
export const updateVersioning = (history, data) => ({
  type: versioningTypes.UPDATE_VERSIONING,
  history,
  data
});
export const updateVersioningStart = () => ({
  type: versioningTypes.UPDATE_VERSIONING_START
});
export const updateVersioninguccess = versioning => ({
  type: versioningTypes.UPDATE_VERSIONING_SUCCESS,
  versioning
});
export const updateVersioningFail = error => ({
  type: versioningTypes.UPDATE_VERSIONING_FAIL,
  error
});

/**
 * DELETE VERSIONING
 */
export const deleteVersioning = (id, history) => ({
  type: versioningTypes.DELETE_VERSIONING,
  id,
  history
});
export const deleteVersioningStart = () => ({
  type: versioningTypes.DELETE_VERSIONING_START
});
export const deleteVersioningSuccess = () => ({
  type: versioningTypes.DELETE_VERSIONING_SUCCESS
});

export const deleteVersioningFail = error => ({
  type: versioningTypes.DELETE_VERSIONING_FAIL,
  error
});
