import * as versioningTypes from './versioning.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  versioning: {},

  error: null,
  loading: false,

  filter: '',
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  }
};

/**
 * FETCH VERSIONING
 */
const fetchVersioningStart = state => updateObject(state, { loading: true });
const fetchVersioningSuccess = (state, action) =>
  updateObject(state, {
    versioning: action.versioning,
    loading: false,
    error: null
  });
const fetchVersioningFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * CREATE VERSIONING
 */
const addVersioningtart = state => updateObject(state, { loading: true });
const addVersioninguccess = (state, action) =>
  updateObject(state, {
    versioning: action.versioning,
    loading: false,
    error: null
  });
const addVersioningFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * GET VERSIONING
 */
const getVersioningtart = state => updateObject(state, { loading: true });
const getVersioninguccess = (state, action) =>
  updateObject(state, {
    versioning: action.versioning,
    loading: false,
    error: null
  });
const getVersioningFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPDATE VERSIONING
 */
const updateVersioningtart = state => updateObject(state, { loading: true });
const updateVersioninguccess = (state, action) =>
  updateObject(state, {
    versioning: action.versioning,
    loading: false,
    error: null
  });
const updateVersioningFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * DELETE VERSIONING
 */
const deleteVersioningtart = state => updateObject(state, { loading: true });
const deleteVersioninguccess = state =>
  updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
const deleteVersioningFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

// ====== UTILITY  ======= //
const versioningDismissError = state =>
  updateObject(state, {
    error: null
  });

const versioningReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case versioningTypes.FETCH_VERSIONING_START:
      return fetchVersioningStart(state, action);
    case versioningTypes.FETCH_VERSIONING_SUCCESS:
      return fetchVersioningSuccess(state, action);
    case versioningTypes.FETCH_VERSIONING_FAIL:
      return fetchVersioningFail(state, action);

    case versioningTypes.ADD_VERSIONING_START:
      return addVersioningtart(state, action);
    case versioningTypes.ADD_VERSIONING_SUCCESS:
      return addVersioninguccess(state, action);
    case versioningTypes.ADD_VERSIONING_FAIL:
      return addVersioningFail(state, action);

    case versioningTypes.GET_VERSIONING_START:
      return getVersioningtart(state, action);
    case versioningTypes.GET_VERSIONING_SUCCESS:
      return getVersioninguccess(state, action);
    case versioningTypes.GET_VERSIONING_FAIL:
      return getVersioningFail(state, action);

    case versioningTypes.UPDATE_VERSIONING_START:
      return updateVersioningtart(state, action);
    case versioningTypes.UPDATE_VERSIONING_SUCCESS:
      return updateVersioninguccess(state, action);
    case versioningTypes.UPDATE_VERSIONING_FAIL:
      return updateVersioningFail(state, action);

    case versioningTypes.DELETE_VERSIONING_START:
      return deleteVersioningtart(state, action);
    case versioningTypes.DELETE_VERSIONING_SUCCESS:
      return deleteVersioninguccess(state, action);
    case versioningTypes.DELETE_VERSIONING_FAIL:
      return deleteVersioningFail(state, action);

    case versioningTypes.VERSIONING_DISMISS_ERROR:
      return versioningDismissError(state);
    default:
      return state;
  }
};

export default versioningReducer;
