export const ADD_FAQ = 'ADD_FAQ';
export const ADD_FAQ_START = 'ADD_FAQ_START';
export const ADD_FAQ_SUCCESS = 'ADD_FAQ_SUCCESS';
export const ADD_FAQ_FAIL = 'ADD_FAQ_FAIL';

export const DELETE_FAQ = 'DELETE_FAQ';
export const DELETE_FAQ_START = 'DELETE_FAQ_START';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAIL = 'DELETE_FAQ_FAIL';


export const UPDATE_FAQ = 'UPDATE_FAQ';
export const UPDATE_FAQ_START = 'UPDATE_FAQ_START';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAIL = 'UPDATE_FAQ_FAIL';

export const GET_FAQ = 'GET_FAQ';
export const GET_FAQ_START = 'GET_FAQ_START';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_FAIL = 'GET_FAQ_FAIL';

export const FETCH_FAQ = 'FETCH_FAQ';
export const FETCH_FAQ_START = 'FETCH_FAQ_START';
export const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS';
export const FETCH_FAQ_FAIL = 'FETCH_FAQ_FAIL';


export const FAQ_DISMISS_ERROR = 'FAQ_DISMISS_ERROR';