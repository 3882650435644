import * as foodCategoryTypes from "store/reducers/food-category/food-category.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchFoodCategorySaga,
  addFoodCategorySaga,
  deleteFoodCategorySaga,
  getFoodCategorySaga,
  updateFoodCategorySaga
} from "./food-category";

export const foodCategorySagas = {
  watchFetchFoodCategory: takeEvery(foodCategoryTypes.FETCH_FOOD_CATEGORY, fetchFoodCategorySaga),
  watchAddFoodCategory: takeEvery(foodCategoryTypes.ADD_FOOD_CATEGORY, addFoodCategorySaga),
  watchDeleteFoodCategory: takeEvery(foodCategoryTypes.DELETE_FOOD_CATEGORY, deleteFoodCategorySaga),
  watchGetFoodCategory: takeEvery(foodCategoryTypes.GET_FOOD_CATEGORY, getFoodCategorySaga),
  watchUpdateFoodCategory: takeEvery(foodCategoryTypes.UPDATE_FOOD_CATEGORY, updateFoodCategorySaga)
}