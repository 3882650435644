import * as userTypes from "./user.types";

/**
 * FETCH USER
 */
export const fetchUsers = (params) => ({
  type: userTypes.FETCH_USERS,
  params
});
export const fetchUsersSuccess = (users) => ({
  type: userTypes.FETCH_USERS_SUCCESS,
  users
});
export const fetchUsersFail = (error) => ({
  type: userTypes.FETCH_USERS_FAIL,
  error
});
export const fetchUsersStart = () => ({
  type: userTypes.FETCH_USERS_START
});

/**
 * FETCH TOTAL USER
 */
export const fetchTotalUsers = (params) => ({
  type: userTypes.FETCH_TOTAL_USERS,
  params
});
export const fetchTotalUsersSuccess = (userTotal) => ({
  type: userTypes.FETCH_TOTAL_USERS_SUCCESS,
  userTotal
});
export const fetchTotalUsersFail = (error) => ({
  type: userTypes.FETCH_TOTAL_USERS_FAIL,
  error
});
export const fetchTotalUsersStart = () => ({
  type: userTypes.FETCH_TOTAL_USERS_START
});

/**
 * FETCH ACTIVE USER
 */
export const fetchActiveUsers = (params) => ({
  type: userTypes.FETCH_ACTIVE_USERS,
  params
});
export const fetchActiveUsersSuccess = (users) => ({
  type: userTypes.FETCH_ACTIVE_USERS_SUCCESS,
  users
});
export const fetchActiveUsersFail = (error) => ({
  type: userTypes.FETCH_ACTIVE_USERS_FAIL,
  error
});
export const fetchActiveUsersStart = () => ({
  type: userTypes.FETCH_ACTIVE_USERS_START
});

/**
 * GET USER
 */
export const getUser = (id) => ({
  type: userTypes.GET_USER,
  id
});
export const getUserStart = () => ({
  type: userTypes.GET_USER_START
});
export const getUserSuccess = (user) => ({
  type: userTypes.GET_USER_SUCCESS,
  user
});
export const getUserFail = (error) => ({
  type: userTypes.GET_USER_FAIL,
  error
});

/**
 * ADD USER
 */
export const addUser = (history, imgUpload, userData) => ({
  type: userTypes.ADD_USER,
  history,
  imgUpload,
  userData
});
export const addUserStart = () => ({
  type: userTypes.ADD_USER_START
});
export const addUserSuccess = (user) => ({
  type: userTypes.ADD_USER_SUCCESS,
  user
});
export const addUserFail = (error) => ({
  type: userTypes.ADD_USER_FAIL,
  error
});

/**
 * UPDATE USER
 */
export const updateUser = (history, imgUpload, userData) => ({
  type: userTypes.UPDATE_USER,
  history,
  imgUpload,
  userData
});
export const updateUserStart = () => ({
  type: userTypes.UPDATE_USER_START
});
export const updateUserSuccess = (user) => ({
  type: userTypes.UPDATE_USER_SUCCESS,
  user
});
export const updateUserFail = (error) => ({
  type: userTypes.UPDATE_USER_FAIL,
  error
});

/**
 * DELETE USER
 */
export const deleteUser = (id, history) => ({
  type: userTypes.DELETE_USER,
  id,
  history
});
export const deleteUserStart = () => ({
  type: userTypes.DELETE_USER_START
});
export const deleteUserSuccess = () => ({
  type: userTypes.DELETE_USER_SUCCESS
});
export const deleteUserFail = () => ({
  type: userTypes.DELETE_USER_FAIL
});

/**
 * UPLOAD USER PICTURE
 */
export const upload = () => ({
  type: userTypes.UPLOAD_USER_PICTURE
});
export const uploadStart = () => ({
  type: userTypes.UPLOAD_USER_PICTURE_START
});
export const uploadSuccess = (url) => ({
  type: userTypes.UPLOAD_USER_PICTURE_SUCCESS,
  url
});
export const uploadFail = () => ({
  type: userTypes.UPLOAD_USER_PICTURE_FAIL
});

// ====== UTILITY  ======= //
export const userDismissError = () => {
  return {
      type: userTypes.USER_DISMISS_ERROR
  };
};

export const userAddFilter = (filter, filterObj) => {
  return {
      type: userTypes.USER_ADD_FILTER,
      filter: filter,
      filterObj: filterObj
  };
};

export const userClearFilter = () => {
  return {
      type: userTypes.USER_CLEAR_FILTER
  };
};

export const userAddPagination = (pagination) => {
  return {
      type: userTypes.USER_ADD_PAGINATION,
      pagination: pagination
  };
};

export const userClearPagination = () => {
  return {
      type: userTypes.USER_CLEAR_PAGINATION
  };
};
