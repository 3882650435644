import Service from "./Service";

export default class TopMedicine extends Service {
  constructor() {
    super("top-medicine", true);
  }

  async find(params) {
    const response = await super.find(params);
    return super.generateDefaultResponse(response);
  }

  async get(id) {
    const response = await super.get(id);
    return super.generateDefaultResponse(response);
  }

  async create(data) {
    const response = await super.create(data);
    return super.generateDefaultResponse(response);
  }

  async patch(data) {
    delete data.isVerified;
    const response = await super.patch(data);
    return super.generateDefaultResponse(response);
  }
}
