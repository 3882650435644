export const ADD_CONTENT_CATEGORY = 'ADD_CONTENT_CATEGORY';
export const ADD_CONTENT_CATEGORY_START = 'ADD_CONTENT_CATEGORY_START';
export const ADD_CONTENT_CATEGORY_SUCCESS = 'ADD_CONTENT_CATEGORY_SUCCESS';
export const ADD_CONTENT_CATEGORY_FAIL = 'ADD_CONTENT_CATEGORY_FAIL';

export const DELETE_CONTENT_CATEGORY = 'DELETE_CONTENT_CATEGORY';
export const DELETE_CONTENT_CATEGORY_START = 'DELETE_CONTENT_CATEGORY_START';
export const DELETE_CONTENT_CATEGORY_SUCCESS = 'DELETE_CONTENT_CATEGORY_SUCCESS';
export const DELETE_CONTENT_CATEGORY_FAIL = 'DELETE_CONTENT_CATEGORY_FAIL';


export const UPDATE_CONTENT_CATEGORY = 'UPDATE_CONTENT_CATEGORY';
export const UPDATE_CONTENT_CATEGORY_START = 'UPDATE_CONTENT_CATEGORY_START';
export const UPDATE_CONTENT_CATEGORY_SUCCESS = 'UPDATE_CONTENT_CATEGORY_SUCCESS';
export const UPDATE_CONTENT_CATEGORY_FAIL = 'UPDATE_CONTENT_CATEGORY_FAIL';

export const GET_CONTENT_CATEGORY = 'GET_CONTENT_CATEGORY';
export const GET_CONTENT_CATEGORY_START = 'GET_CONTENT_CATEGORY_START';
export const GET_CONTENT_CATEGORY_SUCCESS = 'GET_CONTENT_CATEGORY_SUCCESS';
export const GET_CONTENT_CATEGORY_FAIL = 'GET_CONTENT_CATEGORY_FAIL';

export const FETCH_CONTENTS_CATEGORIES = 'FETCH_CONTENTS_CATEGORIES';
export const FETCH_CONTENTS_CATEGORIES_START = 'FETCH_CONTENTS_CATEGORIES_START';
export const FETCH_CONTENTS_CATEGORIES_SUCCESS = 'FETCH_CONTENTS_CATEGORIES_SUCCESS';
export const FETCH_CONTENTS_CATEGORIES_FAIL = 'FETCH_CONTENTS_CATEGORIES_FAIL';


export const CONTENT_CATEGORY_DISMISS_ERROR = 'CONTENT_CATEGORY_DISMISS_ERROR';