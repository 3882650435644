import * as AdminLogTypes from './AdminLog.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  logs: [],
  log: {},

  error: null,
  loading: false,
  filter: '',
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  }
};

/**
 * FETCH ADMIN_LOG
 */
const fetchAdminLogStart = state => {
  return updateObject(state, { loading: true });
};
const fetchAdminLogSuccess = (state, action) => {
  return updateObject(state, {
    logs: action.logs,
    loading: false
  });
};
const fetchAdminLogFail = state => {
  return updateObject(state, { loading: false });
};

/**
 * FETCH ADMIN_LOG
 */
const addAdminLogStart = state => {
  return updateObject(state, { loading: true });
};
const addAdminLogSuccess = (state, action) => {
  return updateObject(state, {
    log: action.log,
    loading: false,
    error: null
  });
};
const addAdminLogFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const AdminLogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AdminLogTypes.FETCH_ADMIN_LOG_START:
      return fetchAdminLogStart(state, action);
    case AdminLogTypes.FETCH_ADMIN_LOG_SUCCESS:
      return fetchAdminLogSuccess(state, action);
    case AdminLogTypes.FETCH_ADMIN_LOG_FAIL:
      return fetchAdminLogFail(state, action);

    case AdminLogTypes.ADD_ADMIN_LOG_START:
      return addAdminLogStart(state, action);
    case AdminLogTypes.ADD_ADMIN_LOG_SUCCESS:
      return addAdminLogSuccess(state, action);
    case AdminLogTypes.ADD_ADMIN_LOG_FAIL:
      return addAdminLogFail(state, action);

    default:
      return state;
  }
};

export default AdminLogReducer;
