import * as HBA1CTypes from "./HBA1C.types";

/**
 * FETCH HBA1C
 */
export const fetchHBA1CSuccess = (HBA1C) => ({
  type: HBA1CTypes.FETCH_HBA1C_SUCCESS,
  HBA1C
});
export const fetchHBA1CFail = (error) => ({
  type: HBA1CTypes.FETCH_HBA1C_FAIL,
  error
});
export const fetchHBA1CStart = () => ({
  type: HBA1CTypes.FETCH_HBA1C_START
});
export const fetchHBA1C = (params) => ({
  type: HBA1CTypes.FETCH_HBA1C,
  params
});
