import * as contentTypes from "./content.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  contents: {},
  content: {},
  successDeteled: "",
  error: null,
  loading: false
};

const fetchContentsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchContentsSuccess = (state, action) => {
  return updateObject(state, {
    contents: action.contents,
    content: {},
    loading: false,
    error: null
  });
};

const fetchContentsFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addContentStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addContentSuccess = (state, action) => {
  return updateObject(state, {
    content: action.content,
    loading: false,
    error: null
  });
};

const addContentFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteContentStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteContentSuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deleteContentFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getContentStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getContentSuccess = (state, action) => {
  return updateObject(state, {
    content: action.content,
    loading: false,
    error: null
  });
};

const getContentFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateContentStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateContentSuccess = (state, action) => {
  return updateObject(state, {
    content: action.content,
    loading: false,
    error: null
  });
};

const updateContentFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const contentDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const contentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case contentTypes.FETCH_CONTENTS_START:
      return fetchContentsStart(state, action);
    case contentTypes.FETCH_CONTENTS_SUCCESS:
      return fetchContentsSuccess(state, action);
    case contentTypes.FETCH_CONTENTS_FAIL:
      return fetchContentsFail(state, action);
    case contentTypes.ADD_CONTENT_START:
      return addContentStart(state, action);
    case contentTypes.ADD_CONTENT_SUCCESS:
      return addContentSuccess(state, action);
    case contentTypes.ADD_CONTENT_FAIL:
      return addContentFail(state, action);
    case contentTypes.DELETE_CONTENT_START:
      return deleteContentStart(state, action);
    case contentTypes.DELETE_CONTENT_SUCCESS:
      return deleteContentSuccess(state, action);
    case contentTypes.DELETE_CONTENT_FAIL:
      return deleteContentFail(state, action);
    case contentTypes.GET_CONTENT_START:
      return getContentStart(state, action);
    case contentTypes.GET_CONTENT_SUCCESS:
      return getContentSuccess(state, action);
    case contentTypes.GET_CONTENT_FAIL:
      return getContentFail(state, action);
    case contentTypes.UPDATE_CONTENT_START:
      return updateContentStart(state, action);
    case contentTypes.UPDATE_CONTENT_SUCCESS:
      return updateContentSuccess(state, action);
    case contentTypes.UPDATE_CONTENT_FAIL:
      return updateContentFail(state, action);
    case contentTypes.CONTENT_DISMISS_ERROR:
      return contentDismissError(state);

    default:
      return state;
  }
};

export default contentReducer;
