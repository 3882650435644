export const ADD_GREETING = 'ADD_GREETING';
export const ADD_GREETING_START = 'ADD_GREETING_START';
export const ADD_GREETING_SUCCESS = 'ADD_GREETING_SUCCESS';
export const ADD_GREETING_FAIL = 'ADD_GREETING_FAIL';

export const DELETE_GREETING = 'DELETE_GREETING';
export const DELETE_GREETING_START = 'DELETE_GREETING_START';
export const DELETE_GREETING_SUCCESS = 'DELETE_GREETING_SUCCESS';
export const DELETE_GREETING_FAIL = 'DELETE_GREETING_FAIL';


export const UPDATE_GREETING = 'UPDATE_GREETING';
export const UPDATE_GREETING_START = 'UPDATE_GREETING_START';
export const UPDATE_GREETING_SUCCESS = 'UPDATE_GREETING_SUCCESS';
export const UPDATE_GREETING_FAIL = 'UPDATE_GREETING_FAIL';

export const GET_GREETING = 'GET_GREETING';
export const GET_GREETING_START = 'GET_GREETING_START';
export const GET_GREETING_SUCCESS = 'GET_GREETING_SUCCESS';
export const GET_GREETING_FAIL = 'GET_GREETING_FAIL';

export const FETCH_GREETING = 'FETCH_GREETING';
export const FETCH_GREETING_START = 'FETCH_GREETING_START';
export const FETCH_GREETING_SUCCESS = 'FETCH_GREETING_SUCCESS';
export const FETCH_GREETING_FAIL = 'FETCH_GREETING_FAIL';

// ====== UTILITY  ======= //
export const GREETING_DISMISS_ERROR = 'GREETING_DISMISS_ERROR';
export const GREETING_ADD_FILTER = 'GREETING_ADD_FILTER';
export const GREETING_CLEAR_FILTER = 'GREETING_CLEAR_FILTER';
export const GREETING_ADD_PAGINATION = 'GREETING_ADD_PAGINATION';
export const GREETING_CLEAR_PAGINATION = 'GREETING_CLEAR_PAGINATION';