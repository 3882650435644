import * as contentCategoryTypes from './content-category.types';

export const addContentCategory = (data, history) => {
    return {
        type: contentCategoryTypes.ADD_CONTENT_CATEGORY,
        data: data,
        history: history
    };
};

export const addContentCategorySuccess = contentCategory => {
    return {
        type: contentCategoryTypes.ADD_CONTENT_CATEGORY_SUCCESS,
        contentCategory: contentCategory
    };
};

export const addContentCategoryFail = error => {
    return {
        type: contentCategoryTypes.ADD_CONTENT_CATEGORY_FAIL,
        error: error
    };
};

export const addContentCategoryStart = () => {
    return {
        type: contentCategoryTypes.ADD_CONTENT_CATEGORY_START
    };
};

export const contentCategoryDismissError = () => {
    return {
        type: contentCategoryTypes.CONTENT_CATEGORY_DISMISS_ERROR
    };
};

export const updateContentCategory = (data, history) => {
    return {
        type: contentCategoryTypes.UPDATE_CONTENT_CATEGORY,
        data: data,
        history: history
    };
};

export const updateContentCategorySuccess = contentCategory => {
    return {
        type: contentCategoryTypes.UPDATE_CONTENT_CATEGORY_SUCCESS,
        contentCategory: contentCategory
    };
};

export const updateContentCategoryFail = error => {
    return {
        type: contentCategoryTypes.UPDATE_CONTENT_CATEGORY_FAIL,
        error: error
    };
};

export const updateContentCategoryStart = () => {
    return {
        type: contentCategoryTypes.UPDATE_CONTENT_CATEGORY_START
    };
};

export const deleteContentCategory = (id, history) => {
    return {
        type: contentCategoryTypes.DELETE_CONTENT_CATEGORY,
        id: id,
        history: history
    };
};

export const deleteContentCategorySuccess = () => {
    return {
        type: contentCategoryTypes.DELETE_CONTENT_CATEGORY_SUCCESS,
    };
};

export const deleteContentCategoryFail = error => {
    return {
        type: contentCategoryTypes.DELETE_CONTENT_CATEGORY_FAIL,
        error: error
    };
};

export const deleteContentCategoryStart = () => {
    return {
        type: contentCategoryTypes.DELETE_CONTENT_CATEGORY_START
    };
};

export const getContentCategory = (id) => {
    return {
        type: contentCategoryTypes.GET_CONTENT_CATEGORY,
        id: id
    };
};

export const getContentCategorySuccess = (contentCategory) => {
    return {
        type: contentCategoryTypes.GET_CONTENT_CATEGORY_SUCCESS,
        contentCategory: contentCategory
    };
};

export const getContentCategoryFail = error => {
    return {
        type: contentCategoryTypes.GET_CONTENT_CATEGORY_FAIL,
        error: error
    };
};

export const getContentCategoryStart = () => {
    return {
        type: contentCategoryTypes.GET_CONTENT_CATEGORY_START
    };
};

export const fetchContentsCategoriesSuccess = contentsCategories => {
    return {
        type: contentCategoryTypes.FETCH_CONTENTS_CATEGORIES_SUCCESS,
        contentsCategories: contentsCategories
    };
};

export const fetchContentsCategoriesFail = error => {
    return {
        type: contentCategoryTypes.FETCH_CONTENTS_CATEGORIES_FAIL,
        error: error
    };
};

export const fetchContentsCategoriesStart = () => {
    return {
        type: contentCategoryTypes.FETCH_CONTENTS_CATEGORIES_START
    };
};

export const fetchContentsCategories = (params) => {
    return {
        type: contentCategoryTypes.FETCH_CONTENTS_CATEGORIES,
        params: params
    };
};
