import * as privacyPolicyTypes from './privacy-policy.types';

export const addPrivacyPolicy = (data, history) => {
    return {
        type: privacyPolicyTypes.ADD_PRIVACY_POLICY,
        data: data,
        history: history
    };
};

export const addPrivacyPolicySuccess = privacyPolicy => {
    return {
        type: privacyPolicyTypes.ADD_PRIVACY_POLICY_SUCCESS,
        privacyPolicy: privacyPolicy
    };
};

export const addPrivacyPolicyFail = error => {
    return {
        type: privacyPolicyTypes.ADD_PRIVACY_POLICY_FAIL,
        error: error
    };
};

export const addPrivacyPolicyStart = () => {
    return {
        type: privacyPolicyTypes.ADD_PRIVACY_POLICY_START
    };
};

export const privacyPolicyDismissError = () => {
    return {
        type: privacyPolicyTypes.PRIVACY_POLICY_DISMISS_ERROR
    };
};

export const updatePrivacyPolicy = (data, history) => {
    return {
        type: privacyPolicyTypes.UPDATE_PRIVACY_POLICY,
        data: data,
        history: history
    };
};

export const updatePrivacyPolicySuccess = privacyPolicy => {
    return {
        type: privacyPolicyTypes.UPDATE_PRIVACY_POLICY_SUCCESS,
        privacyPolicy: privacyPolicy
    };
};

export const updatePrivacyPolicyFail = error => {
    return {
        type: privacyPolicyTypes.UPDATE_PRIVACY_POLICY_FAIL,
        error: error
    };
};

export const updatePrivacyPolicyStart = () => {
    return {
        type: privacyPolicyTypes.UPDATE_PRIVACY_POLICY_START
    };
};

export const deletePrivacyPolicy = (id, history) => {
    return {
        type: privacyPolicyTypes.DELETE_PRIVACY_POLICY,
        id: id,
        history: history
    };
};

export const deletePrivacyPolicySuccess = () => {
    return {
        type: privacyPolicyTypes.DELETE_PRIVACY_POLICY_SUCCESS,
    };
};

export const deletePrivacyPolicyFail = error => {
    return {
        type: privacyPolicyTypes.DELETE_PRIVACY_POLICY_FAIL,
        error: error
    };
};

export const deletePrivacyPolicyStart = () => {
    return {
        type: privacyPolicyTypes.DELETE_PRIVACY_POLICY_START
    };
};

export const getPrivacyPolicy = (id) => {
    return {
        type: privacyPolicyTypes.GET_PRIVACY_POLICY,
        id: id
    };
};

export const getPrivacyPolicySuccess = (privacyPolicy) => {
    return {
        type: privacyPolicyTypes.GET_PRIVACY_POLICY_SUCCESS,
        privacyPolicy: privacyPolicy
    };
};

export const getPrivacyPolicyFail = error => {
    return {
        type: privacyPolicyTypes.GET_PRIVACY_POLICY_FAIL,
        error: error
    };
};

export const getPrivacyPolicyStart = () => {
    return {
        type: privacyPolicyTypes.GET_PRIVACY_POLICY_START
    };
};

export const fetchPrivacyPolicySuccess = privacyPolicys => {
    return {
        type: privacyPolicyTypes.FETCH_PRIVACY_POLICY_SUCCESS,
        privacyPolicys: privacyPolicys
    };
};

export const fetchPrivacyPolicyFail = error => {
    return {
        type: privacyPolicyTypes.FETCH_PRIVACY_POLICY_FAIL,
        error: error
    };
};

export const fetchPrivacyPolicyStart = () => {
    return {
        type: privacyPolicyTypes.FETCH_PRIVACY_POLICY_START
    };
};

export const fetchPrivacyPolicy = (params) => {
    return {
        type: privacyPolicyTypes.FETCH_PRIVACY_POLICY,
        params: params
    };
};
