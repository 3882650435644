import { put } from "redux-saga/effects";
import * as actions from "store/reducers/food-unit/food-unit.actions";
import foodUnitService from "services/FoodUnit";
export function* fetchFoodUnitSaga(action) {
    yield put(actions.fetchFoodUnitStart());
    const service = yield new foodUnitService();
    const foodUnit = yield service.find(action.params);
    if(!foodUnit.error){
      yield put(actions.fetchFoodUnitSuccess(foodUnit));
    } else {
      yield put(actions.fetchFoodUnitFail(foodUnit.error));  
    }
}

export function* addFoodUnitSaga(action) {
  yield put(actions.addFoodUnitStart());
  const service = yield new foodUnitService();
  const foodUnit = yield service.create(action.data);
  if(!foodUnit.error){
    yield action.history.replace('/admin/food-unit');
  } else {
    yield put(actions.addFoodUnitFail(foodUnit.error));
  }
}

export function* deleteFoodUnitSaga(action) {
  yield put(actions.deleteFoodUnitStart());
  const service = yield new foodUnitService();
  const foodUnit = yield service.delete(action.id);
  if(!foodUnit.error){
    yield put(actions.deleteFoodUnitSuccess());
    if(action.history){
      action.history.replace('/admin/food-unit')
    }
  } else {
    yield put(actions.deleteFoodUnitFail(foodUnit.error));
  }
}

export function* getFoodUnitSaga(action) {
  yield put(actions.getFoodUnitStart());
  const service = yield new foodUnitService();
  const foodUnit = yield service.get(action.id);
  if(!foodUnit.error){
    yield put(actions.getFoodUnitSuccess(foodUnit));
  } else {
    yield put(actions.getFoodUnitFail(foodUnit.error));
  }
}

export function* updateFoodUnitSaga(action) {
  yield put(actions.updateFoodUnitStart());
  const service = yield new foodUnitService();
  const foodUnit = yield service.patch(action.data);
  if(!foodUnit.error){
    yield action.history.replace('/admin/food-unit');
  } else {
    yield put(actions.updateFoodUnitFail(foodUnit.error));
  }
}