import * as pinnedContentTypes from './pinned-content.types';

/**
 * FETCH PINNED_CONTENTS
 */
export const fetchPinnedContents = params => ({
  type: pinnedContentTypes.FETCH_PINNED_CONTENTS,
  params
});
export const fetchPinnedContentsStart = () => ({
  type: pinnedContentTypes.FETCH_PINNED_CONTENTS_START
});
export const fetchPinnedContentsSuccess = pinnedContents => ({
  type: pinnedContentTypes.FETCH_PINNED_CONTENTS_SUCCESS,
  pinnedContents
});
export const fetchPinnedContentsFail = error => ({
  type: pinnedContentTypes.FETCH_PINNED_CONTENTS_FAIL,
  error
});

/**
 * GET PINNED_CONTENT
 */
export const getPinnedContent = id => ({
  type: pinnedContentTypes.GET_PINNED_CONTENT,
  id
});
export const getPinnedContentStart = () => ({
  type: pinnedContentTypes.GET_PINNED_CONTENT_START
});
export const getPinnedContentSuccess = pinnedContent => ({
  type: pinnedContentTypes.GET_PINNED_CONTENT_SUCCESS,
  pinnedContent
});
export const getPinnedContentFail = error => ({
  type: pinnedContentTypes.GET_PINNED_CONTENT_FAIL,
  error
});

/**
 * CREATE PINNED_CONTENT
 */
export const addPinnedContent = (history, data, imgUploadPayload) => ({
  type: pinnedContentTypes.ADD_PINNED_CONTENT,
  history,
  data,
  imgUploadPayload
});
export const addPinnedContentStart = () => ({
  type: pinnedContentTypes.ADD_PINNED_CONTENT_START
});
export const addPinnedContentSuccess = pinnedContent => ({
  type: pinnedContentTypes.ADD_PINNED_CONTENT_SUCCESS,
  pinnedContent
});
export const addPinnedContentFail = error => ({
  type: pinnedContentTypes.ADD_PINNED_CONTENT_FAIL,
  error
});

/**
 * UPDATE PINNED_CONTENT
 */
export const updatePinnedContent = (history, data, imgUploadPayload) => ({
  type: pinnedContentTypes.UPDATE_PINNED_CONTENT,
  history,
  data,
  imgUploadPayload
});
export const updatePinnedContentStart = () => ({
  type: pinnedContentTypes.UPDATE_PINNED_CONTENT_START
});
export const updatePinnedContentSuccess = pinnedContent => ({
  type: pinnedContentTypes.UPDATE_PINNED_CONTENT_SUCCESS,
  pinnedContent
});
export const updatePinnedContentFail = error => ({
  type: pinnedContentTypes.UPDATE_PINNED_CONTENT_FAIL,
  error
});

/**
 * DELETE PINNED_CONTENT
 */
export const deletePinnedContent = (id, history) => ({
  type: pinnedContentTypes.DELETE_PINNED_CONTENT,
  id,
  history
});
export const deletePinnedContentStart = () => ({
  type: pinnedContentTypes.DELETE_PINNED_CONTENT_START
});
export const deletePinnedContentSuccess = () => ({
  type: pinnedContentTypes.DELETE_PINNED_CONTENT_SUCCESS
});

export const deletePinnedContentFail = error => ({
  type: pinnedContentTypes.DELETE_PINNED_CONTENT_FAIL,
  error
});

// ====== UTILITY  ======= //
export const pinnedContentDismissError = () => ({
  type: pinnedContentTypes.PINNED_CONTENT_DISMISS_ERROR
});

export const pinnedContentAddFilter = (filter, filterObj) => ({
  type: pinnedContentTypes.PINNED_CONTENT_ADD_FILTER,
  filter,
  filterObj
});

export const pinnedContentClearFilter = () => ({
  type: pinnedContentTypes.PINNED_CONTENT_CLEAR_FILTER
});

export const pinnedContentAddPagination = pagination => ({
  type: pinnedContentTypes.PINNED_CONTENT_ADD_PAGINATION,
  pagination
});

export const pinnedContentClearPagination = () => ({
  type: pinnedContentTypes.PINNED_CONTENT_CLEAR_PAGINATION
});
