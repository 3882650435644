import * as adminTypes from './admins.types';

export const addAdmin = (data, history) => {
    return {
        type: adminTypes.ADD_ADMIN,
        data: data,
        history: history
    };
};

export const addAdminSuccess = admin => {
    return {
        type: adminTypes.ADD_ADMIN_SUCCESS,
        admin: admin
    };
};

export const addAdminFail = error => {
    return {
        type: adminTypes.ADD_ADMIN_FAIL,
        error: error
    };
};

export const addAdminStart = () => {
    return {
        type: adminTypes.ADD_ADMIN_START
    };
};

export const updateAdmin = (data, history, isProfile) => {
    return {
        type: adminTypes.UPDATE_ADMIN,
        data: data,
        isProfile: isProfile,
        history: history
    };
};

export const updateAdminSuccess = admin => {
    return {
        type: adminTypes.UPDATE_ADMIN_SUCCESS,
        admin: admin
    };
};

export const updateAdminFail = error => {
    return {
        type: adminTypes.UPDATE_ADMIN_FAIL,
        error: error
    };
};

export const updateAdminStart = () => {
    return {
        type: adminTypes.UPDATE_ADMIN_START
    };
};

export const deleteAdmin = (id, history) => {
    return {
        type: adminTypes.DELETE_ADMIN,
        id: id,
        history: history
    };
};

export const deleteAdminSuccess = () => {
    return {
        type: adminTypes.DELETE_ADMIN_SUCCESS,
    };
};

export const deleteAdminFail = error => {
    return {
        type: adminTypes.DELETE_ADMIN_FAIL,
        error: error
    };
};

export const deleteAdminStart = () => {
    return {
        type: adminTypes.DELETE_ADMIN_START
    };
};

export const getAdmin = (id) => {
    return {
        type: adminTypes.GET_ADMIN,
        id: id
    };
};

export const getAdminSuccess = (admin) => {
    return {
        type: adminTypes.GET_ADMIN_SUCCESS,
        admin: admin
    };
};

export const getAdminFail = error => {
    return {
        type: adminTypes.GET_ADMIN_FAIL,
        error: error
    };
};

export const getAdminStart = () => {
    return {
        type: adminTypes.GET_ADMIN_START
    };
};

export const fetchAdminsSuccess = admins => {
    return {
        type: adminTypes.FETCH_ADMINS_SUCCESS,
        admins: admins
    };
};

export const fetchAdminsFail = error => {
    return {
        type: adminTypes.FETCH_ADMINS_FAIL,
        error: error
    };
};

export const fetchAdminsStart = () => {
    return {
        type: adminTypes.FETCH_ADMINS_START
    };
};

export const fetchAdmins = (params) => {
    return {
        type: adminTypes.FETCH_ADMINS,
        params: params
    };
};

// ====== UTILITY  ======= //
export const adminDismissError = () => {
    return {
        type: adminTypes.ADMIN_DISMISS_ERROR
    };
};

export const adminAddFilter = (filter, filterObj) => {
    return {
        type: adminTypes.ADMIN_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const adminClearFilter = () => {
    return {
        type: adminTypes.ADMIN_CLEAR_FILTER
    };
};

export const adminAddPagination = (pagination) => {
    return {
        type: adminTypes.ADMIN_ADD_PAGINATION,
        pagination: pagination
    };
};

export const adminClearPagination = () => {
    return {
        type: adminTypes.ADMIN_CLEAR_PAGINATION
    };
};
