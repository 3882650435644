export const ADD_ONBOARDING = 'ADD_ONBOARDING';
export const ADD_ONBOARDING_START = 'ADD_ONBOARDING_START';
export const ADD_ONBOARDING_SUCCESS = 'ADD_ONBOARDING_SUCCESS';
export const ADD_ONBOARDING_FAIL = 'ADD_ONBOARDING_FAIL';

export const DELETE_ONBOARDING = 'DELETE_ONBOARDING';
export const DELETE_ONBOARDING_START = 'DELETE_ONBOARDING_START';
export const DELETE_ONBOARDING_SUCCESS = 'DELETE_ONBOARDING_SUCCESS';
export const DELETE_ONBOARDING_FAIL = 'DELETE_ONBOARDING_FAIL';


export const UPDATE_ONBOARDING = 'UPDATE_ONBOARDING';
export const UPDATE_ONBOARDING_START = 'UPDATE_ONBOARDING_START';
export const UPDATE_ONBOARDING_SUCCESS = 'UPDATE_ONBOARDING_SUCCESS';
export const UPDATE_ONBOARDING_FAIL = 'UPDATE_ONBOARDING_FAIL';

export const GET_ONBOARDING = 'GET_ONBOARDING';
export const GET_ONBOARDING_START = 'GET_ONBOARDING_START';
export const GET_ONBOARDING_SUCCESS = 'GET_ONBOARDING_SUCCESS';
export const GET_ONBOARDING_FAIL = 'GET_ONBOARDING_FAIL';

export const FETCH_ONBOARDING = 'FETCH_ONBOARDING';
export const FETCH_ONBOARDING_START = 'FETCH_ONBOARDING_START';
export const FETCH_ONBOARDING_SUCCESS = 'FETCH_ONBOARDING_SUCCESS';
export const FETCH_ONBOARDING_FAIL = 'FETCH_ONBOARDING_FAIL';

// ====== UTILITY  ======= //
export const ONBOARDING_DISMISS_ERROR = 'ONBOARDING_DISMISS_ERROR';
export const ONBOARDING_ADD_FILTER = 'ONBOARDING_ADD_FILTER';
export const ONBOARDING_CLEAR_FILTER = 'ONBOARDING_CLEAR_FILTER';
export const ONBOARDING_ADD_PAGINATION = 'ONBOARDING_ADD_PAGINATION';
export const ONBOARDING_CLEAR_PAGINATION = 'ONBOARDING_CLEAR_PAGINATION';