import * as foodUnitTypes from "./food-unit.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  foodUnits: {},
  foodUnit: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1,
  },
};

const fetchFoodUnitStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchFoodUnitSuccess = (state, action) => {
  return updateObject(state, {
    foodUnits: action.foodUnits,
    foodUnit: {},
    loading: false,
    error: null,
  });
};

const fetchFoodUnitFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addFoodUnitStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addFoodUnitSuccess = (state, action) => {
  return updateObject(state, {
    foodUnit: action.foodUnit,
    loading: false,
    error: null,
  });
};

const addFoodUnitFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteFoodUnitStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteFoodUnitSuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null,
  });
};

const deleteFoodUnitFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getFoodUnitStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getFoodUnitSuccess = (state, action) => {
  return updateObject(state, {
    foodUnit: action.foodUnit,
    loading: false,
    error: null,
  });
};

const getFoodUnitFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateFoodUnitStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateFoodUnitSuccess = (state, action) => {
  return updateObject(state, {
    foodUnit: action.foodUnit,
    loading: false,
    error: null,
  });
};

const updateFoodUnitFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const foodUnitDismissError = (state) => {
  return updateObject(state, {
    error: null,
  });
};

const foodUnitAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj,
  });
};

const foodUnitClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {},
  });
};

const foodUnitAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination,
  });
};

const foodUnitClearPagination = (state) => {
  return updateObject(state, {
    pagination: {},
  });
};

const foodUnitReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case foodUnitTypes.FETCH_FOOD_UNIT_START:
      return fetchFoodUnitStart(state, action);
    case foodUnitTypes.FETCH_FOOD_UNIT_SUCCESS:
      return fetchFoodUnitSuccess(state, action);
    case foodUnitTypes.FETCH_FOOD_UNIT_FAIL:
      return fetchFoodUnitFail(state, action);
    case foodUnitTypes.ADD_FOOD_UNIT_START:
      return addFoodUnitStart(state, action);
    case foodUnitTypes.ADD_FOOD_UNIT_SUCCESS:
      return addFoodUnitSuccess(state, action);
    case foodUnitTypes.ADD_FOOD_UNIT_FAIL:
      return addFoodUnitFail(state, action);
    case foodUnitTypes.DELETE_FOOD_UNIT_START:
      return deleteFoodUnitStart(state, action);
    case foodUnitTypes.DELETE_FOOD_UNIT_SUCCESS:
      return deleteFoodUnitSuccess(state, action);
    case foodUnitTypes.DELETE_FOOD_UNIT_FAIL:
      return deleteFoodUnitFail(state, action);
    case foodUnitTypes.GET_FOOD_UNIT_START:
      return getFoodUnitStart(state, action);
    case foodUnitTypes.GET_FOOD_UNIT_SUCCESS:
      return getFoodUnitSuccess(state, action);
    case foodUnitTypes.GET_FOOD_UNIT_FAIL:
      return getFoodUnitFail(state, action);
    case foodUnitTypes.UPDATE_FOOD_UNIT_START:
      return updateFoodUnitStart(state, action);
    case foodUnitTypes.UPDATE_FOOD_UNIT_SUCCESS:
      return updateFoodUnitSuccess(state, action);
    case foodUnitTypes.UPDATE_FOOD_UNIT_FAIL:
      return updateFoodUnitFail(state, action);
    case foodUnitTypes.FOOD_UNIT_DISMISS_ERROR:
      return foodUnitDismissError(state);
    case foodUnitTypes.FOOD_UNIT_ADD_FILTER:
      return foodUnitAddFilter(state, action);
    case foodUnitTypes.FOOD_UNIT_CLEAR_FILTER:
      return foodUnitClearFilter(state);
    case foodUnitTypes.FOOD_UNIT_ADD_PAGINATION:
      return foodUnitAddPagination(state, action);
    case foodUnitTypes.FOOD_UNIT_CLEAR_PAGINATION:
      return foodUnitClearPagination(state);

    default:
      return state;
  }
};

export default foodUnitReducer;
