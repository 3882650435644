import * as TopFoodTypes from './topFood.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  topFood: [],
  loading: false
};

/**
 * FETCH TOP FOOD
 */
const fetchTopFoodStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchTopFoodSuccess = (state, action) =>
  updateObject(state, {
    topFood: action.topFood,
    loading: false
  });
const fetchTopFoodFail = (state, action) =>
  updateObject(state, { loading: false });

const TopFoodReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TopFoodTypes.FETCH_TOP_FOOD_START:
      return fetchTopFoodStart(state, action);
    case TopFoodTypes.FETCH_TOP_FOOD_SUCCESS:
      return fetchTopFoodSuccess(state, action);
    case TopFoodTypes.FETCH_TOP_FOOD_FAIL:
      return fetchTopFoodFail(state, action);

    default:
      return state;
  }
};

export default TopFoodReducer;
