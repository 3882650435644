import * as bannerTypes from "./banner.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  banners: {},
  banner: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  },
};

/**
 * FETCH BANNERS
 */
const fetchBannersStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchBannersSuccess = (state, action) =>
  updateObject(state, {
    banners: action.banners,
    banner: {},
    loading: false,
    error: null
  });
const fetchBannersFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * CREATE BANNER
 */
const addBannerStart = (state, action) =>
  updateObject(state, { loading: true });
const addBannerSuccess = (state, action) =>
  updateObject(state, {
    banner: action.banner,
    loading: false,
    error: null
  });
const addBannerFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * GET BANNER
 */
const getBannerStart = (state, action) =>
  updateObject(state, { loading: true });
const getBannerSuccess = (state, action) =>
  updateObject(state, {
    banner: action.banner,
    loading: false,
    error: null
  });
const getBannerFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPDATE BANNER
 */
const updateBannerStart = (state, action) =>
  updateObject(state, { loading: true });
const updateBannerSuccess = (state, action) =>
  updateObject(state, {
    banner: action.banner,
    loading: false,
    error: null
  });
const updateBannerFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * DELETE BANNER
 */
const deleteBannerStart = (state, action) =>
  updateObject(state, { loading: true });
const deleteBannerSuccess = (state, action) =>
  updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
const deleteBannerFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

// ====== UTILITY  ======= //

const bannerDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const bannerAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj
  });
};

const bannerClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {}
  });
};

const bannerAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination
  });
};

const bannerClearPagination = (state) => {
  return updateObject(state, {
    pagination: {}
  });
};

const bannerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case bannerTypes.FETCH_BANNERS_START:
      return fetchBannersStart(state, action);
    case bannerTypes.FETCH_BANNERS_SUCCESS:
      return fetchBannersSuccess(state, action);
    case bannerTypes.FETCH_BANNERS_FAIL:
      return fetchBannersFail(state, action);

    case bannerTypes.ADD_BANNER_START:
      return addBannerStart(state, action);
    case bannerTypes.ADD_BANNER_SUCCESS:
      return addBannerSuccess(state, action);
    case bannerTypes.ADD_BANNER_FAIL:
      return addBannerFail(state, action);

    case bannerTypes.GET_BANNER_START:
      return getBannerStart(state, action);
    case bannerTypes.GET_BANNER_SUCCESS:
      return getBannerSuccess(state, action);
    case bannerTypes.GET_BANNER_FAIL:
      return getBannerFail(state, action);

    case bannerTypes.UPDATE_BANNER_START:
      return updateBannerStart(state, action);
    case bannerTypes.UPDATE_BANNER_SUCCESS:
      return updateBannerSuccess(state, action);
    case bannerTypes.UPDATE_BANNER_FAIL:
      return updateBannerFail(state, action);

    case bannerTypes.DELETE_BANNER_START:
      return deleteBannerStart(state, action);
    case bannerTypes.DELETE_BANNER_SUCCESS:
      return deleteBannerSuccess(state, action);
    case bannerTypes.DELETE_BANNER_FAIL:
      return deleteBannerFail(state, action);

    case bannerTypes.BANNER_DISMISS_ERROR:
      return bannerDismissError(state);
    case bannerTypes.BANNER_ADD_FILTER:
      return bannerAddFilter(state, action);
    case bannerTypes.BANNER_CLEAR_FILTER:
      return bannerClearFilter(state);
    case bannerTypes.BANNER_ADD_PAGINATION:
      return bannerAddPagination(state, action);
    case bannerTypes.BANNER_CLEAR_PAGINATION:
      return bannerClearPagination(state);
    default:
      return state;
  }
};

export default bannerReducer;
