export const ADD_CONTENT = 'ADD_CONTENT';
export const ADD_CONTENT_START = 'ADD_CONTENT_START';
export const ADD_CONTENT_SUCCESS = 'ADD_CONTENT_SUCCESS';
export const ADD_CONTENT_FAIL = 'ADD_CONTENT_FAIL';

export const DELETE_CONTENT = 'DELETE_CONTENT';
export const DELETE_CONTENT_START = 'DELETE_CONTENT_START';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAIL = 'DELETE_CONTENT_FAIL';


export const UPDATE_CONTENT = 'UPDATE_CONTENT';
export const UPDATE_CONTENT_START = 'UPDATE_CONTENT_START';
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_FAIL = 'UPDATE_CONTENT_FAIL';

export const GET_CONTENT = 'GET_CONTENT';
export const GET_CONTENT_START = 'GET_CONTENT_START';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAIL = 'GET_CONTENT_FAIL';

export const FETCH_CONTENTS = 'FETCH_CONTENTS';
export const FETCH_CONTENTS_START = 'FETCH_CONTENTS_START';
export const FETCH_CONTENTS_SUCCESS = 'FETCH_CONTENTS_SUCCESS';
export const FETCH_CONTENTS_FAIL = 'FETCH_CONTENTS_FAIL';


export const CONTENT_DISMISS_ERROR = 'CONTENT_DISMISS_ERROR';