import { takeEvery } from "redux-saga/effects";
import * as OtherLabResultTypes from "store/reducers/otherLabResult/OtherLabResult.types";
import {
  fetchBloodPressureSaga,
  fetchCholesterolSaga,
  fetchKidneySaga
} from "./OtherLabResultSaga";

export const OtherLabResultSagas = {
  watchFetchBloodPressure: takeEvery(
    OtherLabResultTypes.FETCH_BLOOD_PRESSURE,
    fetchBloodPressureSaga
  ),
  watchFetchCholesterol: takeEvery(
    OtherLabResultTypes.FETCH_CHOLESTEROL,
    fetchCholesterolSaga
  ),
  watchFetchKidney: takeEvery(OtherLabResultTypes.FETCH_KIDNEY, fetchKidneySaga)
};
