import React from 'react';

import './Spinner.css';

const spinner = () => (
    <div className="overlay">
      <div className="spinner"></div>  
    </div>
);

export default spinner;