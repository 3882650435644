import { put } from "redux-saga/effects";
import * as actions from "store/reducers/top-medicine/topMedicine.actions";
import TopMedicineService from "../../../services/TopMedicine";

/**
 * FETCH TOP MEDICINE
 */
export function* fetchTopMedicineSaga(action) {
  yield put(actions.fetchTopMedicineStart());

  try {
    const service = yield new TopMedicineService();
    const topMedicine = yield service.find(action.params);

    yield put(actions.fetchTopMedicineSuccess(topMedicine));
  } catch (error) {
    yield put(actions.fetchTopMedicineFail(error));
  }
}

/**
 * FETCH TOP MANUFACTURER
 */
export function* fetchTopManufacturerSaga(action) {
  yield put(actions.fetchTopManufacturerStart());

  try {
    const service = yield new TopMedicineService();
    const topManufacturer = yield service.find(action.params);

    yield put(actions.fetchTopManufacturerSuccess(topManufacturer));
  } catch (error) {
    yield put(actions.fetchTopManufacturerFail(error));
  }
}
