import { put } from "redux-saga/effects";
import * as actions from "../../reducers/banner/banner.actions";
import bannerService from "../../../services/Banner";
import uploadService from "../../../services/Upload";

/**
 * FETCH BANNERS
 */
export function* fetchBannersSaga(action) {
  yield put(actions.fetchBannersStart());
  const service = yield new bannerService();
  const banners = yield service.find(action.params);
  if (banners.error) {
    yield put(actions.fetchBannersFail(banners.error));
    return;
  }
  yield put(actions.fetchBannersSuccess(banners));
}

/**
 * CREATE BANNER
 */
export function* addBannerSaga(action) {
  yield put(actions.addBannerStart());
  const upService = yield new uploadService();
  let img = {};
  if (action.imgUploadPayload) {
    img = yield upService.upload(action.imgUploadPayload);
    if (img.error) {
      yield put(actions.addBannerFail(img.error));
      return;
    }
  }

  const service = yield new bannerService();
  action.data.imageUrl = action.imgUploadPayload ? img.data.url : null

  const banner = yield service.create(action.data);
  if (banner.error) {
    yield put(actions.addBannerFail(banner.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * GET BANNER
 */
export function* getBannerSaga(action) {
  yield put(actions.getBannerStart());
  const service = yield new bannerService();
  const banner = yield service.get(action.id);
  if (banner.error) {
    yield put(actions.getBannerFail(banner.error));
    return;
  }

  yield put(actions.getBannerSuccess(banner));
}

/**
 * UPDATE BANNER
 */
export function* updateBannerSaga(action) {
  yield put(actions.updateBannerStart());
  let upload = {};

  if (action.imgUploadPayload) {
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.addBannerFail(upload.error));
      return;
    }
  }

  const service = yield new bannerService();
  if(action.imgUploadPayload){
    action.data.imageUrl =  upload.data.url;
  }
  
  const banner = yield service.patch(action.data);
  if (banner.error) {
    yield put(actions.updateBannerFail(banner.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * DELETE BANNER
 */
export function* deleteBannerSaga(action) {
  yield put(actions.deleteBannerStart());
  const service = yield new bannerService();
  const banner = yield service.delete(action.id);
  if (banner.error) {
    yield put(actions.deleteBannerFail(banner.error));
    return;
  }
  yield put(actions.deleteBannerSuccess());
  if (action.history) {
    action.history.goBack();
  }
}
