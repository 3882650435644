import { put } from "redux-saga/effects";
import * as actions from "../../reducers/food-recipe/food-recipe.actions";
import foodRecipeService from "../../../services/FoodRecipe";
import uploadService from "../../../services/Upload";

/**
 * FETCH FOOD_RECIPES
 */
export function* fetchFoodRecipesSaga(action) {
  yield put(actions.fetchFoodRecipesStart());
  const service = yield new foodRecipeService();

  const foodRecipes = yield service.find(action.params);
  if (foodRecipes.error) {
    yield put(actions.fetchFoodRecipesFail(foodRecipes.error));
    return;
  }
  yield put(actions.fetchFoodRecipesSuccess(foodRecipes));
}

/**
 * GET FOOD_RECIPE
 */
export function* getFoodRecipeSaga(action) {
  yield put(actions.getFoodRecipeStart());
  const service = yield new foodRecipeService();

  const foodRecipe = yield service.get(action.id);
  if (foodRecipe.error) {
    yield put(actions.getFoodRecipeFail(foodRecipe.error));
    return;
  }
  yield put(actions.getFoodRecipeSuccess(foodRecipe));
}

/**
 * CREATE FOOD_RECIPE
 */
export function* addFoodRecipeSaga(action) {
  yield put(actions.addFoodRecipeStart());
  const upService = yield new uploadService();
  let img = {};
  if (action.imgUploadPayload) {
    img = yield upService.upload(action.imgUploadPayload);
    if (img.error) {
      yield put(actions.addFoodRecipeFail(img.error));
      return;
    }
  }

  const service = yield new foodRecipeService();
  action.data.coverImage = {
    url: action.imgUploadPayload ? img.data.url : null,
    type: "image"
  };

  const foodRecipe = yield service.create(action.data);
  if (foodRecipe.error) {
    yield put(actions.addFoodRecipeFail(foodRecipe.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * UPDATE FOOD_RECIPE
 */
export function* updateFoodRecipeSaga(action) {
  yield put(actions.updateFoodRecipeStart());
  let upload = {};

  if (action.imgUploadPayload) {
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.addFoodRecipeFail(upload.error));
      return;
    }
  }

  const service = yield new foodRecipeService();
  action.data.coverImage = action.imgUploadPayload ? upload.data.url : null;
  const foodRecipe = yield service.patch(action.data);
  if (foodRecipe.error) {
    yield put(actions.updateFoodRecipeFail(foodRecipe.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * DELETE FOOD_RECIPE
 */
export function* deleteFoodRecipeSaga(action) {
  yield put(actions.deleteFoodRecipeStart());
  const service = yield new foodRecipeService();
  const foodRecipe = yield service.delete(action.id);
  if (foodRecipe.error) {
    yield put(actions.deleteFoodRecipeFail(foodRecipe.error));
    return;
  }
  yield put(actions.deleteFoodRecipeSuccess());
  if (action.history) {
    action.history.goBack();
  }
}
