/**
 * OVERCALORIE DIABETICS
 */
export const FETCH_OVERCALORIE_DIABETICS = "FETCH_OVERCALORIE_DIABETICS";
export const FETCH_OVERCALORIE_DIABETICS_START =
  "FETCH_OVERCALORIE_DIABETICS_START";
export const FETCH_OVERCALORIE_DIABETICS_SUCCESS =
  "FETCH_OVERCALORIE_DIABETICS_SUCCESS";
export const FETCH_OVERCALORIE_DIABETICS_FAIL =
  "FETCH_OVERCALORIE_DIABETICS_FAIL";
