export const ADD_PINNED_CONTENT = 'ADD_PINNED_CONTENT';
export const ADD_PINNED_CONTENT_START = 'ADD_PINNED_CONTENT_START';
export const ADD_PINNED_CONTENT_SUCCESS = 'ADD_PINNED_CONTENT_SUCCESS';
export const ADD_PINNED_CONTENT_FAIL = 'ADD_PINNED_CONTENT_FAIL';

export const DELETE_PINNED_CONTENT = 'DELETE_PINNED_CONTENT';
export const DELETE_PINNED_CONTENT_START = 'DELETE_PINNED_CONTENT_START';
export const DELETE_PINNED_CONTENT_SUCCESS = 'DELETE_PINNED_CONTENT_SUCCESS';
export const DELETE_PINNED_CONTENT_FAIL = 'DELETE_PINNED_CONTENT_FAIL';

export const UPDATE_PINNED_CONTENT = 'UPDATE_PINNED_CONTENT';
export const UPDATE_PINNED_CONTENT_START = 'UPDATE_PINNED_CONTENT_START';
export const UPDATE_PINNED_CONTENT_SUCCESS = 'UPDATE_PINNED_CONTENT_SUCCESS';
export const UPDATE_PINNED_CONTENT_FAIL = 'UPDATE_PINNED_CONTENT_FAIL';

export const GET_PINNED_CONTENT = 'GET_PINNED_CONTENT';
export const GET_PINNED_CONTENT_START = 'GET_PINNED_CONTENT_START';
export const GET_PINNED_CONTENT_SUCCESS = 'GET_PINNED_CONTENT_SUCCESS';
export const GET_PINNED_CONTENT_FAIL = 'GET_PINNED_CONTENT_FAIL';

export const FETCH_PINNED_CONTENTS = 'FETCH_PINNED_CONTENTS';
export const FETCH_PINNED_CONTENTS_START = 'FETCH_PINNED_CONTENTS_START';
export const FETCH_PINNED_CONTENTS_SUCCESS = 'FETCH_PINNED_CONTENTS_SUCCESS';
export const FETCH_PINNED_CONTENTS_FAIL = 'FETCH_PINNED_CONTENTS_FAIL';

// ====== UTILITY  ======= //
export const PINNED_CONTENT_DISMISS_ERROR = 'PINNED_CONTENT_DISMISS_ERROR';
export const PINNED_CONTENT_ADD_FILTER = 'PINNED_CONTENT_ADD_FILTER';
export const PINNED_CONTENT_CLEAR_FILTER = 'PINNED_CONTENT_CLEAR_FILTER';
export const PINNED_CONTENT_ADD_PAGINATION = 'PINNED_CONTENT_ADD_PAGINATION';
export const PINNED_CONTENT_CLEAR_PAGINATION =
  'PINNED_CONTENT_CLEAR_PAGINATION';
