import * as contentCategoryTypes from "store/reducers/content-category/content-category.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchContentsCategoriesSaga,
  addContentCategorySaga,
  deleteContentCategorySaga,
  getContentCategorySaga,
  updateContentCategorySaga
} from "./content-category";

export const contentCategorySagas = {
  watchFetchContentCategories: takeEvery(
    contentCategoryTypes.FETCH_CONTENTS_CATEGORIES,
    fetchContentsCategoriesSaga
  ),
  watchAddContentCategory: takeEvery(
    contentCategoryTypes.ADD_CONTENT_CATEGORY,
    addContentCategorySaga
  ),
  watchDeleteContentCategory: takeEvery(
    contentCategoryTypes.DELETE_CONTENT_CATEGORY,
    deleteContentCategorySaga
  ),
  watchGetContentCategory: takeEvery(
    contentCategoryTypes.GET_CONTENT_CATEGORY,
    getContentCategorySaga
  ),
  watchUpdateContentCategory: takeEvery(
    contentCategoryTypes.UPDATE_CONTENT_CATEGORY,
    updateContentCategorySaga
  )
}