import * as foodTypes from "store/reducers/food/food.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchFoodSaga,
  addFoodSaga,
  deleteFoodSaga,
  getFoodSaga,
  updateFoodSaga
} from "./food";

export const foodSagas = {
  watchFetchFood: takeEvery(foodTypes.FETCH_FOOD, fetchFoodSaga),
  watchAddFood: takeEvery(foodTypes.ADD_FOOD, addFoodSaga),
  watchDeleteFood: takeEvery(foodTypes.DELETE_FOOD, deleteFoodSaga),
  watchGetFood: takeEvery(foodTypes.GET_FOOD, getFoodSaga),
  watchUpdateFood: takeEvery(foodTypes.UPDATE_FOOD, updateFoodSaga)
}