import { put } from "redux-saga/effects";
import * as actions from "store/reducers/otherLabResult/OtherLabResult.actions";
import OtherLabResultService from "../../../services/OtherLabResult";

/**
 * FETCH BLOOD PRESSURE
 */
export function* fetchBloodPressureSaga(action) {
  yield put(actions.fetchBloodPressureStart());

  try {
    action.params.otherLabResultType = 1;
    const service = yield new OtherLabResultService();
    const bloodPressure = yield service.find(action.params);

    yield put(actions.fetchBloodPressureSuccess(bloodPressure));
  } catch (error) {
    yield put(actions.fetchBloodPressureFail(error));
  }
}

/**
 * FETCH CHOLESTEROL
 */
export function* fetchCholesterolSaga(action) {
  yield put(actions.fetchCholesterolStart());

  try {
    action.params.otherLabResultType = 2;
    const service = yield new OtherLabResultService();
    const cholesterol = yield service.find(action.params);

    yield put(actions.fetchCholesterolSuccess(cholesterol));
  } catch (error) {
    yield put(actions.fetchCholesterolFail(error));
  }
}

/**
 * FETCH KIDNEY
 */
export function* fetchKidneySaga(action) {
  yield put(actions.fetchKidneyStart());

  try {
    action.params.otherLabResultType = 3;
    const service = yield new OtherLabResultService();
    const kidney = yield service.find(action.params);

    yield put(actions.fetchKidneySuccess(kidney));
  } catch (error) {
    yield put(actions.fetchKidneyFail(error));
  }
}
