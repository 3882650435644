import * as adminAuthManagementTypes from "./adminAuthManagement.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  error: null,
  resetPasswordState: {}
};

/**
 * RESET PASSWORD
 */
const resetPasswordStart = (state, action) =>
  updateObject(state, { loading: true });
const resetPasswordSuccess = (state, action) =>
  updateObject(state, {
    error: null,
    loading: false
  });
const resetPasswordFail = (state, action) =>
  updateObject(state, {
    error: action.error,
    loading: false
  });

const dismissError = (state, action) =>
  updateObject(state, { error: null, loading: false });

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case adminAuthManagementTypes.RESET_PASSWORD:
      return resetPasswordStart(state, action);
    case adminAuthManagementTypes.RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state, action);
    case adminAuthManagementTypes.RESET_PASSWORD_FAIL:
      return resetPasswordFail(state, action);
    case adminAuthManagementTypes.DISMISS_ERROR:
      return dismissError(state, action);

    default:
      return state;
  }
};

export default userReducer;
