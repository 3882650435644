import * as OtherLabResultTypes from "./OtherLabResult.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  bloodPressure: null,
  kidney: null,
  cholesterol: null,
  url: "",
  loading: false
};

/**
 * FETCH BLOOD PRESSURE
 */
const fetchBloodPressureStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchBloodPressureSuccess = (state, action) => {
  return updateObject(state, {
    bloodPressure: action.bloodPressure,
    loading: false
  });
};
const fetchBloodPressureFail = (state, action) => {
  return updateObject(state, { loading: false });
};

/**
 * FETCH CHOLESTEROL
 */
const fetchCholesterolStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchCholesterolSuccess = (state, action) => {
  return updateObject(state, {
    cholesterol: action.cholesterol,
    loading: false
  });
};
const fetchCholesterolFail = (state, action) => {
  return updateObject(state, { loading: false });
};

/**
 * FETCH KIDNEY
 */
const fetchKidneyStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchKidneySuccess = (state, action) => {
  return updateObject(state, {
    kidney: action.kidney,
    loading: false
  });
};
const fetchKidneyFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const OtherLabResultReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OtherLabResultTypes.FETCH_BLOOD_PRESSURE_START:
      return fetchBloodPressureStart(state, action);
    case OtherLabResultTypes.FETCH_BLOOD_PRESSURE_SUCCESS:
      return fetchBloodPressureSuccess(state, action);
    case OtherLabResultTypes.FETCH_BLOOD_PRESSURE_FAIL:
      return fetchBloodPressureFail(state, action);

    case OtherLabResultTypes.FETCH_CHOLESTEROL_START:
      return fetchCholesterolStart(state, action);
    case OtherLabResultTypes.FETCH_CHOLESTEROL_SUCCESS:
      return fetchCholesterolSuccess(state, action);
    case OtherLabResultTypes.FETCH_CHOLESTEROL_FAIL:
      return fetchCholesterolFail(state, action);

    case OtherLabResultTypes.FETCH_KIDNEY_START:
      return fetchKidneyStart(state, action);
    case OtherLabResultTypes.FETCH_KIDNEY_SUCCESS:
      return fetchKidneySuccess(state, action);
    case OtherLabResultTypes.FETCH_KIDNEY_FAIL:
      return fetchKidneyFail(state, action);

    default:
      return state;
  }
};

export default OtherLabResultReducer;
