import Service from "./Service";

export default class Users extends Service {
  constructor() {
    super("chart-overcalorie-diabetics", true);
  }

  async find(params) {
    const response = await super.find(params);
    return super.generateDefaultResponse(response);
  }
}
