import { put } from 'redux-saga/effects';
import * as actions from '../../reducers/versioning/versioning.actions';
import VersioningService from '../../../services/Versioning';

/**
 * FETCH VERSIONING
 */
export function* fetchVersioningSaga(action) {
  yield put(actions.fetchVersioningStart());
  const service = yield new VersioningService();

  const versioning = yield service.find(action.params);
  if (versioning.error) {
    yield put(actions.fetchVersioningFail(versioning.error));
    return;
  }
  yield put(actions.fetchVersioningSuccess(versioning));
}

/**
 * GET VERSIONING
 */
export function* getVersioningSaga(action) {
  yield put(actions.getVersioningStart());
  const service = yield new VersioningService();

  const versioning = yield service.get(action.id);
  if (versioning.error) {
    yield put(actions.getVersioningFail(versioning.error));
    return;
  }
  yield put(actions.getVersioningSuccess(versioning));
}

/**
 * CREATE VERSIONING
 */
export function* addVersioningSaga(action) {
  yield put(actions.addVersioningStart());

  const service = yield new VersioningService();
  const versioning = yield service.create(action.data);
  if (versioning.error) {
    yield put(actions.addVersioningFail(versioning.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * UPDATE VERSIONING
 */
export function* updateVersioningSaga(action) {
  yield put(actions.updateVersioningStart());

  const service = yield new VersioningService();
  const versioning = yield service.patch(action.data);
  if (versioning.error) {
    yield put(actions.updateVersioningFail(versioning.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * DELETE VERSIONING
 */
export function* deleteVersioningSaga(action) {
  yield put(actions.deleteVersioningStart());
  const service = yield new VersioningService();
  const versioning = yield service.delete(action.id);
  if (versioning.error) {
    yield put(actions.deleteVersioningFail(versioning.error));
    return;
  }
  yield put(actions.deleteVersioningSuccess());
  if (action.history) {
    action.history.goBack();
  }
}
