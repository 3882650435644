import { put } from "redux-saga/effects";
import * as actions from "store/reducers/push-notification/push-notification.actions";
import pushNotificationService from "services/PushNotification";
import uploadService from "services/Upload";
export function* fetchPushNotificationSaga(action) {
    yield put(actions.fetchPushNotificationStart());
    const service = yield new pushNotificationService();
    const pushNotification = yield service.find(action.params);
    if(!pushNotification.error){
      yield put(actions.fetchPushNotificationSuccess(pushNotification));
    } else {
      yield put(actions.fetchPushNotificationFail(pushNotification.error));  
    }
}

export function* addPushNotificationSaga(action) {
  yield put(actions.addPushNotificationStart());
  let upload = {};
  if(action.imgUploadPayload !== null){
    // Img Upload
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.addPushNotificationFail(upload.error));
      return;
    }
  }

  // add pushNotification
  action.data.image = action.imgUploadPayload ? upload.data.url : null;
  const service = yield new pushNotificationService();
  const pushNotification = yield service.create(action.data);
  if(!pushNotification.error){
    yield action.history.replace('/admin/push-notification');
  } else {
    yield put(actions.addPushNotificationFail(pushNotification.error));
  }
}

export function* deletePushNotificationSaga(action) {
  yield put(actions.deletePushNotificationStart());
  const service = yield new pushNotificationService();
  const pushNotification = yield service.delete(action.id);
  if(!pushNotification.error){
    yield put(actions.deletePushNotificationSuccess());
    if(action.history){
      action.history.replace('/admin/push-notification')
    }
  } else {
    yield put(actions.deletePushNotificationFail(pushNotification.error));
  }
}

export function* getPushNotificationSaga(action) {
  yield put(actions.getPushNotificationStart());
  const service = yield new pushNotificationService();
  const pushNotification = yield service.get(action.id);
  if(!pushNotification.error){
    yield put(actions.getPushNotificationSuccess(pushNotification));
  } else {
    yield put(actions.getPushNotificationFail(pushNotification.error));
  }
}

export function* updatePushNotificationSaga(action) {
  yield put(actions.updatePushNotificationStart());
  let upload = {};
  if(action.imgUploadPayload !== null){
    // Img Upload
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.updatePushNotificationFail(upload.error));
      return;
    }
  }

  const service = yield new pushNotificationService();
  action.data.image = action.imgUploadPayload ? upload.data.url : null;
  const pushNotification = yield service.patch(action.data);
  if(!pushNotification.error){
    yield action.history.replace('/admin/push-notification');
  } else {
    yield put(actions.updatePushNotificationFail(pushNotification.error));
  }
}