import * as foodRecipeTypes from "./food-recipe.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  foodRecipes: {},
  foodRecipe: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1,
  },
};

/**
 * FETCH FOOD_RECIPES
 */
const fetchFoodRecipesStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchFoodRecipesSuccess = (state, action) =>
  updateObject(state, {
    foodRecipes: action.foodRecipes,
    foodRecipe: {},
    loading: false,
    error: null,
  });
const fetchFoodRecipesFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * CREATE FOOD_RECIPE
 */
const addFoodRecipeStart = (state, action) =>
  updateObject(state, { loading: true });
const addFoodRecipeSuccess = (state, action) =>
  updateObject(state, {
    foodRecipe: action.foodRecipe,
    loading: false,
    error: null,
  });
const addFoodRecipeFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * GET FOOD_RECIPE
 */
const getFoodRecipeStart = (state, action) =>
  updateObject(state, { loading: true });
const getFoodRecipeSuccess = (state, action) =>
  updateObject(state, {
    foodRecipe: action.foodRecipe,
    loading: false,
    error: null,
  });
const getFoodRecipeFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPDATE FOOD_RECIPE
 */
const updateFoodRecipeStart = (state, action) =>
  updateObject(state, { loading: true });
const updateFoodRecipeSuccess = (state, action) =>
  updateObject(state, {
    foodRecipe: action.foodRecipe,
    loading: false,
    error: null,
  });
const updateFoodRecipeFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * DELETE FOOD_RECIPE
 */
const deleteFoodRecipeStart = (state, action) =>
  updateObject(state, { loading: true });
const deleteFoodRecipeSuccess = (state, action) =>
  updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null,
  });
const deleteFoodRecipeFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

// ====== UTILITY  ======= //

const foodRecipeDismissError = (state) => {
  return updateObject(state, {
    error: null,
  });
};

const foodRecipeAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj,
  });
};

const foodRecipeClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {},
  });
};

const foodRecipeAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination,
  });
};

const foodRecipeClearPagination = (state) => {
  return updateObject(state, {
    pagination: {},
  });
};

const foodRecipeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case foodRecipeTypes.FETCH_FOOD_RECIPES_START:
      return fetchFoodRecipesStart(state, action);
    case foodRecipeTypes.FETCH_FOOD_RECIPES_SUCCESS:
      return fetchFoodRecipesSuccess(state, action);
    case foodRecipeTypes.FETCH_FOOD_RECIPES_FAIL:
      return fetchFoodRecipesFail(state, action);

    case foodRecipeTypes.ADD_FOOD_RECIPE_START:
      return addFoodRecipeStart(state, action);
    case foodRecipeTypes.ADD_FOOD_RECIPE_SUCCESS:
      return addFoodRecipeSuccess(state, action);
    case foodRecipeTypes.ADD_FOOD_RECIPE_FAIL:
      return addFoodRecipeFail(state, action);

    case foodRecipeTypes.GET_FOOD_RECIPE_START:
      return getFoodRecipeStart(state, action);
    case foodRecipeTypes.GET_FOOD_RECIPE_SUCCESS:
      return getFoodRecipeSuccess(state, action);
    case foodRecipeTypes.GET_FOOD_RECIPE_FAIL:
      return getFoodRecipeFail(state, action);

    case foodRecipeTypes.UPDATE_FOOD_RECIPE_START:
      return updateFoodRecipeStart(state, action);
    case foodRecipeTypes.UPDATE_FOOD_RECIPE_SUCCESS:
      return updateFoodRecipeSuccess(state, action);
    case foodRecipeTypes.UPDATE_FOOD_RECIPE_FAIL:
      return updateFoodRecipeFail(state, action);

    case foodRecipeTypes.DELETE_FOOD_RECIPE_START:
      return deleteFoodRecipeStart(state, action);
    case foodRecipeTypes.DELETE_FOOD_RECIPE_SUCCESS:
      return deleteFoodRecipeSuccess(state, action);
    case foodRecipeTypes.DELETE_FOOD_RECIPE_FAIL:
      return deleteFoodRecipeFail(state, action);

    case foodRecipeTypes.FOOD_RECIPE_DISMISS_ERROR:
      return foodRecipeDismissError(state);
    case foodRecipeTypes.FOOD_RECIPE_ADD_FILTER:
      return foodRecipeAddFilter(state, action);
    case foodRecipeTypes.FOOD_RECIPE_CLEAR_FILTER:
      return foodRecipeClearFilter(state);
    case foodRecipeTypes.FOOD_RECIPE_ADD_PAGINATION:
      return foodRecipeAddPagination(state, action);
    case foodRecipeTypes.FOOD_RECIPE_CLEAR_PAGINATION:
      return foodRecipeClearPagination(state);
    default:
      return state;
  }
};

export default foodRecipeReducer;
