import * as authTypes from "store/reducers/auth/auth.types";
import { takeEvery } from "redux-saga/effects";
import {
  logoutSaga,
  authUserSaga,
  authCheckStateSaga
} from "./auth";

export const authSagas = {
  watchAuthInitiateLogout: takeEvery(authTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
  watchAuthUser: takeEvery(authTypes.AUTH_USER, authUserSaga),
  wathAuthCheckState: takeEvery(authTypes.AUTH_CHECK_STATE, authCheckStateSaga)
};