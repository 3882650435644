import * as onboardingTypes from "./onboarding.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  onboardings: {},
  onboarding: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  },
};

const fetchOnboardingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchOnboardingSuccess = (state, action) => {
  return updateObject(state, {
    onboardings: action.onboardings,
    onboarding: {},
    loading: false,
    error: null
  });
};

const fetchOnboardingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addOnboardingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addOnboardingSuccess = (state, action) => {
  return updateObject(state, {
    onboarding: action.onboarding,
    loading: false,
    error: null
  });
};

const addOnboardingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteOnboardingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteOnboardingSuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deleteOnboardingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getOnboardingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getOnboardingSuccess = (state, action) => {
  return updateObject(state, {
    onboarding: action.onboarding,
    loading: false,
    error: null
  });
};

const getOnboardingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateOnboardingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateOnboardingSuccess = (state, action) => {
  return updateObject(state, {
    onboarding: action.onboarding,
    loading: false,
    error: null
  });
};

const updateOnboardingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const onboardingDismissError = (state) => {
  return updateObject(state, {
    error: null,
  });
};

const onboardingAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj,
  });
};

const onboardingClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {},
  });
};

const onboardingAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination,
  });
};

const onboardingClearPagination = (state) => {
  return updateObject(state, {
    pagination: {},
  });
};

const onboardingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case onboardingTypes.FETCH_ONBOARDING_START:
      return fetchOnboardingStart(state, action);
    case onboardingTypes.FETCH_ONBOARDING_SUCCESS:
      return fetchOnboardingSuccess(state, action);
    case onboardingTypes.FETCH_ONBOARDING_FAIL:
      return fetchOnboardingFail(state, action);
    case onboardingTypes.ADD_ONBOARDING_START:
      return addOnboardingStart(state, action);
    case onboardingTypes.ADD_ONBOARDING_SUCCESS:
      return addOnboardingSuccess(state, action);
    case onboardingTypes.ADD_ONBOARDING_FAIL:
      return addOnboardingFail(state, action);
    case onboardingTypes.DELETE_ONBOARDING_START:
      return deleteOnboardingStart(state, action);
    case onboardingTypes.DELETE_ONBOARDING_SUCCESS:
      return deleteOnboardingSuccess(state, action);
    case onboardingTypes.DELETE_ONBOARDING_FAIL:
      return deleteOnboardingFail(state, action);
    case onboardingTypes.GET_ONBOARDING_START:
      return getOnboardingStart(state, action);
    case onboardingTypes.GET_ONBOARDING_SUCCESS:
      return getOnboardingSuccess(state, action);
    case onboardingTypes.GET_ONBOARDING_FAIL:
      return getOnboardingFail(state, action);
    case onboardingTypes.UPDATE_ONBOARDING_START:
      return updateOnboardingStart(state, action);
    case onboardingTypes.UPDATE_ONBOARDING_SUCCESS:
      return updateOnboardingSuccess(state, action);
    case onboardingTypes.UPDATE_ONBOARDING_FAIL:
      return updateOnboardingFail(state, action);
    case onboardingTypes.ONBOARDING_DISMISS_ERROR:
      return onboardingDismissError(state);
    case onboardingTypes.ONBOARDING_ADD_FILTER:
      return onboardingAddFilter(state, action);
    case onboardingTypes.ONBOARDING_CLEAR_FILTER:
      return onboardingClearFilter(state);
    case onboardingTypes.ONBOARDING_ADD_PAGINATION:
      return onboardingAddPagination(state, action);
    case onboardingTypes.ONBOARDING_CLEAR_PAGINATION:
      return onboardingClearPagination(state);
    default:
      return state;
  }
};

export default onboardingReducer;
