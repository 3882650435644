import * as sharedTypes from './shared.types';
import { updateObject } from '../../../shared/utility';

const initialState = {
    search: '',
    fieldSearch: []
};

const sharedSearch = ( state, action ) => {
    return updateObject( state, { search: action.search } );
};

const clearSearch = ( state) => {
    return updateObject( state, { search: '', fieldSearch: [] } );
};

const updateFieldSearch = ( state, action) => {
    return updateObject( state, { fieldSearch: action.fieldSearch } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case sharedTypes.SHARED_SEARCH: return sharedSearch(state, action);
        case sharedTypes.SHARED_CLEAR_SEARCH: return clearSearch(state);
        case sharedTypes.SHARED_FIELD_SEARCH: return updateFieldSearch(state, action);
        default:
            return state;
    }
};

export default reducer;