import * as authTypes from "./auth.types";

export const authStart = () => {
  return {
    type: authTypes.AUTH_START
  };
};

export const authSuccess = (token, adminId, refreshToken, expirationDate, permissions, adminName) => {
  return {
    type: authTypes.AUTH_SUCCESS,
    token,
    adminId,
    refreshToken,
    expirationDate,
    permissions,
    adminName
  };
};

export const authFail = error => {
  return {
    type: authTypes.AUTH_FAIL,
    error: error
  };
};

export const authDismissError = error => {
  return {
    type: authTypes.AUTH_DISMISS_ERROR,
  };
};

export const logout = (history) => {
  return {
    type: authTypes.AUTH_INITIATE_LOGOUT,
    history: history
  };
};

export const logoutSucceed = () => {
  return {
    type: authTypes.AUTH_LOGOUT
  };
};

export const checkAuthTimeout = expirationTime => {
  return {
    type: authTypes.AUTH_CHECK_TIMEOUT,
    expirationTime: expirationTime
  };
};

export const auth = (email, password, isSignup) => {
  return {
    type: authTypes.AUTH_USER,
    email: email,
    password: password,
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: authTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return {
    type: authTypes.AUTH_CHECK_STATE
  };
};
