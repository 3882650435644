import * as adminTypes from "./admins.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  admins: {},
  admin: {},
  successDeteled: "",
  error: null,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  },
  loading: false
};

const fetchAdminsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchAdminsSuccess = (state, action) => {
  return updateObject(state, {
    admins: action.admins,
    admin: {},
    loading: false,
    error: null
  });
};

const fetchAdminsFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addAdminStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addAdminSuccess = (state, action) => {
  return updateObject(state, {
    admin: action.admin,
    loading: false,
    error: null
  });
};

const addAdminFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteAdminStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteAdminSuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deleteAdminFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getAdminStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getAdminSuccess = (state, action) => {
  return updateObject(state, {
    admin: action.admin,
    loading: false,
    error: null
  });
};

const getAdminFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateAdminStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateAdminSuccess = (state, action) => {
  return updateObject(state, {
    admin: action.admin,
    loading: false,
    error: null
  });
};

const updateAdminFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const adminDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const adminAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj
  });
};

const adminClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {}
  });
};

const adminAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination
  });
};

const adminClearPagination = (state) => {
  return updateObject(state, {
    pagination: {}
  });
};

const adminReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case adminTypes.FETCH_ADMINS_START:
      return fetchAdminsStart(state, action);
    case adminTypes.FETCH_ADMINS_SUCCESS:
      return fetchAdminsSuccess(state, action);
    case adminTypes.FETCH_ADMINS_FAIL:
      return fetchAdminsFail(state, action);
    case adminTypes.ADD_ADMIN_START:
      return addAdminStart(state, action);
    case adminTypes.ADD_ADMIN_SUCCESS:
      return addAdminSuccess(state, action);
    case adminTypes.ADD_ADMIN_FAIL:
      return addAdminFail(state, action);
    case adminTypes.DELETE_ADMIN_START:
      return deleteAdminStart(state, action);
    case adminTypes.DELETE_ADMIN_SUCCESS:
      return deleteAdminSuccess(state, action);
    case adminTypes.DELETE_ADMIN_FAIL:
      return deleteAdminFail(state, action);
    case adminTypes.GET_ADMIN_START:
      return getAdminStart(state, action);
    case adminTypes.GET_ADMIN_SUCCESS:
      return getAdminSuccess(state, action);
    case adminTypes.GET_ADMIN_FAIL:
      return getAdminFail(state, action);
    case adminTypes.UPDATE_ADMIN_START:
      return updateAdminStart(state, action);
    case adminTypes.UPDATE_ADMIN_SUCCESS:
      return updateAdminSuccess(state, action);
    case adminTypes.UPDATE_ADMIN_FAIL:
      return updateAdminFail(state, action);
    case adminTypes.ADMIN_DISMISS_ERROR:
      return adminDismissError(state);
    case adminTypes.ADMIN_ADD_FILTER:
      return adminAddFilter(state, action);
    case adminTypes.ADMIN_CLEAR_FILTER:
      return adminClearFilter(state);
    case adminTypes.ADMIN_ADD_PAGINATION:
      return adminAddPagination(state, action);
    case adminTypes.ADMIN_CLEAR_PAGINATION:
      return adminClearPagination(state);

    default:
      return state;
  }
};

export default adminReducer;
