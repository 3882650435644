import { put } from "redux-saga/effects";
import * as actions from "store/reducers/physical-activity/physical-activity.actions";
import physicalActivityService from "services/Physical-activity";
export function* fetchPhysicalActivitiesSaga(action) {
    yield put(actions.fetchPhysicalActivitiesStart());
    const service = yield new physicalActivityService();
    const physicalActivities = yield service.find(action.params);
    if(!physicalActivities.error){
      yield put(actions.fetchPhysicalActivitiesSuccess(physicalActivities));
    } else {
      yield put(actions.fetchPhysicalActivitiesFail(physicalActivities.error));  
    }
}

export function* addPhysicalActivitySaga(action) {
  yield put(actions.addPhysicalActivityStart());
  const service = yield new physicalActivityService();
  const physicalActivity = yield service.create(action.data);
  if(!physicalActivity.error){
    yield put(actions.addPhysicalActivitySuccess(physicalActivity));
    yield action.history.replace('/admin/physical-activity');
  } else {
    yield put(actions.addPhysicalActivityFail(physicalActivity.error));
  }
}

export function* deletePhysicalActivitySaga(action) {
  yield put(actions.deletePhysicalActivityStart());
  const service = yield new physicalActivityService();
  const physicalActivity = yield service.delete(action.id);
  if(!physicalActivity.error){
    yield put(actions.deletePhysicalActivitySuccess());
    if(action.history){
      action.history.replace('/admin/physical-activity')
    }
  } else {
    yield put(actions.deletePhysicalActivityFail(physicalActivity.error));
  }
}

export function* getPhysicalActivitySaga(action) {
  yield put(actions.getPhysicalActivityStart());
  const service = yield new physicalActivityService();
  const physicalActivity = yield service.get(action.id);
  if(!physicalActivity.error){
    yield put(actions.getPhysicalActivitySuccess(physicalActivity));
  } else {
    yield put(actions.getPhysicalActivityFail(physicalActivity.error));
  }
}

export function* updatePhysicalActivitySaga(action) {
  yield put(actions.updatePhysicalActivityStart());
  const service = yield new physicalActivityService();
  const physicalActivity = yield service.patch(action.data);
  if(!physicalActivity.error){
    yield put(actions.updatePhysicalActivitySuccess(physicalActivity));
    yield action.history.replace('/admin/physical-activity');
  } else {
    yield put(actions.updatePhysicalActivityFail(physicalActivity.error));
  }
}