import * as versioningTypes from 'store/reducers/versioning/versioning.types';
import { takeEvery } from 'redux-saga/effects';
import {
  addVersioningSaga,
  deleteVersioningSaga,
  fetchVersioningSaga,
  getVersioningSaga,
  updateVersioningSaga
} from './versioningSaga';

export const versioningSagas = {
  watchFetchVersioning: takeEvery(
    versioningTypes.FETCH_VERSIONING,
    fetchVersioningSaga
  ),
  watchGetVersioning: takeEvery(
    versioningTypes.GET_VERSIONING,
    getVersioningSaga
  ),
  watchAddVersioning: takeEvery(
    versioningTypes.ADD_VERSIONING,
    addVersioningSaga
  ),
  watchDeleteVersioning: takeEvery(
    versioningTypes.DELETE_VERSIONING,
    deleteVersioningSaga
  ),
  watchUpdateVersioning: takeEvery(
    versioningTypes.UPDATE_VERSIONING,
    updateVersioningSaga
  )
};
