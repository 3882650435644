import * as physicalActivityTypes from './physical-activity.types';

export const addPhysicalActivity = (data, history) => {
    return {
        type: physicalActivityTypes.ADD_PHYSICAL_ACTIVITY,
        data: data,
        history: history
    };
};

export const addPhysicalActivitySuccess = physicalActivity => {
    return {
        type: physicalActivityTypes.ADD_PHYSICAL_ACTIVITY_SUCCESS,
        physicalActivity: physicalActivity
    };
};

export const addPhysicalActivityFail = error => {
    return {
        type: physicalActivityTypes.ADD_PHYSICAL_ACTIVITY_FAIL,
        error: error
    };
};

export const addPhysicalActivityStart = () => {
    return {
        type: physicalActivityTypes.ADD_PHYSICAL_ACTIVITY_START
    };
};

export const updatePhysicalActivity = (data, history) => {
    return {
        type: physicalActivityTypes.UPDATE_PHYSICAL_ACTIVITY,
        data: data,
        history: history
    };
};

export const updatePhysicalActivitySuccess = physicalActivity => {
    return {
        type: physicalActivityTypes.UPDATE_PHYSICAL_ACTIVITY_SUCCESS,
        physicalActivity: physicalActivity
    };
};

export const updatePhysicalActivityFail = error => {
    return {
        type: physicalActivityTypes.UPDATE_PHYSICAL_ACTIVITY_FAIL,
        error: error
    };
};

export const updatePhysicalActivityStart = () => {
    return {
        type: physicalActivityTypes.UPDATE_PHYSICAL_ACTIVITY_START
    };
};

export const deletePhysicalActivity = (id, history) => {
    return {
        type: physicalActivityTypes.DELETE_PHYSICAL_ACTIVITY,
        id: id,
        history: history
    };
};

export const deletePhysicalActivitySuccess = () => {
    return {
        type: physicalActivityTypes.DELETE_PHYSICAL_ACTIVITY_SUCCESS,
    };
};

export const deletePhysicalActivityFail = error => {
    return {
        type: physicalActivityTypes.DELETE_PHYSICAL_ACTIVITY_FAIL,
        error: error
    };
};

export const deletePhysicalActivityStart = () => {
    return {
        type: physicalActivityTypes.DELETE_PHYSICAL_ACTIVITY_START
    };
};

export const getPhysicalActivity = (id) => {
    return {
        type: physicalActivityTypes.GET_PHYSICAL_ACTIVITY,
        id: id
    };
};

export const getPhysicalActivitySuccess = (physicalActivity) => {
    return {
        type: physicalActivityTypes.GET_PHYSICAL_ACTIVITY_SUCCESS,
        physicalActivity: physicalActivity
    };
};

export const getPhysicalActivityFail = error => {
    return {
        type: physicalActivityTypes.GET_PHYSICAL_ACTIVITY_FAIL,
        error: error
    };
};

export const getPhysicalActivityStart = () => {
    return {
        type: physicalActivityTypes.GET_PHYSICAL_ACTIVITY_START
    };
};

export const fetchPhysicalActivitiesSuccess = physicalActivities => {
    return {
        type: physicalActivityTypes.FETCH_PHYSICAL_ACTIVITIES_SUCCESS,
        physicalActivities: physicalActivities
    };
};

export const fetchPhysicalActivitiesFail = error => {
    return {
        type: physicalActivityTypes.FETCH_PHYSICAL_ACTIVITIES_FAIL,
        error: error
    };
};

export const fetchPhysicalActivitiesStart = () => {
    return {
        type: physicalActivityTypes.FETCH_PHYSICAL_ACTIVITIES_START
    };
};

export const fetchPhysicalActivities = (params) => {
    return {
        type: physicalActivityTypes.FETCH_PHYSICAL_ACTIVITIES,
        params: params
    };
};

// ====== UTILITY  ======= //
export const physicalActivityDismissError = () => {
    return {
        type: physicalActivityTypes.PHYSICAL_ACTIVITY_DISMISS_ERROR
    };
};

export const physicalActivityAddFilter = (filter, filterObj) => {
    return {
        type: physicalActivityTypes.PHYSICAL_ACTIVITY_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const physicalActivityClearFilter = () => {
    return {
        type: physicalActivityTypes.PHYSICAL_ACTIVITY_CLEAR_FILTER
    };
};

export const physicalActivityAddPagination = (pagination) => {
    return {
        type: physicalActivityTypes.PHYSICAL_ACTIVITY_ADD_PAGINATION,
        pagination: pagination
    };
};

export const physicalActivityClearPagination = () => {
    return {
        type: physicalActivityTypes.PHYSICAL_ACTIVITY_CLEAR_PAGINATION
    };
};
