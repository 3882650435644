export const ADD_FOOD_CATEGORY = 'ADD_FOOD_CATEGORY';
export const ADD_FOOD_CATEGORY_START = 'ADD_FOOD_CATEGORY_START';
export const ADD_FOOD_CATEGORY_SUCCESS = 'ADD_FOOD_CATEGORY_SUCCESS';
export const ADD_FOOD_CATEGORY_FAIL = 'ADD_FOOD_CATEGORY_FAIL';

export const DELETE_FOOD_CATEGORY = 'DELETE_FOOD_CATEGORY';
export const DELETE_FOOD_CATEGORY_START = 'DELETE_FOOD_CATEGORY_START';
export const DELETE_FOOD_CATEGORY_SUCCESS = 'DELETE_FOOD_CATEGORY_SUCCESS';
export const DELETE_FOOD_CATEGORY_FAIL = 'DELETE_FOOD_CATEGORY_FAIL';


export const UPDATE_FOOD_CATEGORY = 'UPDATE_FOOD_CATEGORY';
export const UPDATE_FOOD_CATEGORY_START = 'UPDATE_FOOD_CATEGORY_START';
export const UPDATE_FOOD_CATEGORY_SUCCESS = 'UPDATE_FOOD_CATEGORY_SUCCESS';
export const UPDATE_FOOD_CATEGORY_FAIL = 'UPDATE_FOOD_CATEGORY_FAIL';

export const GET_FOOD_CATEGORY = 'GET_FOOD_CATEGORY';
export const GET_FOOD_CATEGORY_START = 'GET_FOOD_CATEGORY_START';
export const GET_FOOD_CATEGORY_SUCCESS = 'GET_FOOD_CATEGORY_SUCCESS';
export const GET_FOOD_CATEGORY_FAIL = 'GET_FOOD_CATEGORY_FAIL';

export const FETCH_FOOD_CATEGORY = 'FETCH_FOOD_CATEGORY';
export const FETCH_FOOD_CATEGORY_START = 'FETCH_FOOD_CATEGORY_START';
export const FETCH_FOOD_CATEGORY_SUCCESS = 'FETCH_FOOD_CATEGORY_SUCCESS';
export const FETCH_FOOD_CATEGORY_FAIL = 'FETCH_FOOD_CATEGORY_FAIL';

// ====== UTILITY  ======= //
export const FOOD_CATEGORY_DISMISS_ERROR = 'FOOD_CATEGORY_DISMISS_ERROR';
export const FOOD_CATEGORY_ADD_FILTER = 'FOOD_CATEGORY_ADD_FILTER';
export const FOOD_CATEGORY_CLEAR_FILTER = 'FOOD_CATEGORY_CLEAR_FILTER';
export const FOOD_CATEGORY_ADD_PAGINATION = 'FOOD_CATEGORY_ADD_PAGINATION';
export const FOOD_CATEGORY_CLEAR_PAGINATION = 'FOOD_CATEGORY_CLEAR_PAGINATION';