export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_START = 'ADD_ADMIN_START';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAIL = 'ADD_ADMIN_FAIL';

export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_START = 'DELETE_ADMIN_START';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAIL = 'DELETE_ADMIN_FAIL';


export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_ADMIN_START = 'UPDATE_ADMIN_START';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAIL = 'UPDATE_ADMIN_FAIL';

export const GET_ADMIN = 'GET_ADMIN';
export const GET_ADMIN_START = 'GET_ADMIN_START';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAIL = 'GET_ADMIN_FAIL';

export const FETCH_ADMINS = 'FETCH_ADMINS';
export const FETCH_ADMINS_START = 'FETCH_ADMINS_START';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_FAIL = 'FETCH_ADMINS_FAIL';

// ====== UTILITY  ======= //
export const ADMIN_DISMISS_ERROR = 'ADMIN_DISMISS_ERROR';
export const ADMIN_ADD_FILTER = 'ADMIN_ADD_FILTER';
export const ADMIN_CLEAR_FILTER = 'ADMIN_CLEAR_FILTER';
export const ADMIN_ADD_PAGINATION = 'ADMIN_ADD_PAGINATION';
export const ADMIN_CLEAR_PAGINATION = 'ADMIN_CLEAR_PAGINATION';