import * as faqTypes from './faq.types';

export const addFaq = (data, history) => {
    return {
        type: faqTypes.ADD_FAQ,
        data: data,
        history: history
    };
};

export const addFaqSuccess = faq => {
    return {
        type: faqTypes.ADD_FAQ_SUCCESS,
        faq: faq
    };
};

export const addFaqFail = error => {
    return {
        type: faqTypes.ADD_FAQ_FAIL,
        error: error
    };
};

export const addFaqStart = () => {
    return {
        type: faqTypes.ADD_FAQ_START
    };
};

export const faqDismissError = () => {
    return {
        type: faqTypes.FAQ_DISMISS_ERROR
    };
};

export const updateFaq = (data, history) => {
    return {
        type: faqTypes.UPDATE_FAQ,
        data: data,
        history: history
    };
};

export const updateFaqSuccess = faq => {
    return {
        type: faqTypes.UPDATE_FAQ_SUCCESS,
        faq: faq
    };
};

export const updateFaqFail = error => {
    return {
        type: faqTypes.UPDATE_FAQ_FAIL,
        error: error
    };
};

export const updateFaqStart = () => {
    return {
        type: faqTypes.UPDATE_FAQ_START
    };
};

export const deleteFaq = (id, history) => {
    return {
        type: faqTypes.DELETE_FAQ,
        id: id,
        history: history
    };
};

export const deleteFaqSuccess = () => {
    return {
        type: faqTypes.DELETE_FAQ_SUCCESS,
    };
};

export const deleteFaqFail = error => {
    return {
        type: faqTypes.DELETE_FAQ_FAIL,
        error: error
    };
};

export const deleteFaqStart = () => {
    return {
        type: faqTypes.DELETE_FAQ_START
    };
};

export const getFaq = (id) => {
    return {
        type: faqTypes.GET_FAQ,
        id: id
    };
};

export const getFaqSuccess = (faq) => {
    return {
        type: faqTypes.GET_FAQ_SUCCESS,
        faq: faq
    };
};

export const getFaqFail = error => {
    return {
        type: faqTypes.GET_FAQ_FAIL,
        error: error
    };
};

export const getFaqStart = () => {
    return {
        type: faqTypes.GET_FAQ_START
    };
};

export const fetchFaqSuccess = faqs => {
    return {
        type: faqTypes.FETCH_FAQ_SUCCESS,
        faqs: faqs
    };
};

export const fetchFaqFail = error => {
    return {
        type: faqTypes.FETCH_FAQ_FAIL,
        error: error
    };
};

export const fetchFaqStart = () => {
    return {
        type: faqTypes.FETCH_FAQ_START
    };
};

export const fetchFaq = (params) => {
    return {
        type: faqTypes.FETCH_FAQ,
        params: params
    };
};
