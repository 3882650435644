import * as articleTypes from "./article.types";

/**
 * FETCH ARTICLES
 */
export const fetchArticles = (params) => ({
  type: articleTypes.FETCH_ARTICLES,
  params
});
export const fetchArticlesStart = () => ({
  type: articleTypes.FETCH_ARTICLES_START
});
export const fetchArticlesSuccess = (articles) => ({
  type: articleTypes.FETCH_ARTICLES_SUCCESS,
  articles
});
export const fetchArticlesFail = (error) => ({
  type: articleTypes.FETCH_ARTICLES_FAIL,
  error
});

/**
 * CREATE ARTICLE
 */
export const addArticle = (history, data, imgUploadPayload) => ({
  type: articleTypes.ADD_ARTICLE,
  history,
  data,
  imgUploadPayload
});
export const addArticleStart = () => ({
  type: articleTypes.ADD_ARTICLE_START
});
export const addArticleSuccess = (article) => ({
  type: articleTypes.ADD_ARTICLE_SUCCESS,
  article
});
export const addArticleFail = (error) => ({
  type: articleTypes.ADD_ARTICLE_FAIL,
  error
});

/**
 * GET ARTICLE
 */
export const getArticle = (id) => ({
  type: articleTypes.GET_ARTICLE,
  id
});
export const getArticleStart = () => ({
  type: articleTypes.GET_ARTICLE_START
});
export const getArticleSuccess = (article) => ({
  type: articleTypes.GET_ARTICLE_SUCCESS,
  article
});
export const getArticleFail = (error) => ({
  type: articleTypes.GET_ARTICLE_FAIL,
  error
});

/**
 * UPDATE ARTICLE
 */
export const updateArticle = (history, data, imgUploadPayload) => ({
  type: articleTypes.UPDATE_ARTICLE,
  history,
  data,
  imgUploadPayload
});
export const updateArticleStart = () => ({
  type: articleTypes.UPDATE_ARTICLE_START
});
export const updateArticleSuccess = (article) => ({
  type: articleTypes.UPDATE_ARTICLE_SUCCESS,
  article
});
export const updateArticleFail = (error) => ({
  type: articleTypes.UPDATE_ARTICLE_FAIL,
  error
});

/**
 * DELETE ARTICLE
 */
export const deleteArticle = (id, history) => ({
  type: articleTypes.DELETE_ARTICLE,
  id,
  history
});
export const deleteArticleStart = () => ({
  type: articleTypes.DELETE_ARTICLE_START
});
export const deleteArticleSuccess = () => ({
  type: articleTypes.DELETE_ARTICLE_SUCCESS
});

export const deleteArticleFail = (error) => ({
  type: articleTypes.DELETE_ARTICLE_FAIL,
  error
});

// ====== UTILITY  ======= //
export const articleDismissError = () => {
  return {
      type: articleTypes.ARTICLE_DISMISS_ERROR
  };
};

export const articleAddFilter = (filter, filterObj) => {
  return {
      type: articleTypes.ARTICLE_ADD_FILTER,
      filter: filter,
      filterObj: filterObj
  };
};

export const articleClearFilter = () => {
  return {
      type: articleTypes.ARTICLE_CLEAR_FILTER
  };
};

export const articleAddPagination = (pagination) => {
  return {
      type: articleTypes.ARTICLE_ADD_PAGINATION,
      pagination: pagination
  };
};

export const articleClearPagination = () => {
  return {
      type: articleTypes.ARTICLE_CLEAR_PAGINATION
  };
};
