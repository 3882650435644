import { put } from "redux-saga/effects";
import * as actions from "../../reducers/page-information/page-information.actions";
import pageInformationService from "../../../services/PageInformation";
import uploadService from "../../../services/Upload";

/**
 * FETCH PAGE_INFORMATIONS
 */
export function* fetchPageInformationsSaga(action) {
  yield put(actions.fetchPageInformationsStart());
  const service = yield new pageInformationService();

  const pageInformations = yield service.find(action.params);
  if (pageInformations.error) {
    yield put(actions.fetchPageInformationsFail(pageInformations.error));
    return;
  }
  yield put(actions.fetchPageInformationsSuccess(pageInformations));
}

/**
 * GET PAGE_INFORMATION
 */
export function* getPageInformationSaga(action) {
  yield put(actions.getPageInformationStart());
  const service = yield new pageInformationService();

  const pageInformation = yield service.get(action.id);
  if (pageInformation.error) {
    yield put(actions.getPageInformationFail(pageInformation.error));
    return;
  }
  yield put(actions.getPageInformationSuccess(pageInformation));
}

/**
 * CREATE PAGE_INFORMATION
 */
export function* addPageInformationSaga(action) {
  yield put(actions.addPageInformationStart());

  const service = yield new pageInformationService();
  const pageInformation = yield service.create(action.data);
  if (pageInformation.error) {
    yield put(actions.addPageInformationFail(pageInformation.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * UPDATE PAGE_INFORMATION
 */
export function* updatePageInformationSaga(action) {
  yield put(actions.updatePageInformationStart());
  let upload = {};

  if (action.imgUploadPayload) {
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.addPageInformationFail(upload.error));
      return;
    }
  }

  const service = yield new pageInformationService();
  action.data.coverImage = action.imgUploadPayload ? upload.data.url : null;
  const pageInformation = yield service.patch(action.data);
  if (pageInformation.error) {
    yield put(actions.updatePageInformationFail(pageInformation.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * DELETE PAGE_INFORMATION
 */
export function* deletePageInformationSaga(action) {
  yield put(actions.deletePageInformationStart());
  const service = yield new pageInformationService();
  const pageInformation = yield service.delete(action.id);
  if (pageInformation.error) {
    yield put(actions.deletePageInformationFail(pageInformation.error));
    return;
  }
  yield put(actions.deletePageInformationSuccess());
  if (action.history) {
    action.history.goBack();
  }
}
