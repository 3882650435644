import { put } from "redux-saga/effects";
import * as actions from "store/reducers/top-food/topFood.actions";
import TopFoodService from "../../../services/TopFood";

/**
 * FETCH TOP FOOD
 */
export function* fetchTopFoodSaga(action) {
  yield put(actions.fetchTopFoodStart());

  try {
    const service = yield new TopFoodService();
    const topFood = yield service.find(action.params);

    yield put(actions.fetchTopFoodSuccess(topFood));
  } catch (error) {
    yield put(actions.fetchTopFoodFail(error));
  }
}
