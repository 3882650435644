import { put } from "redux-saga/effects";
import * as actions from "store/reducers/food-category/food-category.actions";
import foodCategoryService from "services/FoodCategory";
export function* fetchFoodCategorySaga(action) {
    yield put(actions.fetchFoodCategoryStart());
    const service = yield new foodCategoryService();
    const foodCategory = yield service.find(action.params);
    if(!foodCategory.error){
      yield put(actions.fetchFoodCategorySuccess(foodCategory));
    } else {
      yield put(actions.fetchFoodCategoryFail(foodCategory.error));  
    }
}

export function* addFoodCategorySaga(action) {
  yield put(actions.addFoodCategoryStart());
  const service = yield new foodCategoryService();
  const foodCategory = yield service.create(action.data);
  if(!foodCategory.error){
    yield action.history.replace('/admin/food-category');
  } else {
    yield put(actions.addFoodCategoryFail(foodCategory.error));
  }
}

export function* deleteFoodCategorySaga(action) {
  yield put(actions.deleteFoodCategoryStart());
  const service = yield new foodCategoryService();
  const foodCategory = yield service.delete(action.id);
  if(!foodCategory.error){
    yield put(actions.deleteFoodCategorySuccess());
    if(action.history){
      action.history.replace('/admin/food-category')
    }
  } else {
    yield put(actions.deleteFoodCategoryFail(foodCategory.error));
  }
}

export function* getFoodCategorySaga(action) {
  yield put(actions.getFoodCategoryStart());
  const service = yield new foodCategoryService();
  const foodCategory = yield service.get(action.id);
  if(!foodCategory.error){
    yield put(actions.getFoodCategorySuccess(foodCategory));
  } else {
    yield put(actions.getFoodCategoryFail(foodCategory.error));
  }
}

export function* updateFoodCategorySaga(action) {
  yield put(actions.updateFoodCategoryStart());
  const service = yield new foodCategoryService();
  const foodCategory = yield service.patch(action.data);
  if(!foodCategory.error){
    yield action.history.replace('/admin/food-category');
  } else {
    yield put(actions.updateFoodCategoryFail(foodCategory.error));
  }
}