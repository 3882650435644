import * as medicineTypes from "./medicine.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  medicines: {},
  medicine: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1,
  },
};

/**
 * FETCH MEDICINES
 */
const fetchMedicinesStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchMedicinesSuccess = (state, action) =>
  updateObject(state, {
    medicines: action.medicines,
    medicine: {},
    loading: false,
    error: null
  });
const fetchMedicinesFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * CREATE MEDICINE
 */
const addMedicineStart = (state, action) =>
  updateObject(state, { loading: true });
const addMedicineSuccess = (state, action) =>
  updateObject(state, {
    medicine: action.medicine,
    loading: false,
    error: null
  });
const addMedicineFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * GET MEDICINE
 */
const getMedicineStart = (state, action) =>
  updateObject(state, { loading: true });
const getMedicineSuccess = (state, action) =>
  updateObject(state, {
    medicine: action.medicine,
    loading: false,
    error: null
  });
const getMedicineFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPDATE MEDICINE
 */
const updateMedicineStart = (state, action) =>
  updateObject(state, { loading: true });
const updateMedicineSuccess = (state, action) =>
  updateObject(state, {
    medicine: action.medicine,
    loading: false,
    error: null
  });
const updateMedicineFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * DELETE MEDICINE
 */
const deleteMedicineStart = (state, action) =>
  updateObject(state, { loading: true });
const deleteMedicineSuccess = (state, action) =>
  updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
const deleteMedicineFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

// ====== UTILITY  ======= //

const medicineDismissError = (state) => {
  return updateObject(state, {
    error: null,
  });
};

const medicineAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj,
  });
};

const medicineClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {},
  });
};

const medicineAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination,
  });
};

const medicineClearPagination = (state) => {
  return updateObject(state, {
    pagination: {},
  });
};

const medicineReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case medicineTypes.FETCH_MEDICINES_START:
      return fetchMedicinesStart(state, action);
    case medicineTypes.FETCH_MEDICINES_SUCCESS:
      return fetchMedicinesSuccess(state, action);
    case medicineTypes.FETCH_MEDICINES_FAIL:
      return fetchMedicinesFail(state, action);

    case medicineTypes.ADD_MEDICINE_START:
      return addMedicineStart(state, action);
    case medicineTypes.ADD_MEDICINE_SUCCESS:
      return addMedicineSuccess(state, action);
    case medicineTypes.ADD_MEDICINE_FAIL:
      return addMedicineFail(state, action);

    case medicineTypes.GET_MEDICINE_START:
      return getMedicineStart(state, action);
    case medicineTypes.GET_MEDICINE_SUCCESS:
      return getMedicineSuccess(state, action);
    case medicineTypes.GET_MEDICINE_FAIL:
      return getMedicineFail(state, action);

    case medicineTypes.UPDATE_MEDICINE_START:
      return updateMedicineStart(state, action);
    case medicineTypes.UPDATE_MEDICINE_SUCCESS:
      return updateMedicineSuccess(state, action);
    case medicineTypes.UPDATE_MEDICINE_FAIL:
      return updateMedicineFail(state, action);

    case medicineTypes.DELETE_MEDICINE_START:
      return deleteMedicineStart(state, action);
    case medicineTypes.DELETE_MEDICINE_SUCCESS:
      return deleteMedicineSuccess(state, action);
    case medicineTypes.DELETE_MEDICINE_FAIL:
      return deleteMedicineFail(state, action);

    case medicineTypes.MEDICINE_DISMISS_ERROR:
      return medicineDismissError(state);
    case medicineTypes.MEDICINE_ADD_FILTER:
      return medicineAddFilter(state, action);
    case medicineTypes.MEDICINE_CLEAR_FILTER:
      return medicineClearFilter(state);
    case medicineTypes.MEDICINE_ADD_PAGINATION:
      return medicineAddPagination(state, action);
    case medicineTypes.MEDICINE_CLEAR_PAGINATION:
      return medicineClearPagination(state);
    default:
      return state;
  }
};

export default medicineReducer;
