import * as waterConsumptionTypes from "./waterConsumption.types";

/**
 * FETCH WATER_CONSUMPTION
 */
export const fetchWaterConsumptionsSuccess = (waterConsumptions) => ({
  type: waterConsumptionTypes.FETCH_WATER_CONSUMPTIONS_SUCCESS,
  waterConsumptions
});
export const fetchWaterConsumptionsFail = (error) => ({
  type: waterConsumptionTypes.FETCH_WATER_CONSUMPTIONS_FAIL,
  error
});
export const fetchWaterConsumptionsStart = () => ({
  type: waterConsumptionTypes.FETCH_WATER_CONSUMPTIONS_START
});
export const fetchWaterConsumptions = (params) => ({
  type: waterConsumptionTypes.FETCH_WATER_CONSUMPTIONS,
  params
});

/**
 * GET WATER_CONSUMPTION
 */
export const getWaterConsumption = (id) => ({
  type: waterConsumptionTypes.GET_WATER_CONSUMPTION,
  id
});
export const getWaterConsumptionStart = () => ({
  type: waterConsumptionTypes.GET_WATER_CONSUMPTION_START
});
export const getWaterConsumptionSuccess = (waterConsumption) => ({
  type: waterConsumptionTypes.GET_WATER_CONSUMPTION_SUCCESS,
  waterConsumption
});
export const getWaterConsumptionFail = (error) => ({
  type: waterConsumptionTypes.GET_WATER_CONSUMPTION_FAIL,
  error
});

/**
 * ADD WATER_CONSUMPTION
 */
export const addWaterConsumption = (history, imgUpload, waterConsumptionData) => ({
  type: waterConsumptionTypes.ADD_WATER_CONSUMPTION,
  history,
  imgUpload,
  waterConsumptionData
});
export const addWaterConsumptionStart = () => ({
  type: waterConsumptionTypes.ADD_WATER_CONSUMPTION_START
});
export const addWaterConsumptionSuccess = (waterConsumption) => ({
  type: waterConsumptionTypes.ADD_WATER_CONSUMPTION_SUCCESS,
  waterConsumption
});
export const addWaterConsumptionFail = (error) => ({
  type: waterConsumptionTypes.ADD_WATER_CONSUMPTION_FAIL,
  error
});

/**
 * UPDATE WATER_CONSUMPTION
 */
export const updateWaterConsumption = (history, imgUpload, waterConsumptionData) => ({
  type: waterConsumptionTypes.UPDATE_WATER_CONSUMPTION,
  history,
  imgUpload,
  waterConsumptionData
});
export const updateWaterConsumptionStart = () => ({
  type: waterConsumptionTypes.UPDATE_WATER_CONSUMPTION_START
});
export const updateWaterConsumptionSuccess = (waterConsumption) => ({
  type: waterConsumptionTypes.UPDATE_WATER_CONSUMPTION_SUCCESS,
  waterConsumption
});
export const updateWaterConsumptionFail = (error) => ({
  type: waterConsumptionTypes.UPDATE_WATER_CONSUMPTION_FAIL,
  error
});

/**
 * DELETE WATER_CONSUMPTION
 */
export const deleteWaterConsumption = (id, history) => ({
  type: waterConsumptionTypes.DELETE_WATER_CONSUMPTION,
  id,
  history
});
export const deleteWaterConsumptionStart = () => ({
  type: waterConsumptionTypes.DELETE_WATER_CONSUMPTION_START
});
export const deleteWaterConsumptionSuccess = () => ({
  type: waterConsumptionTypes.DELETE_WATER_CONSUMPTION_SUCCESS
});
export const deleteWaterConsumptionFail = () => ({
  type: waterConsumptionTypes.DELETE_WATER_CONSUMPTION_FAIL
});

/**
 * UPLOAD WATER_CONSUMPTION PICTURE
 */
export const upload = () => ({
  type: waterConsumptionTypes.UPLOAD_WATER_CONSUMPTION_PICTURE
});
export const uploadStart = () => ({
  type: waterConsumptionTypes.UPLOAD_WATER_CONSUMPTION_PICTURE_START
});
export const uploadSuccess = (url) => ({
  type: waterConsumptionTypes.UPLOAD_WATER_CONSUMPTION_PICTURE_SUCCESS,
  url
});
export const uploadFail = () => ({
  type: waterConsumptionTypes.UPLOAD_WATER_CONSUMPTION_PICTURE_FAIL
});
