import { put } from 'redux-saga/effects';
import * as actions from '../../reducers/pinned-contents/pinned-content.action';
import PinnedContentService from '../../../services/PinnedContent';

/**
 * FETCH CONTENTS
 */
export function* fetchPinnedContentsSaga(action) {
  yield put(actions.fetchPinnedContentsStart());
  const service = yield new PinnedContentService();

  const contents = yield service.find(action.params);
  if (contents.error) {
    yield put(actions.fetchPinnedContentsFail(contents.error));
    return;
  }
  yield put(actions.fetchPinnedContentsSuccess(contents));
}

/**
 * GET CONTENT
 */
export function* getPinnedContentSaga(action) {
  yield put(actions.getPinnedContentStart());
  const service = yield new PinnedContentService();

  const content = yield service.get(action.id);
  if (content.error) {
    yield put(actions.getPinnedContentFail(content.error));
    return;
  }
  yield put(actions.getPinnedContentSuccess(content));
}

/**
 * CREATE CONTENT
 */
export function* addPinnedContentSaga(action) {
  yield put(actions.addPinnedContentStart());

  const service = yield new PinnedContentService();
  const content = yield service.create(action.data);
  if (content.error) {
    yield put(actions.addPinnedContentFail(content.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * UPDATE CONTENT
 */
export function* updatePinnedContentSaga(action) {
  yield put(actions.updatePinnedContentStart());

  const service = yield new PinnedContentService();
  const content = yield service.patch(action.data);
  if (content.error) {
    yield put(actions.updatePinnedContentFail(content.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * DELETE CONTENT
 */
export function* deletePinnedContentSaga(action) {
  yield put(actions.deletePinnedContentStart());

  const service = yield new PinnedContentService();
  const content = yield service.delete(action.id);
  if (content.error) {
    yield put(actions.deletePinnedContentFail(content.error));
    return;
  }
  yield put(actions.deletePinnedContentSuccess());
  if (action.history) {
    action.history.goBack();
  }
}
