import * as contentTypes from "store/reducers/content/content.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchContentsSaga,
  addContentSaga,
  deleteContentSaga,
  getContentSaga,
  updateContentSaga
} from "./content";

export const contentSagas = {
  watchFetchContents: takeEvery(contentTypes.FETCH_CONTENTS, fetchContentsSaga),
  watchAddContent: takeEvery(contentTypes.ADD_CONTENT, addContentSaga),
  watchDeleteContent: takeEvery(contentTypes.DELETE_CONTENT, deleteContentSaga),
  watchGetContent: takeEvery(contentTypes.GET_CONTENT, getContentSaga),
  watchUpdateContent: takeEvery(contentTypes.UPDATE_CONTENT, updateContentSaga)
}