import { put } from "redux-saga/effects";
import * as actions from "../../reducers/article/article.actions";
import articleService from "../../../services/Article";
import uploadService from "../../../services/Upload";

/**
 * FETCH ARTICLES
 */
export function* fetchArticlesSaga(action) {
  yield put(actions.fetchArticlesStart());
  const service = yield new articleService();
  const articles = yield service.find(action.params);
  if (articles.error) {
    yield put(actions.fetchArticlesFail(articles.error));
    return;
  }
  yield put(actions.fetchArticlesSuccess(articles));
}

/**
 * CREATE ARTICLE
 */
export function* addArticleSaga(action) {
  yield put(actions.addArticleStart());
  const upService = yield new uploadService();
  let img = {};
  if (action.imgUploadPayload) {
    img = yield upService.upload(action.imgUploadPayload);
    if (img.error) {
      yield put(actions.addArticleFail(img.error));
      return;
    }
  }

  const service = yield new articleService();
  action.data.articleImage = {
    url: action.imgUploadPayload ? img.data.url : null,
    type: "image"
  };
  action.data.youtubeURL = {
    url: action.data.youtubeURL,
    type: "video"
  };

  const article = yield service.create(action.data);
  if (article.error) {
    yield put(actions.addArticleFail(article.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * GET ARTICLE
 */
export function* getArticleSaga(action) {
  yield put(actions.getArticleStart());
  const service = yield new articleService();
  const article = yield service.get(action.id);
  if (article.error) {
    yield put(actions.getArticleFail(article.error));
    return;
  }

  yield put(actions.getArticleSuccess(article));
}

/**
 * UPDATE ARTICLE
 */
export function* updateArticleSaga(action) {
  yield put(actions.updateArticleStart());
  let upload = {};

  if (action.imgUploadPayload) {
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.addArticleFail(upload.error));
      return;
    }
  }

  const service = yield new articleService();
  action.data.articleImage = action.imgUploadPayload ? upload.data.url : null;
  const article = yield service.patch(action.data);
  if (article.error) {
    yield put(actions.updateArticleFail(article.error));
    return;
  }
  yield action.history.goBack();
}

/**
 * DELETE ARTICLE
 */
export function* deleteArticleSaga(action) {
  yield put(actions.deleteArticleStart());
  const service = yield new articleService();
  const article = yield service.delete(action.id);
  if (article.error) {
    yield put(actions.deleteArticleFail(article.error));
    return;
  }
  yield put(actions.deleteArticleSuccess());
  if (action.history) {
    action.history.goBack();
  }
}
