import * as TopFoodTypes from './topFood.types';

/**
 * FETCH TOP FOOD
 */
export const fetchTopFood = (params) => ({
  type: TopFoodTypes.FETCH_TOP_FOOD,
  params
});
export const fetchTopFoodSuccess = (topFood) => ({
  type: TopFoodTypes.FETCH_TOP_FOOD_SUCCESS,
  topFood
});
export const fetchTopFoodFail = (error) => ({
  type: TopFoodTypes.FETCH_TOP_FOOD_FAIL,
  error
});
export const fetchTopFoodStart = () => ({
  type: TopFoodTypes.FETCH_TOP_FOOD_START
});
