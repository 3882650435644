import * as foodUnitTypes from './food-unit.types';

export const addFoodUnit = (data, history) => {
    return {
        type: foodUnitTypes.ADD_FOOD_UNIT,
        data: data,
        history: history
    };
};

export const addFoodUnitSuccess = foodUnit => {
    return {
        type: foodUnitTypes.ADD_FOOD_UNIT_SUCCESS,
        foodUnit: foodUnit
    };
};

export const addFoodUnitFail = error => {
    return {
        type: foodUnitTypes.ADD_FOOD_UNIT_FAIL,
        error: error
    };
};

export const addFoodUnitStart = () => {
    return {
        type: foodUnitTypes.ADD_FOOD_UNIT_START
    };
};

export const updateFoodUnit = (data, history) => {
    return {
        type: foodUnitTypes.UPDATE_FOOD_UNIT,
        data: data,
        history: history
    };
};

export const updateFoodUnitSuccess = foodUnit => {
    return {
        type: foodUnitTypes.UPDATE_FOOD_UNIT_SUCCESS,
        foodUnit: foodUnit
    };
};

export const updateFoodUnitFail = error => {
    return {
        type: foodUnitTypes.UPDATE_FOOD_UNIT_FAIL,
        error: error
    };
};

export const updateFoodUnitStart = () => {
    return {
        type: foodUnitTypes.UPDATE_FOOD_UNIT_START
    };
};

export const deleteFoodUnit = (id, history) => {
    return {
        type: foodUnitTypes.DELETE_FOOD_UNIT,
        id: id,
        history: history
    };
};

export const deleteFoodUnitSuccess = () => {
    return {
        type: foodUnitTypes.DELETE_FOOD_UNIT_SUCCESS,
    };
};

export const deleteFoodUnitFail = error => {
    return {
        type: foodUnitTypes.DELETE_FOOD_UNIT_FAIL,
        error: error
    };
};

export const deleteFoodUnitStart = () => {
    return {
        type: foodUnitTypes.DELETE_FOOD_UNIT_START
    };
};

export const getFoodUnit = (id) => {
    return {
        type: foodUnitTypes.GET_FOOD_UNIT,
        id: id
    };
};

export const getFoodUnitSuccess = (foodUnit) => {
    return {
        type: foodUnitTypes.GET_FOOD_UNIT_SUCCESS,
        foodUnit: foodUnit
    };
};

export const getFoodUnitFail = error => {
    return {
        type: foodUnitTypes.GET_FOOD_UNIT_FAIL,
        error: error
    };
};

export const getFoodUnitStart = () => {
    return {
        type: foodUnitTypes.GET_FOOD_UNIT_START
    };
};

export const fetchFoodUnitSuccess = foodUnits => {
    return {
        type: foodUnitTypes.FETCH_FOOD_UNIT_SUCCESS,
        foodUnits: foodUnits
    };
};

export const fetchFoodUnitFail = error => {
    return {
        type: foodUnitTypes.FETCH_FOOD_UNIT_FAIL,
        error: error
    };
};

export const fetchFoodUnitStart = () => {
    return {
        type: foodUnitTypes.FETCH_FOOD_UNIT_START
    };
};

export const fetchFoodUnit = (params) => {
    return {
        type: foodUnitTypes.FETCH_FOOD_UNIT,
        params: params
    };
};

// ====== UTILITY  ======= //
export const foodUnitDismissError = () => {
    return {
        type: foodUnitTypes.FOOD_UNIT_DISMISS_ERROR
    };
};

export const foodUnitAddFilter = (filter, filterObj) => {
    return {
        type: foodUnitTypes.FOOD_UNIT_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const foodUnitClearFilter = () => {
    return {
        type: foodUnitTypes.FOOD_UNIT_CLEAR_FILTER
    };
};

export const foodUnitAddPagination = (pagination) => {
    return {
        type: foodUnitTypes.FOOD_UNIT_ADD_PAGINATION,
        pagination: pagination
    };
};

export const foodUnitClearPagination = () => {
    return {
        type: foodUnitTypes.FOOD_UNIT_CLEAR_PAGINATION
    };
};
