export const ADD_FOOD_RECIPE = "ADD_FOOD_RECIPE";
export const ADD_FOOD_RECIPE_START = "ADD_FOOD_RECIPE_START";
export const ADD_FOOD_RECIPE_SUCCESS = "ADD_FOOD_RECIPE_SUCCESS";
export const ADD_FOOD_RECIPE_FAIL = "ADD_FOOD_RECIPE_FAIL";

export const DELETE_FOOD_RECIPE = "DELETE_FOOD_RECIPE";
export const DELETE_FOOD_RECIPE_START = "DELETE_FOOD_RECIPE_START";
export const DELETE_FOOD_RECIPE_SUCCESS = "DELETE_FOOD_RECIPE_SUCCESS";
export const DELETE_FOOD_RECIPE_FAIL = "DELETE_FOOD_RECIPE_FAIL";

export const UPDATE_FOOD_RECIPE = "UPDATE_FOOD_RECIPE";
export const UPDATE_FOOD_RECIPE_START = "UPDATE_FOOD_RECIPE_START";
export const UPDATE_FOOD_RECIPE_SUCCESS = "UPDATE_FOOD_RECIPE_SUCCESS";
export const UPDATE_FOOD_RECIPE_FAIL = "UPDATE_FOOD_RECIPE_FAIL";

export const GET_FOOD_RECIPE = "GET_FOOD_RECIPE";
export const GET_FOOD_RECIPE_START = "GET_FOOD_RECIPE_START";
export const GET_FOOD_RECIPE_SUCCESS = "GET_FOOD_RECIPE_SUCCESS";
export const GET_FOOD_RECIPE_FAIL = "GET_FOOD_RECIPE_FAIL";

export const FETCH_FOOD_RECIPES = "FETCH_FOOD_RECIPES";
export const FETCH_FOOD_RECIPES_START = "FETCH_FOOD_RECIPES_START";
export const FETCH_FOOD_RECIPES_SUCCESS = "FETCH_FOOD_RECIPES_SUCCESS";
export const FETCH_FOOD_RECIPES_FAIL = "FETCH_FOOD_RECIPES_FAIL";

// ====== UTILITY  ======= //
export const FOOD_RECIPE_DISMISS_ERROR = 'FOOD_RECIPE_DISMISS_ERROR';
export const FOOD_RECIPE_ADD_FILTER = 'FOOD_RECIPE_ADD_FILTER';
export const FOOD_RECIPE_CLEAR_FILTER = 'FOOD_RECIPE_CLEAR_FILTER';
export const FOOD_RECIPE_ADD_PAGINATION = 'FOOD_RECIPE_ADD_PAGINATION';
export const FOOD_RECIPE_CLEAR_PAGINATION = 'FOOD_RECIPE_CLEAR_PAGINATION';
