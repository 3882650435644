import * as GlucoseTypes from "./Glucose.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  glucose: null,
  url: "",
  loading: false
};

/**
 * FETCH HBA1C
 */
const fetchGlucoseStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchGlucoseSuccess = (state, action) => {
  return updateObject(state, {
    glucose: action.glucose,
    loading: false
  });
};
const fetchGlucoseFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const GlucoseReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GlucoseTypes.FETCH_GLUCOSE_START:
      return fetchGlucoseStart(state, action);
    case GlucoseTypes.FETCH_GLUCOSE_SUCCESS:
      return fetchGlucoseSuccess(state, action);
    case GlucoseTypes.FETCH_GLUCOSE_FAIL:
      return fetchGlucoseFail(state, action);

    default:
      return state;
  }
};

export default GlucoseReducer;
