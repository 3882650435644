import * as onboardingTypes from './onboarding.types';

export const addOnboarding = (data, imgUploadPayload, history) => {
    return {
        type: onboardingTypes.ADD_ONBOARDING,
        data: data,
        imgUploadPayload: imgUploadPayload,
        history: history
    };
};

export const addOnboardingSuccess = onboarding => {
    return {
        type: onboardingTypes.ADD_ONBOARDING_SUCCESS,
        onboarding: onboarding
    };
};

export const addOnboardingFail = error => {
    return {
        type: onboardingTypes.ADD_ONBOARDING_FAIL,
        error: error
    };
};

export const addOnboardingStart = () => {
    return {
        type: onboardingTypes.ADD_ONBOARDING_START
    };
};

export const updateOnboarding = (data, imgUploadPayload, history) => {
    return {
        type: onboardingTypes.UPDATE_ONBOARDING,
        data: data,
        imgUploadPayload: imgUploadPayload,
        history: history
    };
};

export const updateOnboardingSuccess = onboarding => {
    return {
        type: onboardingTypes.UPDATE_ONBOARDING_SUCCESS,
        onboarding: onboarding
    };
};

export const updateOnboardingFail = error => {
    return {
        type: onboardingTypes.UPDATE_ONBOARDING_FAIL,
        error: error
    };
};

export const updateOnboardingStart = () => {
    return {
        type: onboardingTypes.UPDATE_ONBOARDING_START
    };
};

export const deleteOnboarding = (id, history) => {
    return {
        type: onboardingTypes.DELETE_ONBOARDING,
        id: id,
        history: history
    };
};

export const deleteOnboardingSuccess = () => {
    return {
        type: onboardingTypes.DELETE_ONBOARDING_SUCCESS,
    };
};

export const deleteOnboardingFail = error => {
    return {
        type: onboardingTypes.DELETE_ONBOARDING_FAIL,
        error: error
    };
};

export const deleteOnboardingStart = () => {
    return {
        type: onboardingTypes.DELETE_ONBOARDING_START
    };
};

export const getOnboarding = (id) => {
    return {
        type: onboardingTypes.GET_ONBOARDING,
        id: id
    };
};

export const getOnboardingSuccess = (onboarding) => {
    return {
        type: onboardingTypes.GET_ONBOARDING_SUCCESS,
        onboarding: onboarding
    };
};

export const getOnboardingFail = error => {
    return {
        type: onboardingTypes.GET_ONBOARDING_FAIL,
        error: error
    };
};

export const getOnboardingStart = () => {
    return {
        type: onboardingTypes.GET_ONBOARDING_START
    };
};

export const fetchOnboardingSuccess = onboardings => {
    return {
        type: onboardingTypes.FETCH_ONBOARDING_SUCCESS,
        onboardings: onboardings
    };
};

export const fetchOnboardingFail = error => {
    return {
        type: onboardingTypes.FETCH_ONBOARDING_FAIL,
        error: error
    };
};

export const fetchOnboardingStart = () => {
    return {
        type: onboardingTypes.FETCH_ONBOARDING_START
    };
};

export const fetchOnboarding = (params) => {
    return {
        type: onboardingTypes.FETCH_ONBOARDING,
        params: params
    };
};

// ====== UTILITY  ======= //
export const onboardingDismissError = () => {
    return {
        type: onboardingTypes.ONBOARDING_DISMISS_ERROR
    };
};

export const onboardingAddFilter = (filter, filterObj) => {
    return {
        type: onboardingTypes.ONBOARDING_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const onboardingClearFilter = () => {
    return {
        type: onboardingTypes.ONBOARDING_CLEAR_FILTER
    };
};

export const onboardingAddPagination = (pagination) => {
    return {
        type: onboardingTypes.ONBOARDING_ADD_PAGINATION,
        pagination: pagination
    };
};

export const onboardingClearPagination = () => {
    return {
        type: onboardingTypes.ONBOARDING_CLEAR_PAGINATION
    };
};
