import * as foodTypes from './food.types';

export const addFood = (data, imgUploadPayload, history) => {
    return {
        type: foodTypes.ADD_FOOD,
        data: data,
        imgUploadPayload: imgUploadPayload,
        history: history
    };
};

export const addFoodSuccess = food => {
    return {
        type: foodTypes.ADD_FOOD_SUCCESS,
        food: food
    };
};

export const addFoodFail = error => {
    return {
        type: foodTypes.ADD_FOOD_FAIL,
        error: error
    };
};

export const addFoodStart = () => {
    return {
        type: foodTypes.ADD_FOOD_START
    };
};

export const updateFood = (data, imgUploadPayload, history) => {
    return {
        type: foodTypes.UPDATE_FOOD,
        data: data,
        imgUploadPayload: imgUploadPayload,
        history: history
    };
};

export const updateFoodSuccess = food => {
    return {
        type: foodTypes.UPDATE_FOOD_SUCCESS,
        food: food
    };
};

export const updateFoodFail = error => {
    return {
        type: foodTypes.UPDATE_FOOD_FAIL,
        error: error
    };
};

export const updateFoodStart = () => {
    return {
        type: foodTypes.UPDATE_FOOD_START
    };
};

export const deleteFood = (id, history) => {
    return {
        type: foodTypes.DELETE_FOOD,
        id: id,
        history: history
    };
};

export const deleteFoodSuccess = () => {
    return {
        type: foodTypes.DELETE_FOOD_SUCCESS,
    };
};

export const deleteFoodFail = error => {
    return {
        type: foodTypes.DELETE_FOOD_FAIL,
        error: error
    };
};

export const deleteFoodStart = () => {
    return {
        type: foodTypes.DELETE_FOOD_START
    };
};

export const getFood = (id) => {
    return {
        type: foodTypes.GET_FOOD,
        id: id
    };
};

export const getFoodSuccess = (food) => {
    return {
        type: foodTypes.GET_FOOD_SUCCESS,
        food: food
    };
};

export const getFoodFail = error => {
    return {
        type: foodTypes.GET_FOOD_FAIL,
        error: error
    };
};

export const getFoodStart = () => {
    return {
        type: foodTypes.GET_FOOD_START
    };
};

export const fetchFoodSuccess = foods => {
    return {
        type: foodTypes.FETCH_FOOD_SUCCESS,
        foods: foods
    };
};

export const fetchFoodFail = error => {
    return {
        type: foodTypes.FETCH_FOOD_FAIL,
        error: error
    };
};

export const fetchFoodStart = () => {
    return {
        type: foodTypes.FETCH_FOOD_START
    };
};

export const fetchFood = (params) => {
    return {
        type: foodTypes.FETCH_FOOD,
        params: params
    };
};

// ====== UTILITY  ======= //
export const foodDismissError = () => {
    return {
        type: foodTypes.FOOD_DISMISS_ERROR
    };
};

export const foodAddFilter = (filter, filterObj) => {
    return {
        type: foodTypes.FOOD_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const foodClearFilter = () => {
    return {
        type: foodTypes.FOOD_CLEAR_FILTER
    };
};

export const foodAddPagination = (pagination) => {
    return {
        type: foodTypes.FOOD_ADD_PAGINATION,
        pagination: pagination
    };
};

export const foodClearPagination = () => {
    return {
        type: foodTypes.FOOD_CLEAR_PAGINATION
    };
};
