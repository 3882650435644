import { put } from "redux-saga/effects";
import * as actions from "store/reducers/content/content.actions";
import contentService from "services/Content";
import uploadService from "services/Upload";
export function* fetchContentsSaga(action) {
    yield put(actions.fetchContentsStart());
    const service = yield new contentService();
    const contents = yield service.find(action.params);
    if(!contents.error){
      yield put(actions.fetchContentsSuccess(contents));
    } else {
      yield put(actions.fetchContentsFail(contents.error));  
    }
}

export function* addContentSaga(action) {
  yield put(actions.addContentStart());
  let upload = {};
  if(action.imgUploadPayload){
    // Img Upload
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.addContentFail(upload.error));
      return;
    }
  }

  // add content
  const service = yield new contentService();
  action.data.contentImage = action.imgUploadPayload ? upload.data.url : null;
  console.log(action.data);
  // return;
  const content = yield service.create(action.data);
  if(!content.error){
    // yield put(actions.addContentSuccess(content));
    yield action.history.replace('/admin/content');
  } else {
    yield put(actions.addContentFail(content.error));
  }
}

export function* deleteContentSaga(action) {
  yield put(actions.deleteContentStart());
  const service = yield new contentService();
  const content = yield service.delete(action.id);
  if(!content.error){
    yield put(actions.deleteContentSuccess());
    if(action.history){
      action.history.replace('/admin/content')
    }
  } else {
    yield put(actions.deleteContentFail(content.error));
  }
}

export function* getContentSaga(action) {
  yield put(actions.getContentStart());
  const service = yield new contentService();
  const content = yield service.get(action.id);
  if(!content.error){
    yield put(actions.getContentSuccess(content));
  } else {
    yield put(actions.getContentFail(content.error));
  }
}

export function* updateContentSaga(action) {
  yield put(actions.updateContentStart());
  let upload = {};
  console.log('upload', action.imgUploadPayload);
  if(action.imgUploadPayload !== null){
    // Img Upload
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.addContentFail(upload.error));
      return;
    }
  }

  // add content
  const service = yield new contentService();
  action.data.contentImage = action.imgUploadPayload ? upload.data.url : null;
  const content = yield service.patch(action.data);
  if(!content.error){
    // yield put(actions.updateContentSuccess(content));
    yield action.history.replace('/admin/content');
  } else {
    yield put(actions.updateContentFail(content.error));
  }
}