export const ADD_PHYSICAL_ACTIVITY = 'ADD_PHYSICAL_ACTIVITY';
export const ADD_PHYSICAL_ACTIVITY_START = 'ADD_PHYSICAL_ACTIVITY_START';
export const ADD_PHYSICAL_ACTIVITY_SUCCESS = 'ADD_PHYSICAL_ACTIVITY_SUCCESS';
export const ADD_PHYSICAL_ACTIVITY_FAIL = 'ADD_PHYSICAL_ACTIVITY_FAIL';

export const DELETE_PHYSICAL_ACTIVITY = 'DELETE_PHYSICAL_ACTIVITY';
export const DELETE_PHYSICAL_ACTIVITY_START = 'DELETE_PHYSICAL_ACTIVITY_START';
export const DELETE_PHYSICAL_ACTIVITY_SUCCESS = 'DELETE_PHYSICAL_ACTIVITY_SUCCESS';
export const DELETE_PHYSICAL_ACTIVITY_FAIL = 'DELETE_PHYSICAL_ACTIVITY_FAIL';


export const UPDATE_PHYSICAL_ACTIVITY = 'UPDATE_PHYSICAL_ACTIVITY';
export const UPDATE_PHYSICAL_ACTIVITY_START = 'UPDATE_PHYSICAL_ACTIVITY_START';
export const UPDATE_PHYSICAL_ACTIVITY_SUCCESS = 'UPDATE_PHYSICAL_ACTIVITY_SUCCESS';
export const UPDATE_PHYSICAL_ACTIVITY_FAIL = 'UPDATE_PHYSICAL_ACTIVITY_FAIL';

export const GET_PHYSICAL_ACTIVITY = 'GET_PHYSICAL_ACTIVITY';
export const GET_PHYSICAL_ACTIVITY_START = 'GET_PHYSICAL_ACTIVITY_START';
export const GET_PHYSICAL_ACTIVITY_SUCCESS = 'GET_PHYSICAL_ACTIVITY_SUCCESS';
export const GET_PHYSICAL_ACTIVITY_FAIL = 'GET_PHYSICAL_ACTIVITY_FAIL';

export const FETCH_PHYSICAL_ACTIVITIES = 'FETCH_PHYSICAL_ACTIVITIES';
export const FETCH_PHYSICAL_ACTIVITIES_START = 'FETCH_PHYSICAL_ACTIVITIES_START';
export const FETCH_PHYSICAL_ACTIVITIES_SUCCESS = 'FETCH_PHYSICAL_ACTIVITIES_SUCCESS';
export const FETCH_PHYSICAL_ACTIVITIES_FAIL = 'FETCH_PHYSICAL_ACTIVITIES_FAIL';


// ====== UTILITY  ======= //
export const PHYSICAL_ACTIVITY_DISMISS_ERROR = 'PHYSICAL_ACTIVITY_DISMISS_ERROR';
export const PHYSICAL_ACTIVITY_ADD_FILTER = 'PHYSICAL_ACTIVITY_ADD_FILTER';
export const PHYSICAL_ACTIVITY_CLEAR_FILTER = 'PHYSICAL_ACTIVITY_CLEAR_FILTER';
export const PHYSICAL_ACTIVITY_ADD_PAGINATION = 'PHYSICAL_ACTIVITY_ADD_PAGINATION';
export const PHYSICAL_ACTIVITY_CLEAR_PAGINATION = 'PHYSICAL_ACTIVITY_CLEAR_PAGINATION';