import Service from "./Service";

export default class Upload extends Service {
  constructor() {
    super("uploads", true);
  }

  async upload(data) {
    const response = await super.create(data);
    return response;
  }
}
