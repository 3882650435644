import { stateToHTML } from 'draft-js-export-html';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const createQuerySearch = (columns, fields, dataSearch) => {
  if (dataSearch === '') {
    return '';
  }

  const querySearch = [];
  if (fields.length) {
    fields.forEach(element => {
      querySearch.push(`search[${element}]=${dataSearch}`);
    });
  } else {
    columns.forEach(element => {
      querySearch.push(`search[${element}]=${dataSearch}`);
    });
  }
  return querySearch.join('&');
};

export const handleInput = event => {
  if (event._isAMomentObject) {
    return event.format('DD-MM-YYYY'); // Date
  }
  if (event[0]?.file) {
    return event; // Img / File
  }
  if (event.value) {
    return event;
  }
  return event.target.value; //  Regular Input
};

export const checkValidity = (value, rules, fieldName, compareTo) => {
  fieldName = fieldName?.replace('*', '');
  let isValid = true;
  let errorMessage = '';
  if (!rules) {
    return true;
  }

  if (rules.maxFileSize) {
    if (value[0].size > rules.maxFileSize) {
      const maxSize = rules.maxFileSize / 1024;
      isValid = false;
      errorMessage = `Image must be ${maxSize}MB or less`;
    }
  }

  if (rules.minLength) {
    if (!value) {
      isValid = false;
      errorMessage = `Please enter a value for ${fieldName}`;
    } else {
      isValid = value.length >= rules.minLength && isValid;
      if (value.length < rules.minLength) {
        errorMessage = `${fieldName} minimum length is ${rules.minLength}`;
      }
    }
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
    if (value.length > rules.maxLength) {
      errorMessage = `${fieldName} max length is ${rules.maxLength}`;
    }
  }

  if (rules.minValue !== undefined && rules.minValue !== null) {
    if (rules.required || value) {
      isValid = parseInt(value) >= rules.minValue && isValid;
      if (parseInt(value) < rules.minValue) {
        errorMessage = `${fieldName} minimum value is ${rules.minValue}`;
      }
    }
  }

  if (rules.isEmail) {
    const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    isValid = pattern.test(value) && isValid;
    if (!pattern.test(value)) {
      errorMessage = 'Invalid email address';
    }
  }

  if (rules.isPhoneNumber) {
    const pattern = /\+?([-]?\d+)+|\(\d+\)([-]\d+)/;
    isValid = pattern.test(value) && isValid;
    if (!pattern.test(value)) {
      errorMessage = 'Invalid phone number';
    }
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
    if (!pattern.test(value)) {
      errorMessage = `${fieldName} should be number`;
    }
  }

  if (rules.required) {
    if (!value) {
      isValid = false;
      errorMessage = `${fieldName} is required`;
    }

    // Check if value is wysiwyg
    if (value?._immutable) {
      if (stateToHTML(value.getCurrentContent()) === '<p><br></p>') {
        return { isValid: false, errorMessage: `${fieldName} is required` };
      }
    }

    if (typeof value !== 'string') value += '';
    isValid = value.trim() !== '' && isValid;
    if (value.trim() === '') {
      errorMessage = `${fieldName} is required`;
    }
  }

  if (typeof compareTo?.value === 'string') {
    if (value !== compareTo.value) {
      errorMessage = `${compareTo.fieldName} does not match `;
      isValid = false;
    }
  }

  return { isValid, errorMessage };
};
