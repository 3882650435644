import { put } from "redux-saga/effects";
import * as actions from "store/reducers/food/food.actions";
import foodService from "services/Food";
import uploadService from "services/Upload";
export function* fetchFoodSaga(action) {
  yield put(actions.fetchFoodStart());
  const service = yield new foodService();
  const food = yield service.find(action.params);
  if (!food.error) {
    yield put(actions.fetchFoodSuccess(food));
  } else {
    yield put(actions.fetchFoodFail(food.error));
  }
}

export function* addFoodSaga(action) {
  yield put(actions.addFoodStart());
  let upload = {};
  if (action.imgUploadPayload !== null) {
    // Img Upload
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.addFoodFail(upload.error));
      return;
    }
  }

  // add food
  action.data.imageUrl = action.imgUploadPayload ? upload.data.url : null;
  action.data.source = "admin";
  const service = yield new foodService();
  const food = yield service.create(action.data);
  if (!food.error) {
    yield action.history.replace("/admin/food");
  } else {
    yield put(actions.addFoodFail(food.error));
  }
}

export function* deleteFoodSaga(action) {
  yield put(actions.deleteFoodStart());
  const service = yield new foodService();
  const food = yield service.delete(action.id);
  if (!food.error) {
    yield put(actions.deleteFoodSuccess());
    if (action.history) {
      action.history.replace("/admin/food");
    }
  } else {
    yield put(actions.deleteFoodFail(food.error));
  }
}

export function* getFoodSaga(action) {
  yield put(actions.getFoodStart());
  const service = yield new foodService();
  const food = yield service.get(action.id);
  if (!food.error) {
    yield put(actions.getFoodSuccess(food));
  } else {
    yield put(actions.getFoodFail(food.error));
  }
}

export function* updateFoodSaga(action) {
  yield put(actions.updateFoodStart());
  let upload = {};
  if (action.imgUploadPayload !== null) {
    // Img Upload
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.updateFoodFail(upload.error));
      return;
    }
  }

  // update food
  if (action.imgUploadPayload) {
    action.data.imageUrl = upload.data.url;
  }
  const service = yield new foodService();
  const food = yield service.patch(action.data);
  if (!food.error) {
    yield action.history.replace("/admin/food");
  } else {
    yield put(actions.updateFoodFail(food.error));
  }
}
