import * as foodCategoryTypes from "./food-category.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  foodCategories: {},
  foodCategory: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  },
};

const fetchFoodCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchFoodCategorySuccess = (state, action) => {
  return updateObject(state, {
    foodCategories: action.foodCategories,
    foodCategory: {},
    loading: false,
    error: null,
  });
};

const fetchFoodCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addFoodCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addFoodCategorySuccess = (state, action) => {
  return updateObject(state, {
    foodCategory: action.foodCategory,
    loading: false,
    error: null,
  });
};

const addFoodCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteFoodCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteFoodCategorySuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null,
  });
};

const deleteFoodCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getFoodCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getFoodCategorySuccess = (state, action) => {
  return updateObject(state, {
    foodCategory: action.foodCategory,
    loading: false,
    error: null,
  });
};

const getFoodCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateFoodCategoryStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateFoodCategorySuccess = (state, action) => {
  return updateObject(state, {
    foodCategory: action.foodCategory,
    loading: false,
    error: null,
  });
};

const updateFoodCategoryFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const foodCategoryDismissError = (state) => {
  return updateObject(state, {
    error: null,
  });
};

const foodCategoryAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj,
  });
};

const foodCategoryClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {},
  });
};

const foodCategoryAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination,
  });
};

const foodCategoryClearPagination = (state) => {
  return updateObject(state, {
    pagination: {},
  });
};

const foodCategoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case foodCategoryTypes.FETCH_FOOD_CATEGORY_START:
      return fetchFoodCategoryStart(state, action);
    case foodCategoryTypes.FETCH_FOOD_CATEGORY_SUCCESS:
      return fetchFoodCategorySuccess(state, action);
    case foodCategoryTypes.FETCH_FOOD_CATEGORY_FAIL:
      return fetchFoodCategoryFail(state, action);
    case foodCategoryTypes.ADD_FOOD_CATEGORY_START:
      return addFoodCategoryStart(state, action);
    case foodCategoryTypes.ADD_FOOD_CATEGORY_SUCCESS:
      return addFoodCategorySuccess(state, action);
    case foodCategoryTypes.ADD_FOOD_CATEGORY_FAIL:
      return addFoodCategoryFail(state, action);
    case foodCategoryTypes.DELETE_FOOD_CATEGORY_START:
      return deleteFoodCategoryStart(state, action);
    case foodCategoryTypes.DELETE_FOOD_CATEGORY_SUCCESS:
      return deleteFoodCategorySuccess(state, action);
    case foodCategoryTypes.DELETE_FOOD_CATEGORY_FAIL:
      return deleteFoodCategoryFail(state, action);
    case foodCategoryTypes.GET_FOOD_CATEGORY_START:
      return getFoodCategoryStart(state, action);
    case foodCategoryTypes.GET_FOOD_CATEGORY_SUCCESS:
      return getFoodCategorySuccess(state, action);
    case foodCategoryTypes.GET_FOOD_CATEGORY_FAIL:
      return getFoodCategoryFail(state, action);
    case foodCategoryTypes.UPDATE_FOOD_CATEGORY_START:
      return updateFoodCategoryStart(state, action);
    case foodCategoryTypes.UPDATE_FOOD_CATEGORY_SUCCESS:
      return updateFoodCategorySuccess(state, action);
    case foodCategoryTypes.UPDATE_FOOD_CATEGORY_FAIL:
      return updateFoodCategoryFail(state, action);
    case foodCategoryTypes.FOOD_CATEGORY_DISMISS_ERROR:
      return foodCategoryDismissError(state);
    case foodCategoryTypes.FOOD_CATEGORY_ADD_FILTER:
      return foodCategoryAddFilter(state, action);
    case foodCategoryTypes.FOOD_CATEGORY_CLEAR_FILTER:
      return foodCategoryClearFilter(state);
    case foodCategoryTypes.FOOD_CATEGORY_ADD_PAGINATION:
      return foodCategoryAddPagination(state, action);
    case foodCategoryTypes.FOOD_CATEGORY_CLEAR_PAGINATION:
      return foodCategoryClearPagination(state);
    default:
      return state;
  }
};

export default foodCategoryReducer;
