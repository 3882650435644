import { all } from 'redux-saga/effects';
import { adminAuthManagementSagas } from './admin-auth-management';
import { adminSagas } from './admins';
import { articleSagas } from './article';
import { authSagas } from './auth';
import { contentCategorySagas } from './content-category';
import { contentSagas } from './content';
import { physicalActivitySagas } from './physical-activity';
import { rolesSagas } from './roles';
import { usersSagas } from './users';
import { faqSagas } from './faq';
import { privacyPolicySagas } from './privacy-policy';
import { onboardingSagas } from './onboarding';
import { pushNotificationSagas } from './push-notification';
import { greetingSagas } from './greeting';
import { waterConsumptionSagas } from './water-consumption';
import { HBA1CSagas } from './HBA1C';
import { ActivitySagas } from './activity';
import { GlucoseSagas } from './glucose';
import { OtherLabResultSagas } from './other-lab-result';
import { articleCategorySagas } from './article-category';
import { contentViewSagas } from './content-views';
import { medicineSagas } from './medicine';
import { topMedicineSagas } from './top-medicine';
import { foodSagas } from './food';
import { foodCategorySagas } from './food-category';
import { foodUnitSagas } from './food-unit';
import { foodRecipeSagas } from './food-recipe';
import { topFoodSagas } from './top-food';
import { overcalorieDiabeticsSagas } from './overcalorie-diabetics';
import { bannerSagas } from './banner';
import { pageInformationSagas } from './page-information';
import { adminLogSagas } from './admin-log';
import { pinnedContentSagas } from './pinned-content';
import { versioningSagas } from './versioning';

export default function* rootSaga() {
  yield all({
    ...adminAuthManagementSagas,
    ...adminSagas,
    ...articleSagas,
    ...authSagas,
    ...contentCategorySagas,
    ...contentSagas,
    ...physicalActivitySagas,
    ...rolesSagas,
    ...usersSagas,
    ...faqSagas,
    ...privacyPolicySagas,
    ...onboardingSagas,
    ...greetingSagas,
    ...waterConsumptionSagas,
    ...HBA1CSagas,
    ...ActivitySagas,
    ...GlucoseSagas,
    ...OtherLabResultSagas,
    ...pushNotificationSagas,
    ...articleCategorySagas,
    ...contentViewSagas,
    ...medicineSagas,
    ...topMedicineSagas,
    ...foodSagas,
    ...foodCategorySagas,
    ...foodUnitSagas,
    ...foodRecipeSagas,
    ...topFoodSagas,
    ...overcalorieDiabeticsSagas,
    ...bannerSagas,
    ...pageInformationSagas,
    ...adminLogSagas,
    ...pinnedContentSagas,
    ...versioningSagas
  });
}
