import * as pinnedContentTypes from 'store/reducers/pinned-contents/pinned-content.types';
import { takeEvery } from 'redux-saga/effects';
import {
  fetchPinnedContentsSaga,
  addPinnedContentSaga,
  deletePinnedContentSaga,
  getPinnedContentSaga,
  updatePinnedContentSaga
} from './pinned-content';

export const pinnedContentSagas = {
  watchFetchPinnedContents: takeEvery(
    pinnedContentTypes.FETCH_PINNED_CONTENTS,
    fetchPinnedContentsSaga
  ),
  watchAddPinnedContent: takeEvery(
    pinnedContentTypes.ADD_PINNED_CONTENT,
    addPinnedContentSaga
  ),
  watchDeletePinnedContent: takeEvery(
    pinnedContentTypes.DELETE_PINNED_CONTENT,
    deletePinnedContentSaga
  ),
  watchGetPinnedContent: takeEvery(
    pinnedContentTypes.GET_PINNED_CONTENT,
    getPinnedContentSaga
  ),
  watchUpdatePinnedContent: takeEvery(
    pinnedContentTypes.UPDATE_PINNED_CONTENT,
    updatePinnedContentSaga
  )
};
