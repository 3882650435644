import { put } from "redux-saga/effects";
import * as actions from "store/reducers/HBA1C/HBA1C.actions";
import HBA1CService from "../../../services/HBA1C";

/**
 * FETCH HBA1C
 */
export function* fetchHBA1CSaga(action) {
  yield put(actions.fetchHBA1CStart());
  try {
    const service = yield new HBA1CService();
    const HBA1Cs = yield service.find(action.params);
    yield put(actions.fetchHBA1CSuccess(HBA1Cs));
  } catch (error) {
    yield put(actions.fetchHBA1CFail(error));
  }
}
