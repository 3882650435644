import { takeEvery } from "redux-saga/effects";
import * as roleTypes from "store/reducers/roles/roles.types";
import {
  fetchRolesSaga,
  addRoleSaga,
  deleteRoleSaga,
  getRoleSaga,
  updateRoleSaga
} from "./roles";

export const rolesSagas = {
  watchFetchRoles: takeEvery(roleTypes.FETCH_ROLES, fetchRolesSaga),
  watchAddRole:  takeEvery(roleTypes.ADD_ROLE, addRoleSaga),
  watchDeleteRole:  takeEvery(roleTypes.DELETE_ROLE, deleteRoleSaga),
  watchGetRole:  takeEvery(roleTypes.GET_ROLE, getRoleSaga),
  watchUpdateRole:  takeEvery(roleTypes.UPDATE_ROLE, updateRoleSaga)
}