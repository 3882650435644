import * as foodUnitTypes from "store/reducers/food-unit/food-unit.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchFoodUnitSaga,
  addFoodUnitSaga,
  deleteFoodUnitSaga,
  getFoodUnitSaga,
  updateFoodUnitSaga
} from "./food-unit";

export const foodUnitSagas = {
  watchFetchFoodUnit: takeEvery(foodUnitTypes.FETCH_FOOD_UNIT, fetchFoodUnitSaga),
  watchAddFoodUnit: takeEvery(foodUnitTypes.ADD_FOOD_UNIT, addFoodUnitSaga),
  watchDeleteFoodUnit: takeEvery(foodUnitTypes.DELETE_FOOD_UNIT, deleteFoodUnitSaga),
  watchGetFoodUnit: takeEvery(foodUnitTypes.GET_FOOD_UNIT, getFoodUnitSaga),
  watchUpdateFoodUnit: takeEvery(foodUnitTypes.UPDATE_FOOD_UNIT, updateFoodUnitSaga)
}