export const ADD_PAGE_INFORMATION = "ADD_PAGE_INFORMATION";
export const ADD_PAGE_INFORMATION_START = "ADD_PAGE_INFORMATION_START";
export const ADD_PAGE_INFORMATION_SUCCESS = "ADD_PAGE_INFORMATION_SUCCESS";
export const ADD_PAGE_INFORMATION_FAIL = "ADD_PAGE_INFORMATION_FAIL";

export const DELETE_PAGE_INFORMATION = "DELETE_PAGE_INFORMATION";
export const DELETE_PAGE_INFORMATION_START = "DELETE_PAGE_INFORMATION_START";
export const DELETE_PAGE_INFORMATION_SUCCESS =
  "DELETE_PAGE_INFORMATION_SUCCESS";
export const DELETE_PAGE_INFORMATION_FAIL = "DELETE_PAGE_INFORMATION_FAIL";

export const UPDATE_PAGE_INFORMATION = "UPDATE_PAGE_INFORMATION";
export const UPDATE_PAGE_INFORMATION_START = "UPDATE_PAGE_INFORMATION_START";
export const UPDATE_PAGE_INFORMATION_SUCCESS =
  "UPDATE_PAGE_INFORMATION_SUCCESS";
export const UPDATE_PAGE_INFORMATION_FAIL = "UPDATE_PAGE_INFORMATION_FAIL";

export const GET_PAGE_INFORMATION = "GET_PAGE_INFORMATION";
export const GET_PAGE_INFORMATION_START = "GET_PAGE_INFORMATION_START";
export const GET_PAGE_INFORMATION_SUCCESS = "GET_PAGE_INFORMATION_SUCCESS";
export const GET_PAGE_INFORMATION_FAIL = "GET_PAGE_INFORMATION_FAIL";

export const FETCH_PAGE_INFORMATIONS = "FETCH_PAGE_INFORMATIONS";
export const FETCH_PAGE_INFORMATIONS_START = "FETCH_PAGE_INFORMATIONS_START";
export const FETCH_PAGE_INFORMATIONS_SUCCESS =
  "FETCH_PAGE_INFORMATIONS_SUCCESS";
export const FETCH_PAGE_INFORMATIONS_FAIL = "FETCH_PAGE_INFORMATIONS_FAIL";

// ====== UTILITY  ======= //
export const PAGE_INFORMATION_DISMISS_ERROR = "PAGE_INFORMATION_DISMISS_ERROR";
export const PAGE_INFORMATION_ADD_FILTER = "PAGE_INFORMATION_ADD_FILTER";
export const PAGE_INFORMATION_CLEAR_FILTER = "PAGE_INFORMATION_CLEAR_FILTER";
export const PAGE_INFORMATION_ADD_PAGINATION =
  "PAGE_INFORMATION_ADD_PAGINATION";
export const PAGE_INFORMATION_CLEAR_PAGINATION =
  "PAGE_INFORMATION_CLEAR_PAGINATION";
