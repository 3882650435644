import * as OvercalorieDiabeticsTypes from "./overcalorieDiabetics.types";

/**
 * FETCH OVERCALORIE DIABETICS
 */
export const fetchOvercalorieDiabetics = (params) => ({
  type: OvercalorieDiabeticsTypes.FETCH_OVERCALORIE_DIABETICS,
  params
});
export const fetchOvercalorieDiabeticsSuccess = (overcalorieDiabetics) => ({
  type: OvercalorieDiabeticsTypes.FETCH_OVERCALORIE_DIABETICS_SUCCESS,
  overcalorieDiabetics
});
export const fetchOvercalorieDiabeticsFail = (error) => ({
  type: OvercalorieDiabeticsTypes.FETCH_OVERCALORIE_DIABETICS_FAIL,
  error
});
export const fetchOvercalorieDiabeticsStart = () => ({
  type: OvercalorieDiabeticsTypes.FETCH_OVERCALORIE_DIABETICS_START
});
