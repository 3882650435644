import * as pinnedContentTypes from './pinned-content.types';
import { updateObject } from '../../../shared/utility';

const INITIAL_STATE = {
  pinnedContents: {},
  pinnedContent: {},
  successDeteled: '',
  error: null,
  loading: false,
  filter: '',
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  }
};

/**
 * FETCH PINNED_CONTENTS
 */
const fetchPinnedContentsStart = state =>
  updateObject(state, { loading: true });
const fetchPinnedContentsSuccess = (state, action) =>
  updateObject(state, {
    pinnedContents: action.pinnedContents,
    pinnedContent: {},
    loading: false,
    error: null
  });
const fetchPinnedContentsFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * CREATE PINNED_CONTENT
 */
const addPinnedContentStart = state => updateObject(state, { loading: true });
const addPinnedContentSuccess = (state, action) =>
  updateObject(state, {
    pinnedContent: action.pinnedContent,
    loading: false,
    error: null
  });
const addPinnedContentFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * GET PINNED_CONTENT
 */
const getPinnedContentStart = state => updateObject(state, { loading: true });
const getPinnedContentSuccess = (state, action) =>
  updateObject(state, {
    pinnedContent: action.pinnedContent,
    loading: false,
    error: null
  });
const getPinnedContentFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * UPDATE PINNED_CONTENT
 */
const updatePinnedContentStart = state =>
  updateObject(state, { loading: true });
const updatePinnedContentSuccess = (state, action) =>
  updateObject(state, {
    pinnedContent: action.pinnedContent,
    loading: false,
    error: null
  });
const updatePinnedContentFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * DELETE PINNED_CONTENT
 */
const deletePinnedContentStart = state =>
  updateObject(state, { loading: true });
const deletePinnedContentSuccess = state =>
  updateObject(state, {
    successDeteled: new Date().getTime(),
    loading: false,
    error: null
  });
const deletePinnedContentFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

// ====== UTILITY  ======= //

const pinnedContentDismissError = state => {
  return updateObject(state, {
    error: null
  });
};

const pinnedContentAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj
  });
};

const pinnedContentClearFilter = state => {
  return updateObject(state, {
    filter: '',
    filterObj: {}
  });
};

const pinnedContentAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination
  });
};

const pinnedContentClearPagination = state => {
  return updateObject(state, {
    pagination: {}
  });
};

const pinnedContentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case pinnedContentTypes.FETCH_PINNED_CONTENTS_START:
      return fetchPinnedContentsStart(state, action);
    case pinnedContentTypes.FETCH_PINNED_CONTENTS_SUCCESS:
      return fetchPinnedContentsSuccess(state, action);
    case pinnedContentTypes.FETCH_PINNED_CONTENTS_FAIL:
      return fetchPinnedContentsFail(state, action);

    case pinnedContentTypes.ADD_PINNED_CONTENT_START:
      return addPinnedContentStart(state, action);
    case pinnedContentTypes.ADD_PINNED_CONTENT_SUCCESS:
      return addPinnedContentSuccess(state, action);
    case pinnedContentTypes.ADD_PINNED_CONTENT_FAIL:
      return addPinnedContentFail(state, action);

    case pinnedContentTypes.GET_PINNED_CONTENT_START:
      return getPinnedContentStart(state, action);
    case pinnedContentTypes.GET_PINNED_CONTENT_SUCCESS:
      return getPinnedContentSuccess(state, action);
    case pinnedContentTypes.GET_PINNED_CONTENT_FAIL:
      return getPinnedContentFail(state, action);

    case pinnedContentTypes.UPDATE_PINNED_CONTENT_START:
      return updatePinnedContentStart(state, action);
    case pinnedContentTypes.UPDATE_PINNED_CONTENT_SUCCESS:
      return updatePinnedContentSuccess(state, action);
    case pinnedContentTypes.UPDATE_PINNED_CONTENT_FAIL:
      return updatePinnedContentFail(state, action);

    case pinnedContentTypes.DELETE_PINNED_CONTENT_START:
      return deletePinnedContentStart(state, action);
    case pinnedContentTypes.DELETE_PINNED_CONTENT_SUCCESS:
      return deletePinnedContentSuccess(state, action);
    case pinnedContentTypes.DELETE_PINNED_CONTENT_FAIL:
      return deletePinnedContentFail(state, action);

    case pinnedContentTypes.PINNED_CONTENT_DISMISS_ERROR:
      return pinnedContentDismissError(state);
    case pinnedContentTypes.PINNED_CONTENT_ADD_FILTER:
      return pinnedContentAddFilter(state, action);
    case pinnedContentTypes.PINNED_CONTENT_CLEAR_FILTER:
      return pinnedContentClearFilter(state);
    case pinnedContentTypes.PINNED_CONTENT_ADD_PAGINATION:
      return pinnedContentAddPagination(state, action);
    case pinnedContentTypes.PINNED_CONTENT_CLEAR_PAGINATION:
      return pinnedContentClearPagination(state);
    default:
      return state;
  }
};

export default pinnedContentReducer;
