export const ADD_ARTICLE_CATEGORY = 'ADD_ARTICLE_CATEGORY';
export const ADD_ARTICLE_CATEGORY_START = 'ADD_ARTICLE_CATEGORY_START';
export const ADD_ARTICLE_CATEGORY_SUCCESS = 'ADD_ARTICLE_CATEGORY_SUCCESS';
export const ADD_ARTICLE_CATEGORY_FAIL = 'ADD_ARTICLE_CATEGORY_FAIL';

export const DELETE_ARTICLE_CATEGORY = 'DELETE_ARTICLE_CATEGORY';
export const DELETE_ARTICLE_CATEGORY_START = 'DELETE_ARTICLE_CATEGORY_START';
export const DELETE_ARTICLE_CATEGORY_SUCCESS = 'DELETE_ARTICLE_CATEGORY_SUCCESS';
export const DELETE_ARTICLE_CATEGORY_FAIL = 'DELETE_ARTICLE_CATEGORY_FAIL';


export const UPDATE_ARTICLE_CATEGORY = 'UPDATE_ARTICLE_CATEGORY';
export const UPDATE_ARTICLE_CATEGORY_START = 'UPDATE_ARTICLE_CATEGORY_START';
export const UPDATE_ARTICLE_CATEGORY_SUCCESS = 'UPDATE_ARTICLE_CATEGORY_SUCCESS';
export const UPDATE_ARTICLE_CATEGORY_FAIL = 'UPDATE_ARTICLE_CATEGORY_FAIL';

export const GET_ARTICLE_CATEGORY = 'GET_ARTICLE_CATEGORY';
export const GET_ARTICLE_CATEGORY_START = 'GET_ARTICLE_CATEGORY_START';
export const GET_ARTICLE_CATEGORY_SUCCESS = 'GET_ARTICLE_CATEGORY_SUCCESS';
export const GET_ARTICLE_CATEGORY_FAIL = 'GET_ARTICLE_CATEGORY_FAIL';

export const FETCH_ARTICLE_CATEGORY = 'FETCH_ARTICLE_CATEGORY';
export const FETCH_ARTICLE_CATEGORY_START = 'FETCH_ARTICLE_CATEGORY_START';
export const FETCH_ARTICLE_CATEGORY_SUCCESS = 'FETCH_ARTICLE_CATEGORY_SUCCESS';
export const FETCH_ARTICLE_CATEGORY_FAIL = 'FETCH_ARTICLE_CATEGORY_FAIL';

// ====== UTILITY  ======= //
export const ARTICLE_CATEGORY_DISMISS_ERROR = 'ARTICLE_CATEGORY_DISMISS_ERROR';
export const ARTICLE_CATEGORY_ADD_FILTER = 'ARTICLE_CATEGORY_ADD_FILTER';
export const ARTICLE_CATEGORY_CLEAR_FILTER = 'ARTICLE_CATEGORY_CLEAR_FILTER';
export const ARTICLE_CATEGORY_ADD_PAGINATION = 'ARTICLE_CATEGORY_ADD_PAGINATION';
export const ARTICLE_CATEGORY_CLEAR_PAGINATION = 'ARTICLE_CATEGORY_CLEAR_PAGINATION';