import { takeEvery } from "redux-saga/effects";
import * as TopMedicineTypes from "store/reducers/top-medicine/topMedicine.types";
import {
  fetchTopMedicineSaga,
  fetchTopManufacturerSaga
} from "./TopMedicineSaga";

export const topMedicineSagas = {
  watchFetchTopMedicine: takeEvery(
    TopMedicineTypes.FETCH_TOP_MEDICINE,
    fetchTopMedicineSaga
  ),
  watchFetchTopManufacturer: takeEvery(
    TopMedicineTypes.FETCH_TOP_MANUFACTURER,
    fetchTopManufacturerSaga
  )
};
