import * as medicineTypes from "./medicine.types";

/**
 * FETCH MEDICINES
 */
export const fetchMedicines = (params) => ({
  type: medicineTypes.FETCH_MEDICINES,
  params
});
export const fetchMedicinesStart = () => ({
  type: medicineTypes.FETCH_MEDICINES_START
});
export const fetchMedicinesSuccess = (medicines) => ({
  type: medicineTypes.FETCH_MEDICINES_SUCCESS,
  medicines
});
export const fetchMedicinesFail = (error) => ({
  type: medicineTypes.FETCH_MEDICINES_FAIL,
  error
});

/**
 * CREATE MEDICINE
 */
export const addMedicine = (history, data, imgUploadPayload) => ({
  type: medicineTypes.ADD_MEDICINE,
  history,
  data,
  imgUploadPayload
});
export const addMedicineStart = () => ({
  type: medicineTypes.ADD_MEDICINE_START
});
export const addMedicineSuccess = (medicine) => ({
  type: medicineTypes.ADD_MEDICINE_SUCCESS,
  medicine
});
export const addMedicineFail = (error) => ({
  type: medicineTypes.ADD_MEDICINE_FAIL,
  error
});

/**
 * GET MEDICINE
 */
export const getMedicine = (id) => ({
  type: medicineTypes.GET_MEDICINE,
  id
});
export const getMedicineStart = () => ({
  type: medicineTypes.GET_MEDICINE_START
});
export const getMedicineSuccess = (medicine) => ({
  type: medicineTypes.GET_MEDICINE_SUCCESS,
  medicine
});
export const getMedicineFail = (error) => ({
  type: medicineTypes.GET_MEDICINE_FAIL,
  error
});

/**
 * UPDATE MEDICINE
 */
export const updateMedicine = (history, data, imgUploadPayload) => ({
  type: medicineTypes.UPDATE_MEDICINE,
  history,
  data,
  imgUploadPayload
});
export const updateMedicineStart = () => ({
  type: medicineTypes.UPDATE_MEDICINE_START
});
export const updateMedicineSuccess = (medicine) => ({
  type: medicineTypes.UPDATE_MEDICINE_SUCCESS,
  medicine
});
export const updateMedicineFail = (error) => ({
  type: medicineTypes.UPDATE_MEDICINE_FAIL,
  error
});

/**
 * DELETE MEDICINE
 */
export const deleteMedicine = (id, history) => ({
  type: medicineTypes.DELETE_MEDICINE,
  id,
  history
});
export const deleteMedicineStart = () => ({
  type: medicineTypes.DELETE_MEDICINE_START
});
export const deleteMedicineSuccess = () => ({
  type: medicineTypes.DELETE_MEDICINE_SUCCESS
});

export const deleteMedicineFail = (error) => ({
  type: medicineTypes.DELETE_MEDICINE_FAIL,
  error
});

// ====== UTILITY  ======= //
export const medicineDismissError = () => {
  return {
      type: medicineTypes.MEDICINE_DISMISS_ERROR
  };
};

export const medicineAddFilter = (filter, filterObj) => {
  return {
      type: medicineTypes.MEDICINE_ADD_FILTER,
      filter: filter,
      filterObj: filterObj
  };
};

export const medicineClearFilter = () => {
  return {
      type: medicineTypes.MEDICINE_CLEAR_FILTER
  };
};

export const medicineAddPagination = (pagination) => {
  return {
      type: medicineTypes.MEDICINE_ADD_PAGINATION,
      pagination: pagination
  };
};

export const medicineClearPagination = () => {
  return {
      type: medicineTypes.MEDICINE_CLEAR_PAGINATION
  };
};
