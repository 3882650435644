import * as foodRecipeTypes from "store/reducers/food-recipe/food-recipe.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchFoodRecipesSaga,
  getFoodRecipeSaga,
  addFoodRecipeSaga,
  deleteFoodRecipeSaga,
  updateFoodRecipeSaga
} from "./foodRecipeSaga";

export const foodRecipeSagas = {
  watchFetchFoodRecipes: takeEvery(
    foodRecipeTypes.FETCH_FOOD_RECIPES,
    fetchFoodRecipesSaga
  ),
  watchGetFoodRecipe: takeEvery(
    foodRecipeTypes.GET_FOOD_RECIPE,
    getFoodRecipeSaga
  ),
  watchAddFoodRecipe: takeEvery(
    foodRecipeTypes.ADD_FOOD_RECIPE,
    addFoodRecipeSaga
  ),
  watchDeleteFoodRecipe: takeEvery(
    foodRecipeTypes.DELETE_FOOD_RECIPE,
    deleteFoodRecipeSaga
  ),
  watchUpdateFoodRecipe: takeEvery(
    foodRecipeTypes.UPDATE_FOOD_RECIPE,
    updateFoodRecipeSaga
  )
};
