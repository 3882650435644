import * as faqTypes from "store/reducers/faq/faq.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchFaqSaga,
  addFaqSaga,
  deleteFaqSaga,
  getFaqSaga,
  updateFaqSaga
} from "./faq";

export const faqSagas = {
  watchFetchFaq: takeEvery(faqTypes.FETCH_FAQ, fetchFaqSaga),
  watchAddFaq: takeEvery(faqTypes.ADD_FAQ, addFaqSaga),
  watchDeleteFaq: takeEvery(faqTypes.DELETE_FAQ, deleteFaqSaga),
  watchGetFaq: takeEvery(faqTypes.GET_FAQ, getFaqSaga),
  watchUpdateFaq: takeEvery(faqTypes.UPDATE_FAQ, updateFaqSaga)
}