import * as ActivityTypes from "./Activity.types";

/**
 * FETCH Activity
 */
export const fetchActivity = (params) => ({
  type: ActivityTypes.FETCH_ACTIVITY,
  params
});
export const fetchActivitySuccess = (activity) => ({
  type: ActivityTypes.FETCH_ACTIVITY_SUCCESS,
  activity
});
export const fetchActivityFail = (error) => ({
  type: ActivityTypes.FETCH_ACTIVITY_FAIL,
  error
});
export const fetchActivityStart = () => ({
  type: ActivityTypes.FETCH_ACTIVITY_START
});
