import { put } from "redux-saga/effects";
import * as actions from "store/reducers/content-views/content-views.actions";
import contentViewService from "services/ContentViews";
export function* fetchContentViewSaga(action) {
  yield put(actions.fetchContentViewStart());
  const service = yield new contentViewService();
  const contentView = yield service.find(action.params);
  if (!contentView.error) {
    yield put(actions.fetchContentViewSuccess(contentView, action.typeParams));
  } else {
    yield put(actions.fetchContentViewFail(contentView.error));
  }
}

export function* getContentViewSaga(action) {
  yield put(actions.getContentViewStart());
  const service = yield new contentViewService();
  const contentView = yield service.get(action.id);
  if (!contentView.error) {
    yield put(actions.getContentViewSuccess(contentView));
  } else {
    yield put(actions.getContentViewFail(contentView.error));
  }
}
