import * as contentViewTypes from './content-views.types';

export const contentViewDismissError = () => {
    return {
        type: contentViewTypes.CONTENT_VIEWS_DISMISS_ERROR
    };
};


export const getContentView = (id) => {
    return {
        type: contentViewTypes.GET_CONTENT_VIEWS,
        id: id
    };
};

export const getContentViewSuccess = (contentView) => {
    return {
        type: contentViewTypes.GET_CONTENT_VIEWS_SUCCESS,
        contentView: contentView
    };
};

export const getContentViewFail = error => {
    return {
        type: contentViewTypes.GET_CONTENT_VIEWS_FAIL,
        error: error
    };
};

export const getContentViewStart = () => {
    return {
        type: contentViewTypes.GET_CONTENT_VIEWS_START
    };
};

export const fetchContentViewSuccess = (contentViews, type) => {
    return {
        type: contentViewTypes.FETCH_CONTENT_VIEWS_SUCCESS,
        contentViews: contentViews,
        typeParams: type
    };
};

export const fetchContentViewFail = error => {
    return {
        type: contentViewTypes.FETCH_CONTENT_VIEWS_FAIL,
        error: error
    };
};

export const fetchContentViewStart = () => {
    return {
        type: contentViewTypes.FETCH_CONTENT_VIEWS_START
    };
};

export const fetchContentView = (params, type) => {
    return {
        type: contentViewTypes.FETCH_CONTENT_VIEWS,
        params: params,
        typeParams: type
    };
};
