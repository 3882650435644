import { put } from "redux-saga/effects";
import * as actions from "store/reducers/admins/admins.actions";
import adminService from "services/Admins";
export function* fetchAdminsSaga(action) {
  yield put(actions.fetchAdminsStart());
  const service = yield new adminService();
  const admins = yield service.find(action.params);
  if (!admins.error) {
    yield put(actions.fetchAdminsSuccess(admins));
  } else {
    yield put(actions.fetchAdminsFail(admins.error));
  }
}

export function* addAdminSaga(action) {
  yield put(actions.addAdminStart());
  const service = yield new adminService();
  const admin = yield service.create(action.data);
  if (!admin.error) {
    yield put(actions.addAdminSuccess(admin));
    yield action.history.replace("/admin/admins");
  } else {
    yield put(actions.addAdminFail(admin.error));
  }
}

export function* deleteAdminSaga(action) {
  yield put(actions.deleteAdminStart());
  const service = yield new adminService();
  const admin = yield service.delete(action.id);
  if (!admin.error) {
    yield put(actions.deleteAdminSuccess());
    if (action.history) {
      action.history.replace("/admin/admins");
    }
  } else {
    yield put(actions.deleteAdminFail(admin.error));
  }
}

export function* getAdminSaga(action) {
  yield put(actions.getAdminStart());
  const service = yield new adminService();
  const admin = yield service.get(action.id);
  if (!admin.error) {
    yield put(actions.getAdminSuccess(admin));
  } else {
    yield put(actions.getAdminFail(admin.error));
  }
}

export function* updateAdminSaga(action) {
  yield put(actions.updateAdminStart());
  const service = yield new adminService();
  const admin = yield service.patch(action.data);
  if (!admin.error) {
    yield put(actions.updateAdminSuccess(admin));
    if(action.isProfile){
      yield localStorage.removeItem('adminName');
      yield localStorage.setItem('adminName', action.data.fullName);
    }
    yield action.history.goBack();
  } else {
    yield put(actions.updateAdminFail(admin.error));
  }
}
