export const ADD_USER = "ADD_USER";
export const ADD_USER_START = "ADD_USER_START";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_START = "DELETE_USER_START";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const GET_USER = "GET_USERS";
export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const FETCH_USERS = "FETCH_USERS";
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";

export const FETCH_TOTAL_USERS = "FETCH_TOTAL_USERS";
export const FETCH_TOTAL_USERS_START = "FETCH_TOTAL_USERS_START";
export const FETCH_TOTAL_USERS_SUCCESS = "FETCH_TOTAL_USERS_SUCCESS";
export const FETCH_TOTAL_USERS_FAIL = "FETCH_TOTAL_USERS_FAIL";

export const FETCH_ACTIVE_USERS = "FETCH_ACTIVE_USERS";
export const FETCH_ACTIVE_USERS_START = "FETCH_ACTIVE_USERS_START";
export const FETCH_ACTIVE_USERS_SUCCESS = "FETCH_ACTIVE_USERS_SUCCESS";
export const FETCH_ACTIVE_USERS_FAIL = "FETCH_ACTIVE_USERS_FAIL";

export const UPLOAD_USER_PICTURE = "UPLOAD_USER_PICTURE";
export const UPLOAD_USER_PICTURE_START = "UPLOAD_USER_PICTURE_START";
export const UPLOAD_USER_PICTURE_SUCCESS = "UPLOAD_USER_PICTURE_SUCCESS";
export const UPLOAD_USER_PICTURE_FAIL = "UPLOAD_USER_PICTURE_FAIL";

// ====== UTILITY  ======= //
export const USER_DISMISS_ERROR = 'USER_DISMISS_ERROR';
export const USER_ADD_FILTER = 'USER_ADD_FILTER';
export const USER_CLEAR_FILTER = 'USER_CLEAR_FILTER';
export const USER_ADD_PAGINATION = 'USER_ADD_PAGINATION';
export const USER_CLEAR_PAGINATION = 'USER_CLEAR_PAGINATION';
