import * as pageInformationTypes from "store/reducers/page-information/page-information.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchPageInformationsSaga,
  getPageInformationSaga,
  addPageInformationSaga,
  deletePageInformationSaga,
  updatePageInformationSaga
} from "./pageInformationSaga";

export const pageInformationSagas = {
  watchFetchPageInformations: takeEvery(
    pageInformationTypes.FETCH_PAGE_INFORMATIONS,
    fetchPageInformationsSaga
  ),
  watchGetPageInformation: takeEvery(
    pageInformationTypes.GET_PAGE_INFORMATION,
    getPageInformationSaga
  ),
  watchAddPageInformation: takeEvery(
    pageInformationTypes.ADD_PAGE_INFORMATION,
    addPageInformationSaga
  ),
  watchDeletePageInformation: takeEvery(
    pageInformationTypes.DELETE_PAGE_INFORMATION,
    deletePageInformationSaga
  ),
  watchUpdatePageInformation: takeEvery(
    pageInformationTypes.UPDATE_PAGE_INFORMATION,
    updatePageInformationSaga
  )
};
