export const ADD_MEDICINE = "ADD_MEDICINE";
export const ADD_MEDICINE_START = "ADD_MEDICINE_START";
export const ADD_MEDICINE_SUCCESS = "ADD_MEDICINE_SUCCESS";
export const ADD_MEDICINE_FAIL = "ADD_MEDICINE_FAIL";

export const DELETE_MEDICINE = "DELETE_MEDICINE";
export const DELETE_MEDICINE_START = "DELETE_MEDICINE_START";
export const DELETE_MEDICINE_SUCCESS = "DELETE_MEDICINE_SUCCESS";
export const DELETE_MEDICINE_FAIL = "DELETE_MEDICINE_FAIL";

export const UPDATE_MEDICINE = "UPDATE_MEDICINE";
export const UPDATE_MEDICINE_START = "UPDATE_MEDICINE_START";
export const UPDATE_MEDICINE_SUCCESS = "UPDATE_MEDICINE_SUCCESS";
export const UPDATE_MEDICINE_FAIL = "UPDATE_MEDICINE_FAIL";

export const GET_MEDICINE = "GET_MEDICINE";
export const GET_MEDICINE_START = "GET_MEDICINE_START";
export const GET_MEDICINE_SUCCESS = "GET_MEDICINE_SUCCESS";
export const GET_MEDICINE_FAIL = "GET_MEDICINE_FAIL";

export const FETCH_MEDICINES = "FETCH_MEDICINES";
export const FETCH_MEDICINES_START = "FETCH_MEDICINES_START";
export const FETCH_MEDICINES_SUCCESS = "FETCH_MEDICINES_SUCCESS";
export const FETCH_MEDICINES_FAIL = "FETCH_MEDICINES_FAIL";

// ====== UTILITY  ======= //
export const MEDICINE_DISMISS_ERROR = 'MEDICINE_DISMISS_ERROR';
export const MEDICINE_ADD_FILTER = 'MEDICINE_ADD_FILTER';
export const MEDICINE_CLEAR_FILTER = 'MEDICINE_CLEAR_FILTER';
export const MEDICINE_ADD_PAGINATION = 'MEDICINE_ADD_PAGINATION';
export const MEDICINE_CLEAR_PAGINATION = 'MEDICINE_CLEAR_PAGINATION';
