import { put, call } from "redux-saga/effects";
import * as actions from "store/reducers/auth/auth.actions";
import AuthService from "services/Auth";
import jwtDecode from "jwt-decode";

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function* logoutSaga(action) {
  yield put(actions.authStart());
  const service = yield new AuthService(true);
  const authenticationResult = yield service.delete();
  if (authenticationResult.error || authenticationResult.message) {
    let errorMessage =
      authenticationResult.error || authenticationResult.message;
    yield put(actions.authFail(errorMessage));
  } else {
    yield call([localStorage, "removeItem"], "token");
    yield call([localStorage, "removeItem"], "expirationDate");
    yield call([localStorage, "removeItem"], "adminId");
    yield call([localStorage, "removeItem"], "adminName");
    yield call([localStorage, "removeItem"], "refreshToken");
    yield call([localStorage, "removeItem"], "permissions");
    yield put(actions.logoutSucceed());
    action.history.replace("/admin/index");
  }
}

// export function* checkAuthTimeoutSaga(action) {
//   yield delay(action.expirationTime * 1000);
//   yield put(actions.logout());
// }

export function* authUserSaga(action) {
  yield put(actions.authStart());
  const service = yield new AuthService();
  const authData = {
    strategy: "local",
    userType: "admin",
    email: action.email,
    password: action.password
  };

  let deviceId = yield localStorage.getItem("deviceIdKalbe");
  if (!deviceId) {
    deviceId = uuidv4();
    yield localStorage.setItem("deviceIdKalbe", deviceId);
  }

  authData.deviceId = "admin-" + deviceId;
  const authenticationResult = yield service.create(authData);
  if (authenticationResult.error) {
    yield put(actions.authFail(authenticationResult.error));
  } else {
    // console.log(authenticationResult);
    const tokenDecode = jwtDecode(authenticationResult.data.accessToken);
    const expirationDate = yield new Date(tokenDecode.exp * 1000);
    yield localStorage.setItem("token", authenticationResult.data.accessToken);
    yield localStorage.setItem(
      "refreshToken",
      authenticationResult.data.refreshToken
    );
    yield localStorage.setItem("expirationDate", expirationDate);
    yield localStorage.setItem("adminId", authenticationResult.data.admin.id);
    yield localStorage.setItem(
      "adminName",
      authenticationResult.data.admin.fullName
    );
    yield localStorage.setItem(
      "permissions",
      authenticationResult.data.admin.permissions
    );
    yield put(
      actions.authSuccess(
        authenticationResult.data.accessToken,
        authenticationResult.data.admin.id,
        authenticationResult.data.refreshToken,
        expirationDate,
        authenticationResult.data.admin.permissions,
        authenticationResult.data.admin.fullName
      )
    );
  }
}

export function* authCheckStateSaga(action) {
  const token = yield localStorage.getItem("token");
  if (!token) {
    yield call([localStorage, "removeItem"], "token");
    yield call([localStorage, "removeItem"], "expirationDate");
    yield call([localStorage, "removeItem"], "adminId");
    yield call([localStorage, "removeItem"], "refreshToken");
    yield call([localStorage, "removeItem"], "permissions");
    yield call([localStorage, "removeItem"], "adminName");
  } else {
    const adminId = yield localStorage.getItem("adminId");
    const adminName = yield localStorage.getItem("adminName");
    const refreshToken = yield localStorage.getItem("refreshToken");
    const expirationDate = yield localStorage.getItem("expirationDate");
    let permissions = yield localStorage.getItem("permissions");
    permissions = permissions ? permissions.split(",") : [];
    yield put(
      actions.authSuccess(
        token,
        adminId,
        refreshToken,
        expirationDate,
        permissions,
        adminName
      )
    );
  }
}
