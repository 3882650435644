import * as contentViewTypes from "store/reducers/content-views/content-views.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchContentViewSaga,
  getContentViewSaga,
} from "./content-views";

export const contentViewSagas = {
  watchFetchContentView: takeEvery(contentViewTypes.FETCH_CONTENT_VIEWS, fetchContentViewSaga),
  watchGetContentView: takeEvery(contentViewTypes.GET_CONTENT_VIEWS, getContentViewSaga),
}