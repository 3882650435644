import * as foodTypes from "./food.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  foods: {},
  food: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1
  },
};

const fetchFoodStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchFoodSuccess = (state, action) => {
  return updateObject(state, {
    foods: action.foods,
    food: {},
    loading: false,
    error: null
  });
};

const fetchFoodFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addFoodStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addFoodSuccess = (state, action) => {
  return updateObject(state, {
    food: action.food,
    loading: false,
    error: null
  });
};

const addFoodFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteFoodStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteFoodSuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deleteFoodFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getFoodStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getFoodSuccess = (state, action) => {
  return updateObject(state, {
    food: action.food,
    loading: false,
    error: null
  });
};

const getFoodFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateFoodStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateFoodSuccess = (state, action) => {
  return updateObject(state, {
    food: action.food,
    loading: false,
    error: null
  });
};

const updateFoodFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const foodDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const foodAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj
  });
};

const foodClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {}
  });
};

const foodAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination
  });
};

const foodClearPagination = (state) => {
  return updateObject(state, {
    pagination: {}
  });
};

const foodReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case foodTypes.FETCH_FOOD_START:
      return fetchFoodStart(state, action);
    case foodTypes.FETCH_FOOD_SUCCESS:
      return fetchFoodSuccess(state, action);
    case foodTypes.FETCH_FOOD_FAIL:
      return fetchFoodFail(state, action);
    case foodTypes.ADD_FOOD_START:
      return addFoodStart(state, action);
    case foodTypes.ADD_FOOD_SUCCESS:
      return addFoodSuccess(state, action);
    case foodTypes.ADD_FOOD_FAIL:
      return addFoodFail(state, action);
    case foodTypes.DELETE_FOOD_START:
      return deleteFoodStart(state, action);
    case foodTypes.DELETE_FOOD_SUCCESS:
      return deleteFoodSuccess(state, action);
    case foodTypes.DELETE_FOOD_FAIL:
      return deleteFoodFail(state, action);
    case foodTypes.GET_FOOD_START:
      return getFoodStart(state, action);
    case foodTypes.GET_FOOD_SUCCESS:
      return getFoodSuccess(state, action);
    case foodTypes.GET_FOOD_FAIL:
      return getFoodFail(state, action);
    case foodTypes.UPDATE_FOOD_START:
      return updateFoodStart(state, action);
    case foodTypes.UPDATE_FOOD_SUCCESS:
      return updateFoodSuccess(state, action);
    case foodTypes.UPDATE_FOOD_FAIL:
      return updateFoodFail(state, action);
    case foodTypes.FOOD_DISMISS_ERROR:
      return foodDismissError(state);
      case foodTypes.FOOD_ADD_FILTER:
        return foodAddFilter(state, action);
      case foodTypes.FOOD_CLEAR_FILTER:
        return foodClearFilter(state);
      case foodTypes.FOOD_ADD_PAGINATION:
        return foodAddPagination(state, action);
      case foodTypes.FOOD_CLEAR_PAGINATION:
        return foodClearPagination(state);
    default:
      return state;
  }
};

export default foodReducer;
