import * as roleTypes from "./roles.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  roles: {},
  role: {},
  successDeteled: "",
  error: null,
  loading: false,
  filter: "",
  filterObj: {},
  pagination: {
    limit: 10,
    skip: 0,
    page: 1,
  },
};

const fetchRolesStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchRolesSuccess = (state, action) => {
  return updateObject(state, {
    roles: action.roles,
    role: {},
    loading: false,
    error: null
  });
};

const fetchRolesFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addRoleStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addRoleSuccess = (state, action) => {
  return updateObject(state, {
    role: action.role,
    loading: false,
    error: null
  });
};

const addRoleFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteRoleStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteRoleSuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deleteRoleFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getRoleStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getRoleSuccess = (state, action) => {
  return updateObject(state, {
    role: action.role,
    loading: false,
    error: null
  });
};

const getRoleFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateRoleStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateRoleSuccess = (state, action) => {
  return updateObject(state, {
    role: action.role,
    loading: false,
    error: null
  });
};

const updateRoleFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

// ====== UTILITY  ======= //

const roleDismissError = (state) => {
  return updateObject(state, {
    error: null,
  });
};

const roleAddFilter = (state, action) => {
  return updateObject(state, {
    filter: action.filter,
    filterObj: action.filterObj,
  });
};

const roleClearFilter = (state) => {
  return updateObject(state, {
    filter: "",
    filterObj: {},
  });
};

const roleAddPagination = (state, action) => {
  return updateObject(state, {
    pagination: action.pagination,
  });
};

const roleClearPagination = (state) => {
  return updateObject(state, {
    pagination: {},
  });
};

const roleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case roleTypes.FETCH_ROLES_START:
      return fetchRolesStart(state, action);
    case roleTypes.FETCH_ROLES_SUCCESS:
      return fetchRolesSuccess(state, action);
    case roleTypes.FETCH_ROLES_FAIL:
      return fetchRolesFail(state, action);
    case roleTypes.ADD_ROLE_START:
      return addRoleStart(state, action);
    case roleTypes.ADD_ROLE_SUCCESS:
      return addRoleSuccess(state, action);
    case roleTypes.ADD_ROLE_FAIL:
      return addRoleFail(state, action);
    case roleTypes.DELETE_ROLE_START:
      return deleteRoleStart(state, action);
    case roleTypes.DELETE_ROLE_SUCCESS:
      return deleteRoleSuccess(state, action);
    case roleTypes.DELETE_ROLE_FAIL:
      return deleteRoleFail(state, action);
    case roleTypes.GET_ROLE_START:
      return getRoleStart(state, action);
    case roleTypes.GET_ROLE_SUCCESS:
      return getRoleSuccess(state, action);
    case roleTypes.GET_ROLE_FAIL:
      return getRoleFail(state, action);
    case roleTypes.UPDATE_ROLE_START:
      return updateRoleStart(state, action);
    case roleTypes.UPDATE_ROLE_SUCCESS:
      return updateRoleSuccess(state, action);
    case roleTypes.UPDATE_ROLE_FAIL:
      return updateRoleFail(state, action);
    case roleTypes.ROLE_DISMISS_ERROR:
      return roleDismissError(state);
    case roleTypes.ROLE_ADD_FILTER:
      return roleAddFilter(state, action);
    case roleTypes.ROLE_CLEAR_FILTER:
      return roleClearFilter(state);
    case roleTypes.ROLE_ADD_PAGINATION:
      return roleAddPagination(state, action);
    case roleTypes.ROLE_CLEAR_PAGINATION:
      return roleClearPagination(state);

    default:
      return state;
  }
};

export default roleReducer;
