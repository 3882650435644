import { put } from "redux-saga/effects";
import * as actions from "store/reducers/roles/roles.actions";
import roleService from "services/Roles";
export function* fetchRolesSaga(action) {
  yield put(actions.fetchRolesStart());
  const service = yield new roleService();
  const roles = yield service.find(action.params);
  console.log(roles);
  if (!roles.error) {
    yield put(actions.fetchRolesSuccess(roles));
  } else {
    yield put(actions.fetchRolesFail(roles.error));
  }
}

export function* addRoleSaga(action) {
  yield put(actions.addRoleStart());
  const service = yield new roleService();
  const role = yield service.create(action.data);
  if (!role.error) {
    yield put(actions.addRoleSuccess(role));
    yield action.history.goBack();
  } else {
    yield put(actions.addRoleFail(role.error));
  }
}

export function* deleteRoleSaga(action) {
  yield put(actions.deleteRoleStart());
  const service = yield new roleService();
  const role = yield service.delete(action.id);
  if (!role.error) {
    yield put(actions.deleteRoleSuccess());
    if (action.history) {
      action.history.goBack();
    }
  } else {
    yield put(actions.deleteRoleFail(role.error));
  }
}

export function* getRoleSaga(action) {
  yield put(actions.getRoleStart());
  const service = yield new roleService();
  const role = yield service.get(action.id);
  if (!role.error) {
    yield put(actions.getRoleSuccess(role));
  } else {
    yield put(actions.getRoleFail(role.error));
  }
}

export function* updateRoleSaga(action) {
  yield put(actions.updateRoleStart());
  const service = yield new roleService();
  const role = yield service.patch(action.data);
  if (!role.error) {
    yield put(actions.updateRoleSuccess(role));
    yield action.history.goBack();
  } else {
    yield put(actions.updateRoleFail(role.error));
  }
}
