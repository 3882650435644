import * as authTypes from './auth.types';
import { updateObject } from '../../../shared/utility';

const initialState = {
    token: null,
    refreshToken: null,
    expirationDate: null,
    permissions: [],
    adminId: null,
    adminName: null,
    error: null,
    loading: false
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, { 
        token: action.token,
        adminId: action.adminId,
        refreshToken: action.refreshToken,
        expirationDate: action.expirationDate,
        permissions: action.permissions,
        adminName: action.adminName,
        error: null,
        loading: false
     } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authDismissError = (state, action) => {
    return updateObject( state, {
        error: null
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userId: null, loading: null });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path })
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case authTypes.AUTH_START: return authStart(state, action);
        case authTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case authTypes.AUTH_FAIL: return authFail(state, action);
        case authTypes.AUTH_LOGOUT: return authLogout(state, action);
        case authTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
        case authTypes.AUTH_DISMISS_ERROR: return authDismissError(state,action);
        default:
            return state;
    }
};

export default reducer;