import * as waterConsumptionTypes from "./waterConsumption.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  waterConsumptions: null,
  url: "",
  loading: false
};

/**
 * FETCH WATER_CONSUMPTIONS
 */
const fetchWaterConsumptionsStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchWaterConsumptionsSuccess = (state, action) => {
  return updateObject(state, {
    waterConsumptions: action.waterConsumptions,
    waterConsumption: {},
    loading: false
  });
};
const fetchWaterConsumptionsFail = (state, action) => {
  return updateObject(state, { loading: false });
};

/**
 * GET WATER_CONSUMPTION
 */
const getWaterConsumptionStart = (state, action) =>
  updateObject(state, { loading: true });
const getWaterConsumptionSuccess = (state, action) =>
  updateObject(state, {
    loading: false,
    waterConsumption: action.waterConsumption
  });
const getWaterConsumptionFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

/**
 * ADD WATER_CONSUMPTION
 */
const addWaterConsumptionStart = (state, action) =>
  updateObject(state, { loading: true });
const addWaterConsumptionSuccess = (state, action) =>
  updateObject(state, {
    waterConsumption: action.waterConsumption,
    loading: false
  });
const addWaterConsumptionFail = (state, action) =>
  updateObject(state, { error: action.error, loading: false });

/**
 * DELETE WATER_CONSUMPTION
 */
const deleteWaterConsumptionStart = (state, action) =>
  updateObject(state, { loading: true });
const deleteWaterConsumptionSuccess = (state, action) =>
  updateObject(state, { successDeleted: new Date().getTime() });
const deleteWaterConsumptionFail = (state, action) =>
  updateObject(state, { loading: false, error: action.error });

const waterConsumptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case waterConsumptionTypes.FETCH_WATER_CONSUMPTIONS_START:
      return fetchWaterConsumptionsStart(state, action);
    case waterConsumptionTypes.FETCH_WATER_CONSUMPTIONS_SUCCESS:
      return fetchWaterConsumptionsSuccess(state, action);
    case waterConsumptionTypes.FETCH_WATER_CONSUMPTIONS_FAIL:
      return fetchWaterConsumptionsFail(state, action);

    case waterConsumptionTypes.GET_WATER_CONSUMPTION_START:
      return getWaterConsumptionStart(state, action);
    case waterConsumptionTypes.GET_WATER_CONSUMPTION_SUCCESS:
      return getWaterConsumptionSuccess(state, action);
    case waterConsumptionTypes.GET_WATER_CONSUMPTION_FAIL:
      return getWaterConsumptionFail(state, action);

    case waterConsumptionTypes.ADD_WATER_CONSUMPTION_START:
      return addWaterConsumptionStart(state, action);
    case waterConsumptionTypes.ADD_WATER_CONSUMPTION_SUCCESS:
      return addWaterConsumptionSuccess(state, action);
    case waterConsumptionTypes.ADD_WATER_CONSUMPTION_FAIL:
      return addWaterConsumptionFail(state, action);

    case waterConsumptionTypes.DELETE_WATER_CONSUMPTION_START:
      return deleteWaterConsumptionStart(state, action);
    case waterConsumptionTypes.DELETE_WATER_CONSUMPTION_SUCCESS:
      return deleteWaterConsumptionSuccess(state, action);
    case waterConsumptionTypes.DELETE_WATER_CONSUMPTION_FAIL:
      return deleteWaterConsumptionFail(state, action);
    default:
      return state;
  }
};

export default waterConsumptionReducer;
