import * as OtherLabResultTypes from "./OtherLabResult.types";

/**
 * FETCH BLOOD PRESSURE
 */
export const fetchBloodPressure = (params) => ({
  type: OtherLabResultTypes.FETCH_BLOOD_PRESSURE,
  params
});
export const fetchBloodPressureSuccess = (bloodPressure) => ({
  type: OtherLabResultTypes.FETCH_BLOOD_PRESSURE_SUCCESS,
  bloodPressure
});
export const fetchBloodPressureFail = (error) => ({
  type: OtherLabResultTypes.FETCH_BLOOD_PRESSURE_FAIL,
  error
});
export const fetchBloodPressureStart = () => ({
  type: OtherLabResultTypes.FETCH_BLOOD_PRESSURE_START
});

/**
 * FETCH BLOOD PRESSURE
 */
export const fetchCholesterol = (params) => ({
  type: OtherLabResultTypes.FETCH_CHOLESTEROL,
  params
});
export const fetchCholesterolSuccess = (cholesterol) => ({
  type: OtherLabResultTypes.FETCH_CHOLESTEROL_SUCCESS,
  cholesterol
});
export const fetchCholesterolFail = (error) => ({
  type: OtherLabResultTypes.FETCH_CHOLESTEROL_FAIL,
  error
});
export const fetchCholesterolStart = () => ({
  type: OtherLabResultTypes.FETCH_CHOLESTEROL_START
});

/**
 * FETCH BLOOD PRESSURE
 */
export const fetchKidney = (params) => ({
  type: OtherLabResultTypes.FETCH_KIDNEY,
  params
});
export const fetchKidneySuccess = (kidney) => ({
  type: OtherLabResultTypes.FETCH_KIDNEY_SUCCESS,
  kidney
});
export const fetchKidneyFail = (error) => ({
  type: OtherLabResultTypes.FETCH_KIDNEY_FAIL,
  error
});
export const fetchKidneyStart = () => ({
  type: OtherLabResultTypes.FETCH_KIDNEY_START
});
