import * as ActivityTypes from "./Activity.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  activity: null,
  url: "",
  loading: false
};

/**
 * FETCH HBA1C
 */
const fetchActivityStart = (state, action) => {
  return updateObject(state, { loading: true });
};
const fetchActivitySuccess = (state, action) => {
  return updateObject(state, {
    activity: action.activity,
    loading: false
  });
};
const fetchActivityFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const ActivityReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActivityTypes.FETCH_ACTIVITY_START:
      return fetchActivityStart(state, action);
    case ActivityTypes.FETCH_ACTIVITY_SUCCESS:
      return fetchActivitySuccess(state, action);
    case ActivityTypes.FETCH_ACTIVITY_FAIL:
      return fetchActivityFail(state, action);

    default:
      return state;
  }
};

export default ActivityReducer;
