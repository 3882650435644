import * as foodCategoryTypes from './food-category.types';

export const addFoodCategory = (data, history) => {
    return {
        type: foodCategoryTypes.ADD_FOOD_CATEGORY,
        data: data,
        history: history
    };
};

export const addFoodCategorySuccess = foodCategory => {
    return {
        type: foodCategoryTypes.ADD_FOOD_CATEGORY_SUCCESS,
        foodCategory: foodCategory
    };
};

export const addFoodCategoryFail = error => {
    return {
        type: foodCategoryTypes.ADD_FOOD_CATEGORY_FAIL,
        error: error
    };
};

export const addFoodCategoryStart = () => {
    return {
        type: foodCategoryTypes.ADD_FOOD_CATEGORY_START
    };
};

export const updateFoodCategory = (data, history) => {
    return {
        type: foodCategoryTypes.UPDATE_FOOD_CATEGORY,
        data: data,
        history: history
    };
};

export const updateFoodCategorySuccess = foodCategory => {
    return {
        type: foodCategoryTypes.UPDATE_FOOD_CATEGORY_SUCCESS,
        foodCategory: foodCategory
    };
};

export const updateFoodCategoryFail = error => {
    return {
        type: foodCategoryTypes.UPDATE_FOOD_CATEGORY_FAIL,
        error: error
    };
};

export const updateFoodCategoryStart = () => {
    return {
        type: foodCategoryTypes.UPDATE_FOOD_CATEGORY_START
    };
};

export const deleteFoodCategory = (id, history) => {
    return {
        type: foodCategoryTypes.DELETE_FOOD_CATEGORY,
        id: id,
        history: history
    };
};

export const deleteFoodCategorySuccess = () => {
    return {
        type: foodCategoryTypes.DELETE_FOOD_CATEGORY_SUCCESS,
    };
};

export const deleteFoodCategoryFail = error => {
    return {
        type: foodCategoryTypes.DELETE_FOOD_CATEGORY_FAIL,
        error: error
    };
};

export const deleteFoodCategoryStart = () => {
    return {
        type: foodCategoryTypes.DELETE_FOOD_CATEGORY_START
    };
};

export const getFoodCategory = (id) => {
    return {
        type: foodCategoryTypes.GET_FOOD_CATEGORY,
        id: id
    };
};

export const getFoodCategorySuccess = (foodCategory) => {
    return {
        type: foodCategoryTypes.GET_FOOD_CATEGORY_SUCCESS,
        foodCategory: foodCategory
    };
};

export const getFoodCategoryFail = error => {
    return {
        type: foodCategoryTypes.GET_FOOD_CATEGORY_FAIL,
        error: error
    };
};

export const getFoodCategoryStart = () => {
    return {
        type: foodCategoryTypes.GET_FOOD_CATEGORY_START
    };
};

export const fetchFoodCategorySuccess = foodCategories => {
    return {
        type: foodCategoryTypes.FETCH_FOOD_CATEGORY_SUCCESS,
        foodCategories: foodCategories
    };
};

export const fetchFoodCategoryFail = error => {
    return {
        type: foodCategoryTypes.FETCH_FOOD_CATEGORY_FAIL,
        error: error
    };
};

export const fetchFoodCategoryStart = () => {
    return {
        type: foodCategoryTypes.FETCH_FOOD_CATEGORY_START
    };
};

export const fetchFoodCategory = (params) => {
    return {
        type: foodCategoryTypes.FETCH_FOOD_CATEGORY,
        params: params
    };
};

// ====== UTILITY  ======= //
export const foodCategoryDismissError = () => {
    return {
        type: foodCategoryTypes.FOOD_CATEGORY_DISMISS_ERROR
    };
};

export const foodCategoryAddFilter = (filter, filterObj) => {
    return {
        type: foodCategoryTypes.FOOD_CATEGORY_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const foodCategoryClearFilter = () => {
    return {
        type: foodCategoryTypes.FOOD_CATEGORY_CLEAR_FILTER
    };
};

export const foodCategoryAddPagination = (pagination) => {
    return {
        type: foodCategoryTypes.FOOD_CATEGORY_ADD_PAGINATION,
        pagination: pagination
    };
};

export const foodCategoryClearPagination = () => {
    return {
        type: foodCategoryTypes.FOOD_CATEGORY_CLEAR_PAGINATION
    };
};
