import { put } from "redux-saga/effects";
import * as actions from "store/reducers/overcalorie-diabetics/overcalorieDiabetics.actions";
import OvercalorieDiabeticsService from "../../../services/OvercalorieDiabetics";

/**
 * FETCH OVERCALORIE DIABETICS
 */
export function* fetchOvercalorieDiabeticsSaga(action) {
  yield put(actions.fetchOvercalorieDiabeticsStart());

  try {
    const service = yield new OvercalorieDiabeticsService();
    const overcalorieDiabetics = yield service.find(action.params);

    yield put(actions.fetchOvercalorieDiabeticsSuccess(overcalorieDiabetics));
  } catch (error) {
    yield put(actions.fetchOvercalorieDiabeticsFail(error));
  }
}
