import { put } from "redux-saga/effects";
import * as actions from "store/reducers/adminAuthManagement/adminAuthManagement.actions";
import adminAuthManagementService from "services/AdminAuthManagement";

export function* resetPasswordSaga(action) {
  yield put(actions.resetPasswordStart());

  const service = yield new adminAuthManagementService();
  const resetPassword = yield service.create(action.params);

  if (resetPassword.error) {
    yield put(actions.resetPasswordFail(resetPassword.error));
    return;
  }
  yield put(actions.resetPasswordSuccess());

  switch (action.params.action) {
    case "sendResetPwd":
      yield action.history.replace(
        `/auth/login?email=${action.params.value.email}`
      );
      break;

    case "resetPwdLong":
      yield action.history.replace(`/auth/login?status=success`);
      break;

    case "passwordChange":
      yield action.history.replace(`/admin/profile`);
      break;

    default:
      break;
  }
}
