import * as greetingTypes from './greeting.types';

export const addGreeting = (data, history) => {
    return {
        type: greetingTypes.ADD_GREETING,
        data: data,
        history: history
    };
};

export const addGreetingSuccess = greeting => {
    return {
        type: greetingTypes.ADD_GREETING_SUCCESS,
        greeting: greeting
    };
};

export const addGreetingFail = error => {
    return {
        type: greetingTypes.ADD_GREETING_FAIL,
        error: error
    };
};

export const addGreetingStart = () => {
    return {
        type: greetingTypes.ADD_GREETING_START
    };
};

export const updateGreeting = (data, history) => {
    return {
        type: greetingTypes.UPDATE_GREETING,
        data: data,
        history: history
    };
};

export const updateGreetingSuccess = greeting => {
    return {
        type: greetingTypes.UPDATE_GREETING_SUCCESS,
        greeting: greeting
    };
};

export const updateGreetingFail = error => {
    return {
        type: greetingTypes.UPDATE_GREETING_FAIL,
        error: error
    };
};

export const updateGreetingStart = () => {
    return {
        type: greetingTypes.UPDATE_GREETING_START
    };
};

export const deleteGreeting = (id, history) => {
    return {
        type: greetingTypes.DELETE_GREETING,
        id: id,
        history: history
    };
};

export const deleteGreetingSuccess = () => {
    return {
        type: greetingTypes.DELETE_GREETING_SUCCESS,
    };
};

export const deleteGreetingFail = error => {
    return {
        type: greetingTypes.DELETE_GREETING_FAIL,
        error: error
    };
};

export const deleteGreetingStart = () => {
    return {
        type: greetingTypes.DELETE_GREETING_START
    };
};

export const getGreeting = (id) => {
    return {
        type: greetingTypes.GET_GREETING,
        id: id
    };
};

export const getGreetingSuccess = (greeting) => {
    return {
        type: greetingTypes.GET_GREETING_SUCCESS,
        greeting: greeting
    };
};

export const getGreetingFail = error => {
    return {
        type: greetingTypes.GET_GREETING_FAIL,
        error: error
    };
};

export const getGreetingStart = () => {
    return {
        type: greetingTypes.GET_GREETING_START
    };
};

export const fetchGreetingSuccess = greetings => {
    return {
        type: greetingTypes.FETCH_GREETING_SUCCESS,
        greetings: greetings
    };
};

export const fetchGreetingFail = error => {
    return {
        type: greetingTypes.FETCH_GREETING_FAIL,
        error: error
    };
};

export const fetchGreetingStart = () => {
    return {
        type: greetingTypes.FETCH_GREETING_START
    };
};

export const fetchGreeting = (params) => {
    return {
        type: greetingTypes.FETCH_GREETING,
        params: params
    };
};

// ====== UTILITY  ======= //
export const greetingDismissError = () => {
    return {
        type: greetingTypes.GREETING_DISMISS_ERROR
    };
};

export const greetingAddFilter = (filter, filterObj) => {
    return {
        type: greetingTypes.GREETING_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const greetingClearFilter = () => {
    return {
        type: greetingTypes.GREETING_CLEAR_FILTER
    };
};

export const greetingAddPagination = (pagination) => {
    return {
        type: greetingTypes.GREETING_ADD_PAGINATION,
        pagination: pagination
    };
};

export const greetingClearPagination = () => {
    return {
        type: greetingTypes.GREETING_CLEAR_PAGINATION
    };
};
