import { put } from "redux-saga/effects";
import * as actions from "store/reducers/greeting/greeting.actions";
import greetingService from "services/Greeting";
export function* fetchGreetingSaga(action) {
    yield put(actions.fetchGreetingStart());
    const service = yield new greetingService();
    const greeting = yield service.find(action.params);
    if(!greeting.error){
      yield put(actions.fetchGreetingSuccess(greeting));
    } else {
      yield put(actions.fetchGreetingFail(greeting.error));  
    }
}

export function* addGreetingSaga(action) {
  yield put(actions.addGreetingStart());
  const service = yield new greetingService();
  const greeting = yield service.create(action.data);
  if(!greeting.error){
    yield action.history.replace('/admin/greeting');
  } else {
    yield put(actions.addGreetingFail(greeting.error));
  }
}

export function* deleteGreetingSaga(action) {
  yield put(actions.deleteGreetingStart());
  const service = yield new greetingService();
  const greeting = yield service.delete(action.id);
  if(!greeting.error){
    yield put(actions.deleteGreetingSuccess());
    if(action.history){
      action.history.replace('/admin/greeting')
    }
  } else {
    yield put(actions.deleteGreetingFail(greeting.error));
  }
}

export function* getGreetingSaga(action) {
  yield put(actions.getGreetingStart());
  const service = yield new greetingService();
  const greeting = yield service.get(action.id);
  if(!greeting.error){
    yield put(actions.getGreetingSuccess(greeting));
  } else {
    yield put(actions.getGreetingFail(greeting.error));
  }
}

export function* updateGreetingSaga(action) {
  yield put(actions.updateGreetingStart());
  const service = yield new greetingService();
  const greeting = yield service.patch(action.data);
  if(!greeting.error){
    yield action.history.replace('/admin/greeting');
  } else {
    yield put(actions.updateGreetingFail(greeting.error));
  }
}