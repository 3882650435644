import * as pageInformationTypes from "./page-information.types";

/**
 * FETCH PAGE_INFORMATIONS
 */
export const fetchPageInformations = (params) => ({
  type: pageInformationTypes.FETCH_PAGE_INFORMATIONS,
  params
});
export const fetchPageInformationsStart = () => ({
  type: pageInformationTypes.FETCH_PAGE_INFORMATIONS_START
});
export const fetchPageInformationsSuccess = (pageInformations) => ({
  type: pageInformationTypes.FETCH_PAGE_INFORMATIONS_SUCCESS,
  pageInformations
});
export const fetchPageInformationsFail = (error) => ({
  type: pageInformationTypes.FETCH_PAGE_INFORMATIONS_FAIL,
  error
});

/**
 * GET PAGE_INFORMATION
 */
export const getPageInformation = (id) => ({
  type: pageInformationTypes.GET_PAGE_INFORMATION,
  id
});
export const getPageInformationStart = () => ({
  type: pageInformationTypes.GET_PAGE_INFORMATION_START
});
export const getPageInformationSuccess = (pageInformation) => ({
  type: pageInformationTypes.GET_PAGE_INFORMATION_SUCCESS,
  pageInformation
});
export const getPageInformationFail = (error) => ({
  type: pageInformationTypes.GET_PAGE_INFORMATION_FAIL,
  error
});

/**
 * CREATE PAGE_INFORMATION
 */
export const addPageInformation = (history, data) => ({
  type: pageInformationTypes.ADD_PAGE_INFORMATION,
  history,
  data
});
export const addPageInformationStart = () => ({
  type: pageInformationTypes.ADD_PAGE_INFORMATION_START
});
export const addPageInformationSuccess = (pageInformation) => ({
  type: pageInformationTypes.ADD_PAGE_INFORMATION_SUCCESS,
  pageInformation
});
export const addPageInformationFail = (error) => ({
  type: pageInformationTypes.ADD_PAGE_INFORMATION_FAIL,
  error
});

/**
 * UPDATE PAGE_INFORMATION
 */
export const updatePageInformation = (history, data) => ({
  type: pageInformationTypes.UPDATE_PAGE_INFORMATION,
  history,
  data
});
export const updatePageInformationStart = () => ({
  type: pageInformationTypes.UPDATE_PAGE_INFORMATION_START
});
export const updatePageInformationSuccess = (pageInformation) => ({
  type: pageInformationTypes.UPDATE_PAGE_INFORMATION_SUCCESS,
  pageInformation
});
export const updatePageInformationFail = (error) => ({
  type: pageInformationTypes.UPDATE_PAGE_INFORMATION_FAIL,
  error
});

/**
 * DELETE PAGE_INFORMATION
 */
export const deletePageInformation = (id, history) => ({
  type: pageInformationTypes.DELETE_PAGE_INFORMATION,
  id,
  history
});
export const deletePageInformationStart = () => ({
  type: pageInformationTypes.DELETE_PAGE_INFORMATION_START
});
export const deletePageInformationSuccess = () => ({
  type: pageInformationTypes.DELETE_PAGE_INFORMATION_SUCCESS
});

export const deletePageInformationFail = (error) => ({
  type: pageInformationTypes.DELETE_PAGE_INFORMATION_FAIL,
  error
});

// ====== UTILITY  ======= //
export const pageInformationDismissError = () => ({
  type: pageInformationTypes.PAGE_INFORMATION_DISMISS_ERROR
});
export const pageInformationAddFilter = (filter, filterObj) => ({
  type: pageInformationTypes.PAGE_INFORMATION_ADD_FILTER,
  filter: filter,
  filterObj: filterObj
});

export const pageInformationClearFilter = () => ({
  type: pageInformationTypes.PAGE_INFORMATION_CLEAR_FILTER
});

export const pageInformationAddPagination = (pagination) => ({
  type: pageInformationTypes.PAGE_INFORMATION_ADD_PAGINATION,
  pagination: pagination
});

export const pageInformationClearPagination = () => ({
  type: pageInformationTypes.PAGE_INFORMATION_CLEAR_PAGINATION
});
