import * as articleCategoryTypes from "store/reducers/article-category/article-category.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchArticleCategorySaga,
  addArticleCategorySaga,
  deleteArticleCategorySaga,
  getArticleCategorySaga,
  updateArticleCategorySaga
} from "./article-category";

export const articleCategorySagas = {
  watchFetchArticleCategory: takeEvery(articleCategoryTypes.FETCH_ARTICLE_CATEGORY, fetchArticleCategorySaga),
  watchAddArticleCategory: takeEvery(articleCategoryTypes.ADD_ARTICLE_CATEGORY, addArticleCategorySaga),
  watchDeleteArticleCategory: takeEvery(articleCategoryTypes.DELETE_ARTICLE_CATEGORY, deleteArticleCategorySaga),
  watchGetArticleCategory: takeEvery(articleCategoryTypes.GET_ARTICLE_CATEGORY, getArticleCategorySaga),
  watchUpdateArticleCategory: takeEvery(articleCategoryTypes.UPDATE_ARTICLE_CATEGORY, updateArticleCategorySaga)
}