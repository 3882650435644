import * as roleTypes from './roles.types';

export const addRole = (data, history) => {
    return {
        type: roleTypes.ADD_ROLE,
        data: data,
        history: history
    };
};

export const addRoleSuccess = role => {
    return {
        type: roleTypes.ADD_ROLE_SUCCESS,
        role: role
    };
};

export const addRoleFail = error => {
    return {
        type: roleTypes.ADD_ROLE_FAIL,
        error: error
    };
};

export const addRoleStart = () => {
    return {
        type: roleTypes.ADD_ROLE_START
    };
};

export const updateRole = (data, history) => {
    return {
        type: roleTypes.UPDATE_ROLE,
        data: data,
        history: history
    };
};

export const updateRoleSuccess = role => {
    return {
        type: roleTypes.UPDATE_ROLE_SUCCESS,
        role: role
    };
};

export const updateRoleFail = error => {
    return {
        type: roleTypes.UPDATE_ROLE_FAIL,
        error: error
    };
};

export const updateRoleStart = () => {
    return {
        type: roleTypes.UPDATE_ROLE_START
    };
};

export const deleteRole = (id, history) => {
    return {
        type: roleTypes.DELETE_ROLE,
        id: id,
        history: history
    };
};

export const deleteRoleSuccess = () => {
    return {
        type: roleTypes.DELETE_ROLE_SUCCESS,
    };
};

export const deleteRoleFail = error => {
    return {
        type: roleTypes.DELETE_ROLE_FAIL,
        error: error
    };
};

export const deleteRoleStart = () => {
    return {
        type: roleTypes.DELETE_ROLE_START
    };
};

export const getRole = (id) => {
    return {
        type: roleTypes.GET_ROLE,
        id: id
    };
};

export const getRoleSuccess = (role) => {
    return {
        type: roleTypes.GET_ROLE_SUCCESS,
        role: role
    };
};

export const getRoleFail = error => {
    return {
        type: roleTypes.GET_ROLE_FAIL,
        error: error
    };
};

export const getRoleStart = () => {
    return {
        type: roleTypes.GET_ROLE_START
    };
};

export const fetchRolesSuccess = roles => {
    return {
        type: roleTypes.FETCH_ROLES_SUCCESS,
        roles: roles
    };
};

export const fetchRolesFail = error => {
    return {
        type: roleTypes.FETCH_ROLES_FAIL,
        error: error
    };
};

export const fetchRolesStart = () => {
    return {
        type: roleTypes.FETCH_ROLES_START
    };
};

export const fetchRoles = (params) => {
    return {
        type: roleTypes.FETCH_ROLES,
        params: params
    };
};

// ====== UTILITY  ======= //
export const roleDismissError = () => {
    return {
        type: roleTypes.ROLE_DISMISS_ERROR
    };
};

export const roleAddFilter = (filter, filterObj) => {
    return {
        type: roleTypes.ROLE_ADD_FILTER,
        filter: filter,
        filterObj: filterObj
    };
};

export const roleClearFilter = () => {
    return {
        type: roleTypes.ROLE_CLEAR_FILTER
    };
};

export const roleAddPagination = (pagination) => {
    return {
        type: roleTypes.ROLE_ADD_PAGINATION,
        pagination: pagination
    };
};

export const roleClearPagination = () => {
    return {
        type: roleTypes.ROLE_CLEAR_PAGINATION
    };
};
