import * as TopMedicineTypes from "./topMedicine.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  topMedicine: [],
  topManufacturer: [],
  loading: false
};

/**
 * FETCH TOP MEDICINE
 */
const fetchTopMedicineStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchTopMedicineSuccess = (state, action) =>
  updateObject(state, {
    topMedicine: action.topMedicine,
    loading: false
  });
const fetchTopMedicineFail = (state, action) =>
  updateObject(state, { loading: false });

/**
 * FETCH TOP MANUFACTURER
 */
const fetchTopManufacturerStart = (state, action) =>
  updateObject(state, { loading: true });
const fetchTopManufacturerSuccess = (state, action) =>
  updateObject(state, {
    topManufacturer: action.topManufacturer,
    loading: false
  });
const fetchTopManufacturerFail = (state, action) =>
  updateObject(state, { loading: false });

const TopMedicineReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TopMedicineTypes.FETCH_TOP_MEDICINE_START:
      return fetchTopMedicineStart(state, action);
    case TopMedicineTypes.FETCH_TOP_MEDICINE_SUCCESS:
      return fetchTopMedicineSuccess(state, action);
    case TopMedicineTypes.FETCH_TOP_MEDICINE_FAIL:
      return fetchTopMedicineFail(state, action);

    case TopMedicineTypes.FETCH_TOP_MANUFACTURER_START:
      return fetchTopManufacturerStart(state, action);
    case TopMedicineTypes.FETCH_TOP_MANUFACTURER_SUCCESS:
      return fetchTopManufacturerSuccess(state, action);
    case TopMedicineTypes.FETCH_TOP_MANUFACTURER_FAIL:
      return fetchTopManufacturerFail(state, action);

    default:
      return state;
  }
};

export default TopMedicineReducer;
