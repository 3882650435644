export const ADD_PRIVACY_POLICY = 'ADD_PRIVACY_POLICY';
export const ADD_PRIVACY_POLICY_START = 'ADD_PRIVACY_POLICY_START';
export const ADD_PRIVACY_POLICY_SUCCESS = 'ADD_PRIVACY_POLICY_SUCCESS';
export const ADD_PRIVACY_POLICY_FAIL = 'ADD_PRIVACY_POLICY_FAIL';

export const DELETE_PRIVACY_POLICY = 'DELETE_PRIVACY_POLICY';
export const DELETE_PRIVACY_POLICY_START = 'DELETE_PRIVACY_POLICY_START';
export const DELETE_PRIVACY_POLICY_SUCCESS = 'DELETE_PRIVACY_POLICY_SUCCESS';
export const DELETE_PRIVACY_POLICY_FAIL = 'DELETE_PRIVACY_POLICY_FAIL';


export const UPDATE_PRIVACY_POLICY = 'UPDATE_PRIVACY_POLICY';
export const UPDATE_PRIVACY_POLICY_START = 'UPDATE_PRIVACY_POLICY_START';
export const UPDATE_PRIVACY_POLICY_SUCCESS = 'UPDATE_PRIVACY_POLICY_SUCCESS';
export const UPDATE_PRIVACY_POLICY_FAIL = 'UPDATE_PRIVACY_POLICY_FAIL';

export const GET_PRIVACY_POLICY = 'GET_PRIVACY_POLICY';
export const GET_PRIVACY_POLICY_START = 'GET_PRIVACY_POLICY_START';
export const GET_PRIVACY_POLICY_SUCCESS = 'GET_PRIVACY_POLICY_SUCCESS';
export const GET_PRIVACY_POLICY_FAIL = 'GET_PRIVACY_POLICY_FAIL';

export const FETCH_PRIVACY_POLICY = 'FETCH_PRIVACY_POLICY';
export const FETCH_PRIVACY_POLICY_START = 'FETCH_PRIVACY_POLICY_START';
export const FETCH_PRIVACY_POLICY_SUCCESS = 'FETCH_PRIVACY_POLICY_SUCCESS';
export const FETCH_PRIVACY_POLICY_FAIL = 'FETCH_PRIVACY_POLICY_FAIL';


export const PRIVACY_POLICY_DISMISS_ERROR = 'PRIVACY_POLICY_DISMISS_ERROR';