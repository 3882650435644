import * as TopMedicineTypes from "./topMedicine.types";

/**
 * FETCH TOP MEDICINE
 */
export const fetchTopMedicine = (params) => ({
  type: TopMedicineTypes.FETCH_TOP_MEDICINE,
  params
});
export const fetchTopMedicineSuccess = (topMedicine) => ({
  type: TopMedicineTypes.FETCH_TOP_MEDICINE_SUCCESS,
  topMedicine
});
export const fetchTopMedicineFail = (error) => ({
  type: TopMedicineTypes.FETCH_TOP_MEDICINE_FAIL,
  error
});
export const fetchTopMedicineStart = () => ({
  type: TopMedicineTypes.FETCH_TOP_MEDICINE_START
});

/**
 * FETCH TOP MANUFACTURER
 */
export const fetchTopManufacturer = (params) => ({
  type: TopMedicineTypes.FETCH_TOP_MANUFACTURER,
  params
});
export const fetchTopManufacturerSuccess = (topManufacturer) => ({
  type: TopMedicineTypes.FETCH_TOP_MANUFACTURER_SUCCESS,
  topManufacturer
});
export const fetchTopManufacturerFail = (error) => ({
  type: TopMedicineTypes.FETCH_TOP_MANUFACTURER_FAIL,
  error
});
export const fetchTopManufacturerStart = () => ({
  type: TopMedicineTypes.FETCH_TOP_MANUFACTURER_START
});
