export const ADD_ROLE = 'ADD_ROLE';
export const ADD_ROLE_START = 'ADD_ROLE_START';
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS';
export const ADD_ROLE_FAIL = 'ADD_ROLE_FAIL';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_START = 'DELETE_ROLE_START';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL';


export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_START = 'UPDATE_ROLE_START';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL';

export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_START = 'GET_ROLE_START';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL';

export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_START = 'FETCH_ROLES_START';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAIL = 'FETCH_ROLES_FAIL';


// ====== UTILITY  ======= //
export const ROLE_DISMISS_ERROR = 'ROLE_DISMISS_ERROR';
export const ROLE_ADD_FILTER = 'ROLE_ADD_FILTER';
export const ROLE_CLEAR_FILTER = 'ROLE_CLEAR_FILTER';
export const ROLE_ADD_PAGINATION = 'ROLE_ADD_PAGINATION';
export const ROLE_CLEAR_PAGINATION = 'ROLE_CLEAR_PAGINATION';