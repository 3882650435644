import * as bannerTypes from "./banner.types";

/**
 * FETCH BANNERS
 */
export const fetchBanners = (params) => ({
  type: bannerTypes.FETCH_BANNERS,
  params
});
export const fetchBannersStart = () => ({
  type: bannerTypes.FETCH_BANNERS_START
});
export const fetchBannersSuccess = (banners) => ({
  type: bannerTypes.FETCH_BANNERS_SUCCESS,
  banners
});
export const fetchBannersFail = (error) => ({
  type: bannerTypes.FETCH_BANNERS_FAIL,
  error
});

/**
 * CREATE BANNER
 */
export const addBanner = (history, data, imgUploadPayload) => ({
  type: bannerTypes.ADD_BANNER,
  history,
  data,
  imgUploadPayload
});
export const addBannerStart = () => ({
  type: bannerTypes.ADD_BANNER_START
});
export const addBannerSuccess = (banner) => ({
  type: bannerTypes.ADD_BANNER_SUCCESS,
  banner
});
export const addBannerFail = (error) => ({
  type: bannerTypes.ADD_BANNER_FAIL,
  error
});

/**
 * GET BANNER
 */
export const getBanner = (id) => ({
  type: bannerTypes.GET_BANNER,
  id
});
export const getBannerStart = () => ({
  type: bannerTypes.GET_BANNER_START
});
export const getBannerSuccess = (banner) => ({
  type: bannerTypes.GET_BANNER_SUCCESS,
  banner
});
export const getBannerFail = (error) => ({
  type: bannerTypes.GET_BANNER_FAIL,
  error
});

/**
 * UPDATE BANNER
 */
export const updateBanner = (history, data, imgUploadPayload) => ({
  type: bannerTypes.UPDATE_BANNER,
  history,
  data,
  imgUploadPayload
});
export const updateBannerStart = () => ({
  type: bannerTypes.UPDATE_BANNER_START
});
export const updateBannerSuccess = (banner) => ({
  type: bannerTypes.UPDATE_BANNER_SUCCESS,
  banner
});
export const updateBannerFail = (error) => ({
  type: bannerTypes.UPDATE_BANNER_FAIL,
  error
});

/**
 * DELETE BANNER
 */
export const deleteBanner = (id, history) => ({
  type: bannerTypes.DELETE_BANNER,
  id,
  history
});
export const deleteBannerStart = () => ({
  type: bannerTypes.DELETE_BANNER_START
});
export const deleteBannerSuccess = () => ({
  type: bannerTypes.DELETE_BANNER_SUCCESS
});

export const deleteBannerFail = (error) => ({
  type: bannerTypes.DELETE_BANNER_FAIL,
  error
});

// ====== UTILITY  ======= //
export const bannerDismissError = () => {
  return {
      type: bannerTypes.BANNER_DISMISS_ERROR
  };
};

export const bannerAddFilter = (filter, filterObj) => {
  return {
      type: bannerTypes.BANNER_ADD_FILTER,
      filter: filter,
      filterObj: filterObj
  };
};

export const bannerClearFilter = () => {
  return {
      type: bannerTypes.BANNER_CLEAR_FILTER
  };
};

export const bannerAddPagination = (pagination) => {
  return {
      type: bannerTypes.BANNER_ADD_PAGINATION,
      pagination: pagination
  };
};

export const bannerClearPagination = () => {
  return {
      type: bannerTypes.BANNER_CLEAR_PAGINATION
  };
};
