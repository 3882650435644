export const ADD_FOOD = 'ADD_FOOD';
export const ADD_FOOD_START = 'ADD_FOOD_START';
export const ADD_FOOD_SUCCESS = 'ADD_FOOD_SUCCESS';
export const ADD_FOOD_FAIL = 'ADD_FOOD_FAIL';

export const DELETE_FOOD = 'DELETE_FOOD';
export const DELETE_FOOD_START = 'DELETE_FOOD_START';
export const DELETE_FOOD_SUCCESS = 'DELETE_FOOD_SUCCESS';
export const DELETE_FOOD_FAIL = 'DELETE_FOOD_FAIL';


export const UPDATE_FOOD = 'UPDATE_FOOD';
export const UPDATE_FOOD_START = 'UPDATE_FOOD_START';
export const UPDATE_FOOD_SUCCESS = 'UPDATE_FOOD_SUCCESS';
export const UPDATE_FOOD_FAIL = 'UPDATE_FOOD_FAIL';

export const GET_FOOD = 'GET_FOOD';
export const GET_FOOD_START = 'GET_FOOD_START';
export const GET_FOOD_SUCCESS = 'GET_FOOD_SUCCESS';
export const GET_FOOD_FAIL = 'GET_FOOD_FAIL';

export const FETCH_FOOD = 'FETCH_FOOD';
export const FETCH_FOOD_START = 'FETCH_FOOD_START';
export const FETCH_FOOD_SUCCESS = 'FETCH_FOOD_SUCCESS';
export const FETCH_FOOD_FAIL = 'FETCH_FOOD_FAIL';


// ====== UTILITY  ======= //
export const FOOD_DISMISS_ERROR = 'FOOD_DISMISS_ERROR';
export const FOOD_ADD_FILTER = 'FOOD_ADD_FILTER';
export const FOOD_CLEAR_FILTER = 'FOOD_CLEAR_FILTER';
export const FOOD_ADD_PAGINATION = 'FOOD_ADD_PAGINATION';
export const FOOD_CLEAR_PAGINATION = 'FOOD_CLEAR_PAGINATION';