import * as medicineTypes from "store/reducers/medicine/medicine.types";
import { takeEvery } from "redux-saga/effects";
import {
  fetchMedicinesSaga,
  addMedicineSaga,
  deleteMedicineSaga,
  getMedicineSaga,
  updateMedicineSaga
} from "./medicineSaga";

export const medicineSagas = {
  watchFetchMedicines: takeEvery(
    medicineTypes.FETCH_MEDICINES,
    fetchMedicinesSaga
  ),
  watchAddMedicine: takeEvery(medicineTypes.ADD_MEDICINE, addMedicineSaga),
  watchDeleteMedicine: takeEvery(
    medicineTypes.DELETE_MEDICINE,
    deleteMedicineSaga
  ),
  watchGetMedicine: takeEvery(medicineTypes.GET_MEDICINE, getMedicineSaga),
  watchUpdateMedicine: takeEvery(
    medicineTypes.UPDATE_MEDICINE,
    updateMedicineSaga
  )
};
