import { put } from "redux-saga/effects";
import * as actions from "store/reducers/onboarding/onboarding.actions";
import onboardingService from "services/Onboarding";
import uploadService from "services/Upload";
export function* fetchOnboardingSaga(action) {
    yield put(actions.fetchOnboardingStart());
    const service = yield new onboardingService();
    const onboarding = yield service.find(action.params);
    if(!onboarding.error){
      yield put(actions.fetchOnboardingSuccess(onboarding));
    } else {
      yield put(actions.fetchOnboardingFail(onboarding.error));  
    }
}

export function* addOnboardingSaga(action) {
  yield put(actions.addOnboardingStart());
  let upload = {};
  if(action.imgUploadPayload !== null){
    // Img Upload
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.addOnboardingFail(upload.error));
      return;
    }
  }

  // add onboarding
  action.data.image = action.imgUploadPayload ? upload.data.url : null;
  const service = yield new onboardingService();
  const onboarding = yield service.create(action.data);
  if(!onboarding.error){
    yield action.history.replace('/admin/onboarding');
  } else {
    yield put(actions.addOnboardingFail(onboarding.error));
  }
}

export function* deleteOnboardingSaga(action) {
  yield put(actions.deleteOnboardingStart());
  const service = yield new onboardingService();
  const onboarding = yield service.delete(action.id);
  if(!onboarding.error){
    yield put(actions.deleteOnboardingSuccess());
    if(action.history){
      action.history.replace('/admin/onboarding')
    }
  } else {
    yield put(actions.deleteOnboardingFail(onboarding.error));
  }
}

export function* getOnboardingSaga(action) {
  yield put(actions.getOnboardingStart());
  const service = yield new onboardingService();
  const onboarding = yield service.get(action.id);
  if(!onboarding.error){
    yield put(actions.getOnboardingSuccess(onboarding));
  } else {
    yield put(actions.getOnboardingFail(onboarding.error));
  }
}

export function* updateOnboardingSaga(action) {
  yield put(actions.updateOnboardingStart());
  let upload = {};
  if(action.imgUploadPayload !== null){
    // Img Upload
    const upService = yield new uploadService();
    upload = yield upService.upload(action.imgUploadPayload);
    if (upload.error) {
      yield put(actions.updateOnboardingFail(upload.error));
      return;
    }
  }

  const service = yield new onboardingService();
  action.data.image = action.imgUploadPayload ? upload.data.url : null;
  const onboarding = yield service.patch(action.data);
  if(!onboarding.error){
    yield action.history.replace('/admin/onboarding');
  } else {
    yield put(actions.updateOnboardingFail(onboarding.error));
  }
}