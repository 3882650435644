import * as faqTypes from "./faq.types";
import { updateObject } from "../../../shared/utility";

const INITIAL_STATE = {
  faqs: {},
  faq: {},
  successDeteled: "",
  error: null,
  loading: false
};

const fetchFaqStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchFaqSuccess = (state, action) => {
  return updateObject(state, {
    faqs: action.faqs,
    faq: {},
    loading: false,
    error: null
  });
};

const fetchFaqFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const addFaqStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const addFaqSuccess = (state, action) => {
  return updateObject(state, {
    faq: action.faq,
    loading: false,
    error: null
  });
};

const addFaqFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const deleteFaqStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const deleteFaqSuccess = (state, action) => {
  return updateObject(state, {
    successDeteled: new Date().getTime(),
    error: null
  });
};

const deleteFaqFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getFaqStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getFaqSuccess = (state, action) => {
  return updateObject(state, {
    faq: action.faq,
    loading: false,
    error: null
  });
};

const getFaqFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const updateFaqStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const updateFaqSuccess = (state, action) => {
  return updateObject(state, {
    faq: action.faq,
    loading: false,
    error: null
  });
};

const updateFaqFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const faqDismissError = (state) => {
  return updateObject(state, {
    error: null
  });
};

const faqReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case faqTypes.FETCH_FAQ_START:
      return fetchFaqStart(state, action);
    case faqTypes.FETCH_FAQ_SUCCESS:
      return fetchFaqSuccess(state, action);
    case faqTypes.FETCH_FAQ_FAIL:
      return fetchFaqFail(state, action);
    case faqTypes.ADD_FAQ_START:
      return addFaqStart(state, action);
    case faqTypes.ADD_FAQ_SUCCESS:
      return addFaqSuccess(state, action);
    case faqTypes.ADD_FAQ_FAIL:
      return addFaqFail(state, action);
    case faqTypes.DELETE_FAQ_START:
      return deleteFaqStart(state, action);
    case faqTypes.DELETE_FAQ_SUCCESS:
      return deleteFaqSuccess(state, action);
    case faqTypes.DELETE_FAQ_FAIL:
      return deleteFaqFail(state, action);
    case faqTypes.GET_FAQ_START:
      return getFaqStart(state, action);
    case faqTypes.GET_FAQ_SUCCESS:
      return getFaqSuccess(state, action);
    case faqTypes.GET_FAQ_FAIL:
      return getFaqFail(state, action);
    case faqTypes.UPDATE_FAQ_START:
      return updateFaqStart(state, action);
    case faqTypes.UPDATE_FAQ_SUCCESS:
      return updateFaqSuccess(state, action);
    case faqTypes.UPDATE_FAQ_FAIL:
      return updateFaqFail(state, action);
    case faqTypes.FAQ_DISMISS_ERROR:
      return faqDismissError(state);

    default:
      return state;
  }
};

export default faqReducer;
