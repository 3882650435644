import { put } from "redux-saga/effects";
import * as actions from "store/reducers/faq/faq.actions";
import faqService from "services/Faq";
export function* fetchFaqSaga(action) {
    yield put(actions.fetchFaqStart());
    const service = yield new faqService();
    const faq = yield service.find(action.params);
    if(!faq.error){
      yield put(actions.fetchFaqSuccess(faq));
    } else {
      yield put(actions.fetchFaqFail(faq.error));  
    }
}

export function* addFaqSaga(action) {
  yield put(actions.addFaqStart());
  const service = yield new faqService();
  const faq = yield service.create(action.data);
  if(!faq.error){
    yield action.history.replace('/admin/faq');
  } else {
    yield put(actions.addFaqFail(faq.error));
  }
}

export function* deleteFaqSaga(action) {
  yield put(actions.deleteFaqStart());
  const service = yield new faqService();
  const faq = yield service.delete(action.id);
  if(!faq.error){
    yield put(actions.deleteFaqSuccess());
    if(action.history){
      action.history.replace('/admin/faq')
    }
  } else {
    yield put(actions.deleteFaqFail(faq.error));
  }
}

export function* getFaqSaga(action) {
  yield put(actions.getFaqStart());
  const service = yield new faqService();
  const faq = yield service.get(action.id);
  if(!faq.error){
    yield put(actions.getFaqSuccess(faq));
  } else {
    yield put(actions.getFaqFail(faq.error));
  }
}

export function* updateFaqSaga(action) {
  yield put(actions.updateFaqStart());
  const service = yield new faqService();
  const faq = yield service.patch(action.data);
  if(!faq.error){
    yield action.history.replace('/admin/faq');
  } else {
    yield put(actions.updateFaqFail(faq.error));
  }
}