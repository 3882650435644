import { takeEvery } from "redux-saga/effects";
import * as userTypes from "store/reducers/user/user.types";
import {
  fetchUsersSaga,
  fetchActiveUsersSaga,
  fetchTotalUsersSaga,
  getUserSaga,
  addUserSaga,
  updateUserSaga,
  deleteUserSaga
} from "./users";

export const usersSagas = {
  watchFetchUsers: takeEvery(userTypes.FETCH_USERS, fetchUsersSaga),
  watchFetchActiveUsers: takeEvery(
    userTypes.FETCH_ACTIVE_USERS,
    fetchActiveUsersSaga
  ),
  watchTotalUsers: takeEvery(userTypes.FETCH_TOTAL_USERS, fetchTotalUsersSaga),
  watchGetUser: takeEvery(userTypes.GET_USER, getUserSaga),
  watchAddUser: takeEvery(userTypes.ADD_USER, addUserSaga),
  watchUpdateUser: takeEvery(userTypes.UPDATE_USER, updateUserSaga),
  watchDeleteUser: takeEvery(userTypes.DELETE_USER, deleteUserSaga)
};
