import { put } from "redux-saga/effects";
import * as actions from "store/reducers/content-category/content-category.actions";
import contentCategoryService from "services/ContentCategory";
export function* fetchContentsCategoriesSaga(action) {
    yield put(actions.fetchContentsCategoriesStart());
    const service = yield new contentCategoryService();
    const contentsCategories = yield service.find(action.params);
    if(!contentsCategories.error){
      yield put(actions.fetchContentsCategoriesSuccess(contentsCategories));
    } else {
      yield put(actions.fetchContentsCategoriesFail(contentsCategories.error));  
    }
}

export function* addContentCategorySaga(action) {
  yield put(actions.addContentCategoryStart());
  const service = yield new contentCategoryService();
  const contentCategory = yield service.create(action.data);
  if(!contentCategory.error){
    yield put(actions.addContentCategorySuccess(contentCategory));
    yield action.history.replace('/admin/content-category');
  } else {
    yield put(actions.addContentCategoryFail(contentCategory.error));
  }
}

export function* deleteContentCategorySaga(action) {
  yield put(actions.deleteContentCategoryStart());
  const service = yield new contentCategoryService();
  const contentCategory = yield service.delete(action.id);
  if(!contentCategory.error){
    yield put(actions.deleteContentCategorySuccess());
    if(action.history){
      action.history.replace('/admin/content-category')
    }
  } else {
    yield put(actions.deleteContentCategoryFail(contentCategory.error));
  }
}

export function* getContentCategorySaga(action) {
  yield put(actions.getContentCategoryStart());
  const service = yield new contentCategoryService();
  const contentCategory = yield service.get(action.id);
  if(!contentCategory.error){
    yield put(actions.getContentCategorySuccess(contentCategory));
  } else {
    yield put(actions.getContentCategoryFail(contentCategory.error));
  }
}

export function* updateContentCategorySaga(action) {
  yield put(actions.updateContentCategoryStart());
  const service = yield new contentCategoryService();
  const contentCategory = yield service.patch(action.data);
  if(!contentCategory.error){
    yield put(actions.updateContentCategorySuccess(contentCategory));
    yield action.history.replace('/admin/content-category');
  } else {
    yield put(actions.updateContentCategoryFail(contentCategory.error));
  }
}